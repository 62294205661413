import { pick } from 'lodash-es';
import type { Commit, Dispatch } from 'vuex';

type Roles = TmRole[];
type Casl = { rules: string[]; aliases: Record<string, any> };

const moduleState = {
  user: <TmUser>{},
  roles: <Roles>[],
  casl: <Casl>{
    rules: [],
    aliases: {},
  },
};

export const getters = {
  hasRole: (state: typeof moduleState) => (role: TmRole) => {
    // admin user can manage everything, except other users scenarios
    return state.roles.includes(role) || state.roles.includes('admin');
  },
  isLogged: (state: typeof moduleState) => 'id' in state.user,
};

export const actions = {
  async login({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, auth: typeof moduleState) {
    commit('SET_AUTH', auth);
    // exit any preserved edit mode on login
    dispatch('scenarios/exitEditMode', {}, { root: true });
  },
  async logout({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }) {
    commit('PURGE_AUTH');
    // exit edit mode on logout
    dispatch('scenarios/exitEditMode', {}, { root: true });
    // also end computing progress, since there is no other way to end computing progress if it somehow breaks
  },
};

export const mutations = {
  SET_AUTH(state: typeof moduleState, payload: any) {
    state.user = pick(payload, ['id', 'username', 'email']);
    state.roles = payload.roles || [];
    state.casl.rules = payload.rights.rules || [];
    state.casl.aliases = payload.rights.aliases || {};
  },
  PURGE_AUTH(state: typeof moduleState) {
    state.user = {};
    state.roles = [];
    state.casl.rules = [];
    state.casl.aliases = {};
  },
  UPDATE_AUTH(
    state: typeof moduleState,
    { roles, email, username }: { roles: Roles; email: string; username: string },
  ) {
    state.user.email = email;
    state.user.username = username;
    state.roles = roles || [];
  },
};

export default {
  namespaced: true,
  state: moduleState,
  getters,
  actions,
  mutations,
};
