import { add, sub } from 'date-fns';

export const today = ({ stripHours = false, stripMins = true } = {}) => {
  const jsDate = new Date();
  if (stripHours) jsDate.setHours(0, 0, 0, 0);
  if (stripMins) jsDate.setMinutes(0, 0, 0);
  return jsDate.toISOString();
};

export const tomorrow = ({ stripHours = false } = {}) => {
  const jsDate = add(new Date(), { days: 1 });
  if (stripHours) jsDate.setHours(0, 0, 0, 0);
  return jsDate.toISOString();
};

export const yesterday = ({ stripHours = false } = {}) => {
  const jsDate = sub(new Date(), { days: 1 });
  if (stripHours) jsDate.setHours(0, 0, 0, 0);
  return jsDate.toISOString();
};

export const getModelMonday = ({ hour = null }: { hour?: number | null } = {}) => {
  // hard-set day of the traffic model - probably just temporary
  const jsDate = new Date('2022-01-03');
  if (hour) jsDate.setHours(hour, 0, 0, 0);
  return jsDate.toISOString();
};

/* TmDate helpers, expect date iso string, returns same */
export const getTmDateHours = (tmDate: TmDate) => {
  if (!tmDate) return 0;
  const date = new Date(tmDate);
  return date.getHours();
};

export const setTmDateHours = (tmDate: TmDate, hours: number) => {
  if (!tmDate) return tmDate;
  const date = new Date(tmDate);
  date.setHours(hours);
  return date.toISOString();
};

export const addTmDateHours = (
  tmDate: TmDate,
  hours: number,
  {
    minHour = 0,
    maxHour = null,
  }: {
    minHour?: number;
    maxHour?: number | null;
  } = {},
) => {
  if (!tmDate) return tmDate;
  const date = new Date(tmDate);
  const currentHours = date.getHours();
  const currentMinutes = date.getMinutes();
  let hourDecimal = currentHours + hours + currentMinutes / 60;
  if (maxHour && hourDecimal > maxHour) hourDecimal = minHour;
  date.setHours(getHoursFromHoursNumber(hourDecimal), getMinutesFromHoursNumber(hourDecimal));
  return date.toISOString();
};

export const subtractTmDateHours = (tmDate: TmDate, hours: number) => {
  if (!tmDate) return tmDate;
  const date = new Date(tmDate);
  const currentHours = date.getHours();
  date.setHours(currentHours - hours);
  return date.toISOString();
};

export const isAfter = (date: TmDate, compareDate: TmDate) => {
  const dateD = toJsDate(date);
  const compareDateD = toJsDate(compareDate);
  if (!dateD || !compareDateD) return false;
  return dateD.valueOf() - compareDateD.valueOf() < 0;
};

export const toJsDate = (tmDate: TmDate, { stripHours = false, stripMins = false } = {}) => {
  if (!tmDate) return null;
  const jsDate = new Date(tmDate);
  if (stripHours) jsDate.setHours(0, 0, 0, 0);
  if (stripMins) jsDate.setMinutes(0, 0, 0);
  return jsDate;
};

export const toTmDate = (date: TmCommonDate) => {
  const jsDate = new Date(date);
  return jsDate.toISOString();
};

export const formatScenarioDate = (
  tmDate: TmDate | undefined,
  format: string | undefined = 'default',
  hideUnsetTime: boolean = true,
  i18n: () => { t: (phrase: string) => string; d: (date: TmDate, format: string) => string },
) => {
  const { t, d } = i18n();
  switch (typeof tmDate) {
    case 'undefined':
      return 'n/a';
    case 'object':
      // meaning null
      return t('scenarios.unlimited');
    case 'string':
      return d(tmDate, hideUnsetTime && d(tmDate, 'time') == '0:00' ? 'default' : format); // force no time display if there is none set
  }
};

export const formatScenarioDatePeriod = (
  dateFrom: TmDate | undefined,
  dateTo: TmDate | undefined,
  format: string | undefined = 'default',
  hideUnsetTime: boolean = true,
  shortenUnlimitedRange: boolean = true,
  i18n: () => { t: (phrase: string) => string; d: (date: TmDate, phrase: string) => string },
) => {
  if (shortenUnlimitedRange && dateFrom === null && dateTo === null) {
    // fully unlimited date range is just 'unlimited' (instead of unlimited - unlimited)
    return formatScenarioDate(dateFrom, format, hideUnsetTime, i18n);
  }

  return (
    formatScenarioDate(dateFrom, format, hideUnsetTime, i18n) +
    ' - ' +
    formatScenarioDate(dateTo, format, hideUnsetTime, i18n)
  );
};

export const formatAsApiDate = (tmDate: TmDate, round = true) => {
  if (!tmDate) return '';
  const date = new Date(tmDate);
  if (round) {
    date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
    date.setMinutes(0, 0, 0);
  }
  return date.toISOString().split('.')[0]; // strip milliseconds
};

const HOUR_MILLISECONDS = 1000 * 60 * 60;
// Expect hour with decimal minutes/seconds
export const formatTravelTime = (hourPart: number) => {
  const date = new Date(HOUR_MILLISECONDS * hourPart);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getSeconds();
  return `${hours ? hours + 'h ' : ''} ${minutes ? minutes + 'm ' : ''} ${seconds}s`;
};

// Hours number = float hours.decimal minutes 3:15 -> 3.25
export const getTmDateHoursNumber = (tmDate: TmDate, { stripMins = false } = {}) => {
  if (!tmDate) return 0;
  const date = new Date(tmDate);
  const h = date.getHours();
  const m = stripMins ? 0 : date.getMinutes();
  return h + m / 60;
};

export const getHoursFromHoursNumber = (n: number) => Math.trunc(n);

export const getMinutesFromHoursNumber = (n: number) => {
  const mins = 60 * (n % 1);
  return Math.round(mins);
};

// General date range evaluator used in all cases when we need to filter scenarios/events/mods that fit into single hour period (set by Calendar) OR hour to hour date range (set by Aggregation components)
export const getDateRangeIntersectionEvaluator = (calendarDate?: TmCalendarDate, unsetCalendarDateResponse = false) => {
  if (!calendarDate) return () => unsetCalendarDateResponse;
  const calendarDateFrom = Array.isArray(calendarDate) ? calendarDate[0] : calendarDate;
  const calendarDateEnd = Array.isArray(calendarDate) ? calendarDate[1] : addTmDateHours(calendarDate, 1); // widgets displays one hour range (from active date to active date + 1 hour)
  if (!calendarDateFrom || !calendarDateEnd) return () => unsetCalendarDateResponse;

  const evaluateDate = ({ dateFrom, dateTo }: { dateFrom: TmDate; dateTo: TmDate }) => {
    // DateFrom match included in intersection, DateTo match excluded (eg. for date 12:00, item starting at 12:00 is included, item ending at 12:00 excluded)
    return (dateFrom === null || dateFrom <= calendarDateEnd) && (dateTo === null || dateTo > calendarDateFrom);
  };
  return evaluateDate;
};

export const getDateRangeLengthInHours = (dateFrom: TmDate, dateTo: TmDate) => {
  const [d1, d2] = [toJsDate(dateFrom), toJsDate(dateTo)];
  if (!d1 || !d2) throw new Error('Date rage incomplete');
  const diffInMilliseconds = d2.getTime() - d1.getTime();
  const diffInHours = diffInMilliseconds / (60 * 60 * 1000);
  return diffInHours + 1; // we count both start hour and end hour, so the total diff must be increased by one
};
