<template>
  <div class="content">
    <i class="p-mr-3 pi pi-exclamation-triangle" />
    {{ $t(message) }} <br />
    {{ dependencies }}
  </div>

  <div class="actions p-d-flex">
    <tm-button :label="$t(label)" type="confirm" class="p-mr-3" @click="onConfirm" />
    <tm-button :label="$t('dialogs.cancel')" type="cancel" @click="closeDialog()" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import useDialog from '@composables/useDialog';

const { close: closeDialog, dialog } = useDialog();
const message = computed(() => {
  if (dialog.value.data.included === undefined) return 'dialogs.confirm deleting dependencies';
  return `dialogs.confirm ${dialog.value.data.included ? 'including' : 'excluding'} dependencies`;
});
const label = computed(() => {
  if (dialog.value.data.included === undefined) return 'dialogs.delete all dependencies';
  return `dialogs.${dialog.value.data.included ? 'include' : 'exclude'} all dependencies`;
});
const dependencies = computed(() => dialog.value.data.dependencies);
const onConfirm = (ev: MouseEvent) => dialog.value.callback?.onConfirm?.(ev);
</script>
