import { getSynchronizedModuleState, defaultAppLang } from '@store/helpers';

const moduleState = {
  lang: <TmLang>defaultAppLang,
};

export const mutations = {
  SET_LANG(state: typeof moduleState, { lang }: { lang: TmLang }) {
    state.lang = lang;
  },
};

export default {
  namespaced: true,
  state: getSynchronizedModuleState({
    module: 'locale',
    state: moduleState,
  }),
  mutations,
};
