import { computed } from 'vue';
import { usePrimeVue } from 'primevue/config';
import { useStore } from 'vuex';

// Exporting outside of the composable, since it is set as default value in store, before the composable is set up
export const dateFiltersOptions = {
  /** All month indexes (respects JavaScript month indexes 0-11) */
  months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  /** All day indexes (respects JavaScript day indexes 0-6) */
  days: [0, 1, 2, 3, 4, 5, 6],
  /** All hour indexes (respects JavaScript hour indexes 0-23) */
  hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
} as const;

export default function useDateFilters() {
  const primevue = usePrimeVue();
  const store = useStore();
  // month and day names are obtained from PrimeVue locales so they are compatible with other components (Calendar)
  const calendarLocales = computed(() => ({
    months: primevue.config.locale?.monthNames || [],
    days: primevue.config.locale?.dayNames || [],
  }));

  const aggregationDateFilters = computed(() => store.getters['map/getDateFilters']);

  const monthOptions = computed(() => [
    { name: calendarLocales.value.months[0], monthIndex: 0 },
    { name: calendarLocales.value.months[1], monthIndex: 1 },
    { name: calendarLocales.value.months[2], monthIndex: 2 },
    { name: calendarLocales.value.months[3], monthIndex: 3 },
    { name: calendarLocales.value.months[4], monthIndex: 4 },
    { name: calendarLocales.value.months[5], monthIndex: 5 },
    { name: calendarLocales.value.months[6], monthIndex: 6 },
    { name: calendarLocales.value.months[7], monthIndex: 7 },
    { name: calendarLocales.value.months[8], monthIndex: 8 },
    { name: calendarLocales.value.months[9], monthIndex: 9 },
    { name: calendarLocales.value.months[10], monthIndex: 10 },
    { name: calendarLocales.value.months[11], monthIndex: 11 },
  ]);

  const dayOptions = computed(() => [
    { name: calendarLocales.value.days[1], dayIndex: 1 },
    { name: calendarLocales.value.days[2], dayIndex: 2 },
    { name: calendarLocales.value.days[3], dayIndex: 3 },
    { name: calendarLocales.value.days[4], dayIndex: 4 },
    { name: calendarLocales.value.days[5], dayIndex: 5 },
    { name: calendarLocales.value.days[6], dayIndex: 6 },
    { name: calendarLocales.value.days[0], dayIndex: 0 },
  ]);

  const getMinMaxHourIndexes = (hours: number[]) => {
    const sortedIndexes = [...hours].sort((a, b) => a - b);
    const hourStart = sortedIndexes[0];
    const hourEnd = sortedIndexes[sortedIndexes.length - 1];
    return [hourStart, hourEnd];
  };

  const monthsNames = computed(() => {
    const monthIndexes = aggregationDateFilters.value.months;
    return monthOptions.value.filter((option) => monthIndexes.includes(option.monthIndex)).map((option) => option.name);
  });

  const dayNames = computed(() => {
    const dayIndexes = aggregationDateFilters.value.days;
    return dayOptions.value.filter((option) => dayIndexes.includes(option.dayIndex)).map((option) => option.name);
  });

  const areMonthsFiltered = computed(() => aggregationDateFilters.value.months.length !== monthOptions.value.length);
  const areDaysFiltered = computed(() => aggregationDateFilters.value.days.length !== dayOptions.value.length);
  const areHoursFiltered = computed(() => {
    const minMaxHourIndexes = getMinMaxHourIndexes(aggregationDateFilters.value.hours);
    return !(
      minMaxHourIndexes[0] === dateFiltersOptions.hours[0] &&
      minMaxHourIndexes[1] === dateFiltersOptions.hours[dateFiltersOptions.hours.length - 1]
    );
  });

  return {
    monthOptions,
    dayOptions,
    getMinMaxHourIndexes,
    monthsNames,
    aggregationDateFilters,
    dayNames,
    areDaysFiltered,
    areMonthsFiltered,
    areHoursFiltered,
  };
}
