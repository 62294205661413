<template>
  <div>
    <div v-if="$isMobile()" class="mobile-breadcrumb">
      <tm-button type="link" icon="pi-arrow-left" class="p-mx-2" @click="navigateBack()" />
      <span>{{ $t(`navigation.${getRouteNamePhrase()}`) }}</span>
    </div>

    <prime-breadcrumb v-else :home="{ icon: 'pi pi-home', to: { name: 'home' } }" :model="routeItems" class="break" />
  </div>
</template>

<script setup lang="ts">
import PrimeBreadcrumb from 'primevue/breadcrumb';
import { computed, ref, toRef, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

type RouteNames = Record<string, string | undefined>; // e.g. { scenarios: 'My scenario name', events: 'My event name' }
type RouteItem = { label: string; to: { name: string }; prev: string };

const props = defineProps<{
  names?: RouteNames; // Object with model names that should replace active general route names
}>();

const route = useRoute();
const router = useRouter();
const store = useStore();
const { t, locale } = useI18n();

const routeItems = ref<RouteItem[]>([]);
const currentRouteName = computed<string>(() => route.name as string);
const isInsideComputation = computed<boolean>(() => store.getters['scenarios/isInsideComputation'](route.params?.id));

const getRouteNamePhrase = (routeName = currentRouteName.value) => routeName.split('.').join(' ');

const navigateBack = () => {
  const routeName = route.name as string;
  const levels = routeName?.split('.') || [];
  if (levels.length <= 1 || levels[1] == 'login') return router.push({ name: 'home' });
  const parentRoute = levels[levels.length - 2];
  router.push({ name: parentRoute }); // maybe just use router.go(-1);
};

watch(
  [currentRouteName, toRef(props, 'names'), isInsideComputation, locale],
  ([routeName, propsNames]) => {
    _setItemsByRouteName(routeName);
    _setActualRouteModelNames(propsNames);
  },
  { immediate: true },
);

function _setItemsByRouteName(routeName: string) {
  const items: RouteItem[] = [];
  const levels: string[] = routeName.split('.');

  let itemRouteName: string;
  let prev: string;
  levels.forEach((lvl) => {
    itemRouteName = itemRouteName ? `${itemRouteName}.${lvl}` : lvl;
    const label = t(`navigation.${getRouteNamePhrase(itemRouteName)}`);
    items.push({ label: label, to: { name: itemRouteName }, prev });
    if (isInsideComputation.value && itemRouteName == 'scenarios.events') {
      // special breadcrumb item for the computation mode
      items.push({ label: t(`navigation.computation`), to: { name: 'scenarios.events' }, prev });
    }
    prev = lvl;
  });

  routeItems.value = items;
}

function _setActualRouteModelNames(names?: RouteNames) {
  if (!names) return;

  for (const [type, name] of Object.entries(names)) {
    const item = routeItems.value.find((el) => el.prev === type);
    if (item && name) item.label = name;
  }
}
</script>

<style scoped>
.break {
  display: block;
  margin-bottom: 1rem;
}
.mobile-breadcrumb,
.mobile-breadcrumb :deep(.pi) {
  color: white;
}
.mobile-breadcrumb {
  line-height: 1.5rem;
  background-color: #0094d2;
  padding: 1rem 0;
  margin-bottom: 2rem;
  font-size: 1.6rem;
}
</style>
