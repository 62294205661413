import MapLayout from '@layouts/Map.vue';
import { parseRouteParamAsNumber, parseRouteParamAsString } from '@utils/route-param';
import type { RouteRecordRaw } from 'vue-router';

const UserView = () => import('@views/user/User.vue');
const LoginView = () => import('@views/user/Login.vue');
const LostPasswordView = () => import('@views/user/LostPassword.vue');
const UserSettingsView = () => import('@views/user/Settings.vue');
const PasswordResetView = () => import('@views/user/PasswordReset.vue');

const routes: RouteRecordRaw[] = [
  {
    path: '/user',
    name: 'user',
    component: UserView,
    meta: {
      requiresAuth: true,
      layout: MapLayout,
    },
  },
  {
    path: '/user/login',
    name: 'user.login',
    component: LoginView,
    meta: {
      requiresAuth: false,
      layout: MapLayout,
    },
  },
  {
    path: '/user/lost-password',
    name: 'user.password recovery',
    component: LostPasswordView,
    meta: {
      requiresAuth: false,
      layout: MapLayout,
    },
  },
  {
    path: '/user/:userId/password-reset/:token',
    name: 'user.password reset',
    component: PasswordResetView,
    meta: {
      requiresAuth: false,
      layout: MapLayout,
    },
    props: ({ params }) => ({
      userId: parseRouteParamAsNumber(params.userId),
      token: parseRouteParamAsString(params.token),
    }),
  },
  {
    path: '/user/settings',
    name: 'user.settings',
    component: UserSettingsView,
    meta: {
      requiresAuth: true,
      layout: MapLayout,
    },
  },
];

export default routes;
