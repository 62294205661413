<template>
  <vm-map-element :vm-map="map" :class="[plainBaseColor ? 'plain-color-' + plainBaseColor : '']">
    <keep-alive>
      <component
        :is="viewerMode?.component || 'div'"
        :mode-key="mapMode"
        :register-mode="registerViewerMode"
        v-bind="viewerMode?.data ?? {}"
      />
    </keep-alive>
  </vm-map-element>
</template>

<script setup lang="ts">
import { computed, reactive, markRaw, watchEffect, watch, toRaw, onBeforeMount, defineAsyncComponent } from 'vue';
import { VmMapElement } from 'vuemap';
import { useMainMap } from './map';
import { setStyleConfig } from './mapStyling/styleService';
import { useOsm, useOsmLight, useOsmWorld } from './baseLayers';
import { useStore } from 'vuex';
import { debounce } from 'lodash-es';
import { ENABLED_DATA_MODES } from '@composables/useDataModes';
import {
  MP_LIVE_VIEWER,
  MP_HISTORICAL_VIEWER,
  MP_MODEL_STA_VIEWER,
  MP_MODEL_DTA_VIEWER,
  MP_MODEL_STA_COMPARISON,
  MP_MODEL_DTA_COMPARISON,
  MP_HISTORICAL_COMPARISON,
  MP_COMBINED_COMPARISON,
  MP_H_AGGREGATION_COMPARISON,
  getModelTypeByModelName,
  MP_HISTORICAL_AGGREGATION,
  MP_MODEL_STA_AGGREGATION,
  MP_MODEL_STA_AGGREGATION_COMPARISON,
} from '@keys/index';
import type { MapBase, Center, Zoom, MapStyle } from '@store/modules/map';
import type { Ref } from 'vue';

type ViewerComponentData = {
  date?: Ref<TmCalendarDate>;
  dates?: Ref<TmCalendarDate>;
  scenarios?: Ref<TmComparisonItems>;
  modelType?: Ref<TmModelType>;
  mapStyle?: Ref<MapStyle>;
  isReversedComparison?: Ref<boolean>;
};
type ViewerMode = {
  component: string;
  data?: ViewerComponentData;
};

const store = useStore();
const map = useMainMap();

const mapMode = computed<TmMapMode>(() => store.state.map.mapMode);
const viewerModes = reactive<Map<string, ViewerMode>>(new Map());
const calendarDate = computed<TmCalendarDate>(() => store.getters['map/getCalendarDate']());
const modelType = computed<TmModelType>(() => store.state.scenarios.model.type);
const mapStyle = computed<MapStyle>(() => store.state.map.mapStyle);
const mapBaseId = computed<MapBase>(() => store.state.map.base);
const plainBaseColor = computed<string>(() => mapBaseId.value?.split('plain')?.[1]?.toLocaleLowerCase());
const viewerMode = computed<ViewerMode | undefined>(() => viewerModes.get(mapMode.value));
const mapPosition = computed<{ zoom: Zoom; center: Center }>(() => ({
  zoom: store.state.map.zoom,
  center: store.state.map.center,
}));

const addViewerMode = ({ key, component, data = {} }: ViewerMode & { key: string }) => {
  if (viewerModes.has(key)) return;
  viewerModes.set(key, {
    component: markRaw(defineAsyncComponent(() => import(`./mapModes/${component}.vue`))),
    data,
  });
};

const setupBaseMaps = () => {
  const enabledBaseMaps = import.meta.env.VITE_ENABLED_BASE_MAPS?.split(', ') || [];
  if (enabledBaseMaps.includes('osm')) map.manager.addBaseMap(useOsm);
  if (enabledBaseMaps.includes('osmLight')) map.manager.addBaseMap(useOsmLight);
  if (enabledBaseMaps.includes('osmWorld')) map.manager.addBaseMap(useOsmWorld);
};

const setupViewerModes = () => {
  const isEnabledDataMode = (dataModeKey: TmDataMode) => ENABLED_DATA_MODES.includes(dataModeKey);
  const isEnabledModelType = (modelType: TmModelType) => {
    // this is only usable because the app is refreshed when the model is switched - therefore we only have one model type enabled at a time
    // TODO: if the model switching is ever made 'reactive' -> this would need to change and we would need the full list of model types fetched prior to this check
    const activeModelType = getModelTypeByModelName(store.state.scenarios.model.name);
    return modelType === activeModelType;
  };

  if (isEnabledDataMode('live'))
    addViewerMode({
      key: MP_LIVE_VIEWER,
      component: 'ModeLiveViewer',
    });

  if (isEnabledDataMode('historical')) {
    addViewerMode({
      key: MP_HISTORICAL_VIEWER,
      component: 'ModeHistoricalViewer',
      data: { date: calendarDate },
    });
    addViewerMode({
      key: MP_HISTORICAL_AGGREGATION,
      component: 'ModeHistoricalAggregation',
      data: { dates: calendarDate },
    });
  }

  if (isEnabledDataMode('model') && isEnabledModelType('DTA'))
    addViewerMode({
      key: MP_MODEL_DTA_VIEWER,
      component: 'ModeModelDTAViewer',
      data: { date: calendarDate, mapStyle },
    });

  if (isEnabledDataMode('model') && isEnabledModelType('STA')) {
    addViewerMode({
      key: MP_MODEL_STA_VIEWER,
      component: 'ModeModelSTAViewer',
      data: { date: calendarDate, mapStyle },
    });
    addViewerMode({
      key: MP_MODEL_STA_AGGREGATION,
      component: 'ModeModelSTAAggregation',
      data: { dates: calendarDate, mapStyle },
    });
  }

  if (isEnabledDataMode('comparison') && isEnabledDataMode('model') && isEnabledModelType('DTA'))
    addViewerMode({
      key: MP_MODEL_DTA_COMPARISON,
      component: 'ModeModelDTAComparison',
      data: {
        dates: calendarDate,
        scenarios: computed(() => store.state.map.comparison),
        modelType,
      },
    });

  if (isEnabledDataMode('comparison') && isEnabledDataMode('model') && isEnabledModelType('STA')) {
    addViewerMode({
      key: MP_MODEL_STA_COMPARISON,
      component: 'ModeModelSTAComparison',
      data: {
        dates: calendarDate,
        scenarios: computed(() => store.state.map.comparison),
      },
    });
    addViewerMode({
      key: MP_MODEL_STA_AGGREGATION_COMPARISON,
      component: 'ModeModelSTAAggregationComparison',
      data: {
        dates: calendarDate,
        scenarios: computed(() => store.state.map.comparison),
      },
    });
  }

  if (isEnabledDataMode('comparison') && isEnabledDataMode('historical')) {
    addViewerMode({
      key: MP_HISTORICAL_COMPARISON,
      component: 'ModeHistoricalComparison',
      data: { dates: calendarDate },
    });
    addViewerMode({
      key: MP_H_AGGREGATION_COMPARISON,
      component: 'ModeHistoricalAggregationComparison',
      data: { dates: calendarDate },
    });
  }

  if (
    isEnabledDataMode('comparison') &&
    isEnabledDataMode('historical') &&
    isEnabledDataMode('model') &&
    isEnabledModelType('STA')
  )
    addViewerMode({
      key: MP_COMBINED_COMPARISON,
      component: 'ModeCombinedComparison',
      data: {
        dates: calendarDate,
        scenarios: computed(() => store.state.map.comparison),
        modelType,
        isReversedComparison: computed(() => !!store.state.map.isReversedComparison),
      },
    });
};

const registerViewerMode = (key: string, layers: any) => map.manager.createMapState(key, layers); // TS_TODO: layers

const updateMapPosition = debounce(() => {
  const { center, zoom } = toRaw(map.view.state);
  store.dispatch('map/setPosition', { center, zoom });
}, 100);

// Sets currently selected mapMode as mapPreset (even if mode not found here, might come from somewhere else)
watchEffect(() => map.manager.setMapState(mapMode.value));

watch(mapBaseId, (id) => map.manager.setBaseMap(id.includes('plain') ? null : id), { immediate: true });

watch(map.view.state, updateMapPosition);

watch(mapPosition, (newPosition) => map.view.set(newPosition));

watch(
  mapStyle,
  () => {
    setStyleConfig(mapStyle.value);
    map.manager.forceRender();
  },
  { deep: true, immediate: true },
);

watch(
  () => map.manager.state.loading,
  (isLoading) => {
    // TODO: this is not reliable, sometimes causes unfinished loading bug...
    // store.dispatch(isLoading ? 'layout/startLoading' : 'layout/finishLoading');
    console.info('VUEMAP loading state ', isLoading ? 'started' : 'finished');
  },
);

onBeforeMount(() => {
  setupBaseMaps();
  setupViewerModes();
});
</script>

<style>
/* OL layer css filter */
.tm-layer-pastel {
  filter: grayscale(70%);
}
.plain-color-white {
  background-color: white;
}
.plain-color-grey {
  background-color: #e9ecef;
}
</style>
