import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import authModule from './modules/auth';
import layoutModule from './modules/layout';
import mapModule from './modules/map';
import localeModule from './modules/locale';
import scenariosModule from './modules/scenarios';

const appNamespace: string = import.meta.env.VITE_APP_NAMESPACE || import.meta.env.VITE_MODEL_CACHE_PREFIX || 'tramod';
const appBase: string = import.meta.env.BASE_URL.replace(/[^a-z]/gi, '') || '';
const appVersion: string = __APP_VERSION__ || '0.0.0';
const localStorageKey = `${appNamespace}_${appBase}_${appVersion}`;

export function getStore() {
  return createStore({
    actions: {
      // global store action for refreshing App so it is easier to mock this in tests
      refreshApp: (context, url?: string) => (url ? window.location.replace(url) : window.location.reload()),
    },
    modules: {
      auth: authModule,
      layout: layoutModule,
      map: mapModule,
      locale: localeModule,
      scenarios: scenariosModule,
    },
    plugins: [
      createPersistedState({
        key: localStorageKey,
        paths: [
          'auth', // persist entire auth module state (user, roles, permissions)
          'scenarios.editMode',
          'scenarios.filters',
          'scenarios.sorting',
          'scenarios.model.timestamp', // preserve model timestamp to prevent infinite app reload (only needed until its possible to refresh layers without app reload)
        ],
      }),
    ],
  });
}

export default getStore();
