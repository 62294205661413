import { ref, computed } from 'vue';
import useMainApiRequest from '@composables/useMainApiRequest';
import { registerCacheInvalidation } from '@composables/useCache';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export type UserRole = { roleKey: TmRole; label: string; disabled: boolean };

const allUsersFetched = ref(false);
const cachedUsers = ref<Record<number, TmUser>>({});
const cachedOrganizations = ref<Record<number, TmOrganization>>({});

registerCacheInvalidation('users', invalidateUsers);
registerCacheInvalidation('organizations', invalidateOrganizations);

export default function useUsers(defUserId?: number) {
  const users = computed(() => Object.values(cachedUsers.value));
  const user = computed<TmUser>(() => {
    if (!defUserId) return {};
    return cachedUsers.value[defUserId] ?? {};
  });
  const organizations = computed(() => Object.values(cachedOrganizations.value));
  const { t } = useI18n();
  const { makeRequest } = useMainApiRequest();
  const router = useRouter();
  const store = useStore();
  const loggedUserId: number = store.state.auth.user.id;

  async function fetchUsers(hiddenFields = false) {
    if (_isCacheAvailable()) return;
    invalidateUsers();

    await makeRequest({
      url: 'users',
      method: 'get',
      params: {
        ...(hiddenFields && { hiddenFields: true }),
      },
      message: {
        error: {
          404: { summary: t('users.no users found'), severity: 'info' },
          default: t('users.error while fetching users'),
        },
      },
      onSuccess: (result: { users: TmUser[] }) => {
        result.users.forEach((user) => {
          if (user.id) cachedUsers.value[user.id] = user;
        });
        allUsersFetched.value = true;
      },
    });
  }

  async function fetchUser(userId = defUserId) {
    if (!userId) throw new Error('No user id provided for the fetch');
    if (_isCacheAvailable({ modelId: userId })) return;
    invalidateUsers(userId);

    await makeRequest({
      url: `users/${userId}`,
      method: 'get',
      message: {
        error: t('users.error while fetching user'),
      },
      onSuccess: (result: { user: TmUser }) => {
        cachedUsers.value[userId] = result.user;
      },
    });
  }

  async function createUser(user: TmUser) {
    await makeRequest({
      url: `users`,
      method: 'post',
      data: user,
      message: {
        success: t('users.user created'),
        error: t('users.error while creating user'),
      },
      onSuccess: async (result: { user: TmUser }) => {
        const { id, organization, email } = result.user;

        if (id) cachedUsers.value[id] = result.user;
        if (organization && _isNewOrganization(organization)) invalidateOrganizations();
        // send password reset request with the 'create' type
        if (email) requestPasswordReset({ type: 'create', email });
      },
    });
  }

  async function updateUser(user: TmUser) {
    if (!user.id) throw new Error('No user id provided for the update');
    await makeRequest({
      url: `users/${user.id}`,
      method: 'patch',
      data: user,
      message: {
        success: t('users.user saved'),
        error: t('users.error while saving user'),
      },
      onSuccess: async (result: { user: TmUser }) => {
        const { id, organization } = result.user;

        if (id) cachedUsers.value[id] = result.user;
        if (id && loggedUserId == id) {
          // if updating currently logged user -> also update store auth module
          store.commit('auth/UPDATE_AUTH', result.user);
        }
        if (organization && _isNewOrganization(organization)) invalidateOrganizations();
      },
    });
  }

  async function deleteUser(userId = defUserId) {
    if (!userId) throw new Error('No user id provided for the deletion');
    await makeRequest({
      url: `users/${userId}`,
      method: 'delete',
      message: {
        success: t('users.user deleted'),
        error: t('users.failed to delete user'),
      },
      onSuccess: () => {
        delete cachedUsers.value[userId];
      },
    });
  }

  async function requestPasswordReset({
    email,
    type = 'reset',
    leaveAfter = false,
  }: {
    email?: string;
    type?: string;
    leaveAfter?: boolean;
  }) {
    if (!email) throw new Error('No e-mail provided for password reset.');
    const { subject, body } = _getLocaleEmailContent(t, type);
    const host = `${window.location.origin}${import.meta.env.BASE_URL}`;

    await makeRequest({
      url: 'users/password-reset',
      method: 'post',
      data: { email, subject, body, type, host },
      message: {
        success: t('users.reset email sent'),
        error: t('users.failed to send reset email'),
      },
      onSuccess: () => {
        // redirect to the login view
        if (leaveAfter) router.push({ name: 'user.login' });
      },
    });
  }

  async function resetPassword({
    userId,
    token,
    email,
    password,
  }: {
    userId: number;
    token: string;
    email: string;
    password: string;
  }) {
    await makeRequest({
      url: `users/${userId}/password-reset/${token}`,
      method: 'post',
      data: { email, password },
      message: {
        success: t('users.password reset successful'),
        error: t('users.failed to reset password'),
      },
      onSuccess: () => {
        // redirect to the login view
        router.push({ name: 'user.login' });
      },
    });
  }

  async function fetchOrganizations() {
    if (_isCacheAvailable({ model: 'organizations' })) return;
    invalidateOrganizations();

    await makeRequest({
      url: 'users/organizations',
      method: 'get',
      message: {
        error: {
          404: { summary: t('users.no organizations found'), severity: 'info' },
          default: t('users.error while fetching organizations'),
        },
      },
      onSuccess: (result: { organizations: TmOrganization[] }) => {
        result.organizations.forEach((org) => {
          if (org.id) cachedOrganizations.value[org.id] = org;
        });
      },
    });
  }

  function _isNewOrganization(orgName: string) {
    return !!orgName && !organizations.value.find((org) => org.name === orgName);
  }

  function _isCacheAvailable({ modelId, model = 'users' }: { modelId?: number; model?: string } = {}) {
    switch (model) {
      case 'users':
        return modelId ? !!cachedUsers.value[modelId] : allUsersFetched.value;
      case 'organizations':
        return !!Object.keys(cachedOrganizations.value).length;
      default:
        return false;
    }
  }

  const allUserRoles: UserRole[] = [
    { roleKey: 'admin', label: 'users.admin', disabled: false },
    { roleKey: 'userManager', label: 'users.user manager', disabled: false },
    { roleKey: 'textManager', label: 'users.text manager', disabled: false },
    { roleKey: 'loggedIn', label: 'users.basic user', disabled: false },
  ] as const;

  const isAdminRoleSelected = (userRoles: UserRole[]) => userRoles.some((role) => role.roleKey === 'admin');

  const getAvailableUserRoles = ({
    getAllRoles = false,
    userRoles = [],
  }: {
    getAllRoles?: boolean;
    userRoles?: UserRole[];
  } = {}): UserRole[] => {
    if (getAllRoles) return allUserRoles;

    // admin is editing admin or the admin role was selected on currently non-admin user
    if (user.value.roles?.includes('admin') || isAdminRoleSelected(userRoles)) {
      return allUserRoles.map((role) => (role.roleKey !== 'admin' ? { ...role, disabled: true } : role));
    }
    // admin is editing other non-admin users
    if (store.getters['auth/hasRole']('admin')) return allUserRoles;

    // userManager is editing himself
    if (store.getters['auth/hasRole']('userManager') && user.value.roles?.includes('userManager')) {
      const availableUserRoles = [];

      for (const role of allUserRoles) {
        if (role.roleKey === 'admin') continue;
        if (role.roleKey === 'userManager') {
          availableUserRoles.push({ ...role, disabled: true });
        } else {
          availableUserRoles.push(role);
        }
      }
      return availableUserRoles;
    }
    // userManager is editing others
    return allUserRoles.filter((role) => role.roleKey !== 'admin' && role.roleKey !== 'userManager');
  };

  const getRoleName = (role: TmRole) => {
    const roleLabel = allUserRoles.find((r) => r.roleKey === role)?.label;
    return roleLabel ? t(roleLabel) : role;
  };

  return {
    users,
    user,
    fetchUsers,
    fetchUser,
    createUser,
    updateUser,
    deleteUser,
    requestPasswordReset,
    resetPassword,
    organizations,
    fetchOrganizations,
    invalidateUsers,
    invalidateOrganizations,
    availableUserRoles: ref<UserRole[]>(getAvailableUserRoles()),
    getAvailableUserRoles,
    isAdminRoleSelected,
    getRoleName,
    isUserLoggedAs: (role: TmRole) => store.getters['auth/hasRole'](role),
  };
}

function invalidateUsers(userId?: number) {
  allUsersFetched.value = false;

  userId ? delete cachedUsers.value[userId] : (cachedUsers.value = {});
}

function invalidateOrganizations() {
  cachedOrganizations.value = {};
}

function _getLocaleEmailContent(t: (p: string, a: any, l: { locale: TmLang }) => string, type: string) {
  const isCreating = type === 'create';
  const t_c = (phrase: string, args: any = null, lang: TmLang = 'cs') => t(phrase, args, { locale: lang });

  const subject = isCreating
    ? `${t_c('users.password create request')}, ${t_c('users.password create request', null, 'en')}`
    : `${t_c('users.password reset request')}, ${t_c('users.password reset request', null, 'en')}`;
  // TODO: create custom template instead of just the string
  // (Vue component templates can not be easily transformed to string)
  const body = `<p>
    ${t_c('users.greetings')},<br>
    ${
      isCreating
        ? t_c('users.create password for account', { account: '[EMAIL]' })
        : t_c('users.reset password for account', { account: '[EMAIL]' })
    }:<br>
    <br>
    <a href='[LINK]'>[LINK]</a><br>
    <br>
    ${isCreating ? t_c('users.link will expire in three days') : t_c('users.link will expire in one hour')}<br>
    <br>
    ${isCreating ? '' : t_c('users.ignore if not requested')}<br>
    <br>
    ${t_c('users.thank you')},<br>
    ${t_c('users.roadtwin team')}<br>
    <br>
    <hr><br>
    <br>
    English: <br>
    ${t_c('users.greetings', null, 'en')},<br>
    ${
      isCreating
        ? t_c('users.create password for account', { account: '[EMAIL]' }, 'en')
        : t_c('users.reset password for account', { account: '[EMAIL]' }, 'en')
    }:<br>
    <br>
    <a href='[LINK]'>[LINK]</a><br>
    <br>
    ${
      isCreating
        ? t_c('users.link will expire in three days', null, 'en')
        : t_c('users.link will expire in one hour', null, 'en')
    }<br>
    <br>
    ${isCreating ? '' : t_c('users.ignore if not requested', null, 'en')}<br>
    <br>
    ${t_c('users.thank you', null, 'en')},<br>
    ${t_c('users.roadtwin team', null, 'en')}
    </p>`;

  return { subject, body };
}
