<template>
  <template v-for="({ name }, i) in widgets" :key="name">
    <component
      :is="widgetComponents[name].component"
      v-if="widgetComponents[name]"
      v-bind="widgetComponents[name].props"
      :class="{ 'p-mb-3': i + 1 < widgets.length }"
    />
  </template>
</template>

<script setup lang="ts">
import { computed, reactive, defineAsyncComponent, markRaw } from 'vue';
import { useStore } from 'vuex';
import useWidgets from '@composables/useWidgets';
import type { Component } from 'vue';

const store = useStore();
const { activeWidgets: widgets } = useWidgets();

const mapMode = computed<TmMapMode>(() => store.state.map.mapMode);
const dataMode = computed<TmDataMode>(() => store.getters['map/getDataMode']);
const isDTA = computed<boolean>(() => store.getters['scenarios/isDtaModelTypeActive']);
const calendarDate = computed<TmCalendarDate>(() => store.getters['map/getCalendarDate']());

const getComponent = (componentName: string) => {
  return markRaw(defineAsyncComponent(() => import(`./widgets/${componentName}.vue`))) as Component;
};

const widgetComponents = reactive<Record<string, { component: Component; props?: { [key: string]: any } }>>({
  mapPopup: {
    component: getComponent('WidgetMapInfo'),
  },
  calendar: {
    component: getComponent('WidgetCalendar'),
    props: { mapMode, isDTA },
  },
  comparison: {
    component: getComponent('WidgetComparison'),
    props: { mapMode, isDTA },
  },
  legend: {
    component: getComponent('WidgetLegend'),
    props: { mapMode, dataMode },
  },
  eventList: {
    component: getComponent('WidgetEventList'),
    props: { date: calendarDate },
  },
  shortestPath: {
    component: getComponent('WidgetShortestPath'),
    props: { date: calendarDate },
  },
  mapSettings: {
    component: getComponent('WidgetMapSettings'),
    props: { dataMode, isDTA, mapMode },
  },
  timeline: {
    component: getComponent('WidgetTimeline'),
  },
  aggregation: {
    component: getComponent('WidgetAggregation'),
    props: { mapMode },
  },
  aggregationComparison: {
    component: getComponent('WidgetAggregationComparison'),
    props: { mapMode, isDTA },
  },
  historicalExport: {
    component: getComponent('WidgetHistoricalExport'),
    props: { dataMode, date: calendarDate },
  },
  modelExport: {
    component: getComponent('WidgetModelExport'),
    props: { mapMode, date: calendarDate },
  },
});

defineExpose({ widgets });
</script>
