import { watch, ref } from 'vue';
import useDate from '@composables/useDate';
import { useStore } from 'vuex';
import type { Ref } from 'vue';
import { debounce } from 'lodash-es';

type CacheKey = string; // e.g. 'scenarios' or 'events'
type FilterGroupKey = string; // e.g. 'date' or 'nodeTransit'
type Operator = 'EQ' | 'NEQ' | 'LT' | 'LTE' | 'GT' | 'GTE';
export type Filter = {
  name: string;
  label?: string;
  field?: string;
  value?: unknown;
  value2?: unknown;
  operator?: Operator;
  operator2?: Operator;
};
export type Sorting = { name: string; label?: string };
type FilterRecord = Record<FilterGroupKey, Filter[]>;
type SelectedFilters = Record<FilterGroupKey, Ref<Filter[]>>;
type SelectedSorting = Ref<Sorting>;
type SearchPhrase = Ref<string>;

export default function useFilters<T>({
  items,
  cacheKey,
  selectedFilters,
  selectedSorting = ref({ name: 'id' }),
  searchPhrase = ref(''),
}: {
  items: Ref<T[]>;
  cacheKey?: CacheKey; // if not set - it will ignore stored filters/sorting and will not cache any selection
  selectedFilters?: SelectedFilters;
  selectedSorting?: SelectedSorting;
  searchPhrase?: SearchPhrase;
}) {
  const { today: tmToday, toJsDate } = useDate();
  const store = useStore();
  const filteredItems = ref([...items.value]) as Ref<T[]>;
  const isUsingCache: boolean = !!cacheKey;
  const userSpecificCacheKey: string = `${cacheKey}-${store.state.auth.user.id}`;
  const storedFilters: FilterRecord | undefined = store.state.scenarios.filters[userSpecificCacheKey];
  const storedSorting: Sorting | undefined = store.state.scenarios.sorting[userSpecificCacheKey];

  // change default filters/sorting based on store state
  selectStoredFilters(storedFilters, selectedFilters);
  selectStoredSorting(storedSorting, selectedSorting);

  // apply initial filter and sorting - using default (stored) state
  applyFilters(selectedFilters);
  applySorting(selectedSorting);

  // re-apply filters whenever items are fetched/changed + re-sort
  watch(items, () => {
    applyFilters(selectedFilters);
    applySorting(selectedSorting);
  });

  // re-apply filters whenever filter selection is changed and store them
  if (selectedFilters)
    watch(Object.values(selectedFilters), () => {
      applyFilters(selectedFilters);
      storeFilters(selectedFilters);
      applySorting(selectedSorting); // we need to re-sort since filtering uses original item list
    });

  // re-sort whenever sorting selection changes + store sorting
  watch(selectedSorting, () => {
    applySorting(selectedSorting);
    storeSorting(selectedSorting);
  });

  // debounced re-filter and re-sort on updated search phrase
  watch(
    searchPhrase,
    debounce(() => {
      applyFilters(selectedFilters);
      applySorting(selectedSorting);
    }, 500),
  );

  function selectStoredFilters(storedFilters?: FilterRecord, selectedFilters?: SelectedFilters) {
    if (!isUsingCache || !storedFilters || !selectedFilters) return;

    Object.keys(selectedFilters).forEach((filterGroup) => {
      if (storedFilters[filterGroup]) {
        selectedFilters[filterGroup].value = storedFilters[filterGroup];
      }
    });
  }

  function applyFilters(filters?: SelectedFilters) {
    filteredItems.value = [...items.value];
    if (!filters) return;

    Object.keys(filters).forEach((filterGroup) => {
      const filterSelection = filters[filterGroup].value ?? [];
      if (filterGroup === 'date') filterByDate(filterSelection);
      else filterByType(filterSelection);
    });

    filterByPhrase();
  }

  function storeFilters(filters?: SelectedFilters) {
    if (!isUsingCache || !filters) return;

    Object.keys(filters).forEach((filterGroup) => {
      store.commit('scenarios/SET_FILTERS', {
        cacheKey: userSpecificCacheKey,
        group: filterGroup,
        selection: filters[filterGroup].value ?? [],
      });
    });
  }

  function filterByType(appliedFilters: Filter[]) {
    if (!appliedFilters.length) return;

    filteredItems.value = filteredItems.value.filter((item) =>
      appliedFilters.some((filter) => {
        const itemValue = item[filter.field as keyof T];
        const opResult = _evaluateByOperator(itemValue, filter.value, filter.operator);
        return filter.value2 === undefined || filter.operator2 === undefined
          ? opResult
          : opResult && _evaluateByOperator(itemValue, filter.value2, filter.operator2);
      }),
    );
  }

  function filterByDate(appliedFilters: Filter[], { dateFromKey = 'dateFrom', dateToKey = 'dateTo' } = {}) {
    if (!appliedFilters.length) return;
    const today = toJsDate(tmToday(), { stripHours: true });
    if (!today) return;

    filteredItems.value = filteredItems.value.filter((item) => {
      const d1 = item[dateFromKey as keyof T] as TmDate;
      const d2 = item[dateToKey as keyof T] as TmDate;
      // Null date means that the item is not date restricted from that side
      const dateFrom = d1 ? toJsDate(d1, { stripHours: true }) : null;
      const dateTo = d2 ? toJsDate(d2, { stripHours: true }) : null;
      return appliedFilters.some((filter) => {
        if (filter.name === 'current') {
          // is current
          return (!dateFrom || dateFrom <= today) && (!dateTo || today <= dateTo);
        } else if (filter.name === 'future') {
          // is in the future, should work only with second comparison but less magical
          return dateFrom && dateFrom > today;
        } else if (filter.name === 'past') {
          // is in the past
          return dateTo && dateTo < today;
        }
      });
    });
  }

  function filterByPhrase() {
    const phrase = searchPhrase.value ? searchPhrase.value.trim() : null;
    if (!phrase) return;
    filteredItems.value = filteredItems.value.filter((item) => {
      const nameField = <keyof T>'name';
      const itemName = String(item[nameField]);
      const fitsName = itemName?.toLowerCase().includes(phrase.toLowerCase());
      const externalIdField = <keyof T>'externalId';
      if (!item[externalIdField]) return fitsName;
      const itemExternalId = String(item[externalIdField]);
      const fitsExternalId = itemExternalId?.toLowerCase().includes(phrase.toLowerCase());
      return fitsName || fitsExternalId;
    });
  }

  function selectStoredSorting(storedSorting?: Sorting, selectedSorting?: SelectedSorting) {
    if (!isUsingCache || !selectedSorting || !storedSorting?.name) return;
    selectedSorting.value = storedSorting;
  }

  function applySorting(sorting: SelectedSorting) {
    const sortBy = sorting.value.name;
    if (sortBy === 'name') sortByName();
    if (sortBy === 'id') sortById();
    if (sortBy === 'date') sortByDate();
    if (sortBy === 'externalId') sortByExternalId();
  }

  function sortByName() {
    const sortBy = <keyof T>'name';
    filteredItems.value = filteredItems.value.sort((a, b) => {
      const n1 = a[sortBy];
      const n2 = b[sortBy];
      if (typeof n1 !== 'string' || typeof n2 !== 'string') return 0;
      // @ts-ignore // performing NATURAL SORTING
      return n1.localeCompare(n2, undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    });
  }

  function sortById() {
    const sortBy = <keyof T>'id';
    filteredItems.value = filteredItems.value.sort((a, b) => (a[sortBy] < b[sortBy] ? 1 : -1));
  }

  function sortByExternalId() {
    const sortBy = <keyof T>'externalId';
    filteredItems.value = filteredItems.value.sort((a, b) => {
      const itemA = Number(a[sortBy]);
      const itemB = Number(b[sortBy]);
      if (!itemA) return 1;
      if (!itemB) return -1;
      return itemA < itemB ? -1 : 1;
    });
  }

  function sortByDate() {
    const sortBy = <keyof T>'createdAt';
    filteredItems.value = filteredItems.value.sort((a, b) => {
      const d1 = toJsDate(a[sortBy] as TmDate);
      const d2 = toJsDate(b[sortBy] as TmDate);
      if (d1 === null) return 1;
      if (d2 === null) return -1;
      return d1 < d2 ? 1 : -1;
    });
  }

  function storeSorting(sortByRef: SelectedSorting) {
    if (!isUsingCache) return;
    store.commit('scenarios/SET_SORTING', { cacheKey: userSpecificCacheKey, sortBy: sortByRef.value });
  }

  return { filteredItems, filterByType, filterByDate, sortByName, sortById, sortByDate, sortByExternalId };
}

function _evaluateByOperator(val1: any, val2: any, op = <Operator>'EQ') {
  switch (op) {
    case 'EQ':
      return val1 === val2;
    case 'NEQ':
      return val1 !== val2;
    case 'LT':
      return val1 < val2;
    case 'LTE':
      return val1 <= val2;
    case 'GT':
      return val1 > val2;
    case 'GTE':
      return val1 >= val2;
  }
}
