<template>
  <div class="input-wrapper">
    <div class="p-inputgroup">
      <span v-if="label && !borderless" class="button-label p-inputgroup-addon">{{ label }}</span>
      <component
        :is="inputComponent"
        v-model="inputValue"
        :type="type"
        :name="name"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        :disabled="disabled || loading"
        :class="{ 'p-invalid': errorMessage, borderless, readonly }"
        :aria-describedby="'help-input-' + name"
        :readonly="readonly"
        :min="useNumberComponent ? min : undefined"
        :max="useNumberComponent ? max : undefined"
        :maxlength="charLimit"
        :show-buttons="useNumberComponent ? true : undefined"
        :use-grouping="useNumberComponent ? false : undefined"
        :mode="useNumberComponent ? 'decimal' : undefined"
        @blur="$emit('blur')"
      />
      <i v-if="loading" class="pi pi-spin pi-spinner loader" />
    </div>
    <small :id="'help-input-' + name" class="p-error">{{ errorMessage }}</small>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useField } from 'vee-validate';
import PrimeInputText from 'primevue/inputtext';
import PrimeInputNumber from 'primevue/inputnumber';

type InputValue = any; // TS_TODO: (PrimeInputNumber can only accept number -> v-model error) string | number | null;

const props = withDefaults(
  defineProps<{
    modelValue?: InputValue; // Value of the input
    placeholder?: string; // Text to display as placeholder
    name: string; // Name attribute of the input
    type?: 'text' | 'password' | 'number' | 'email'; // Type attribute of the input
    rules?: string; // Vee-validate rules string (e.g. "required|min:5")
    borderless?: boolean; // Whether to display borderless input
    label?: string; // Label to display before the input
    autocomplete?: string; // Autocomplete attribute of the input
    disabled?: boolean; // Disabled attribute of the input
    loading?: boolean; // Whether the input data is still loading, also makes the input disabled
    readonly?: boolean; // Readonly attribute of the input
    min?: number; // Min number - only usable with useNumberComponent
    max?: number; // Max number - only usable with useNumberComponent
    charLimit?: number; // The maximum number of characters allowed (default to 255)
    useNumberComponent?: boolean; // Whether to use PrimeInputNumber instead of PrimeText
  }>(),
  {
    modelValue: null,
    placeholder: undefined,
    type: 'text',
    rules: undefined,
    borderless: true,
    label: undefined,
    autocomplete: 'off',
    disabled: false,
    loading: false,
    readonly: false,
    min: undefined,
    max: undefined,
    charLimit: 255,
    useNumberComponent: false,
  },
);

const emit = defineEmits<{
  'update:modelValue': [value: InputValue];
  blur: [];
}>();

const { errorMessage, value } = useField(props.name, props.rules, {
  initialValue: props.modelValue,
});

const inputComponent = computed(() => {
  return props.useNumberComponent ? PrimeInputNumber : PrimeInputText;
});

const inputValue = computed({
  get: () => props.modelValue,
  set: (newVal) => {
    value.value = newVal;
    emit('update:modelValue', newVal);
  },
});
</script>

<style scoped>
.input-wrapper {
  width: 100%;
}
input {
  width: 100%;
  resize: none;
}
input.borderless {
  border: none;
}
input[name='name'] {
  font-size: 1.8rem;
  font-weight: 700;
  padding: 0;
}
.p-error {
  font-size: small;
  font-weight: initial;
}
.p-inputtext.p-invalid.p-component {
  box-shadow: 0 0 0 0.2rem #f44336;
}
.p-inputtext:not(.readonly):enabled:hover {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: #0094d2;
}
.button-label {
  background-color: #0094d2;
  color: white;
}
.p-inputgroup {
  position: relative;
}
.loader {
  right: 0.5rem;
  color: #6c757d;
  position: absolute;
  top: 50%;
  margin-top: -0.3rem;
}
</style>
