import { ref, computed } from 'vue';
import useMainApiRequest from '@composables/useMainApiRequest';
// import { registerCacheInvalidation } from '@composables/useCache';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import useToast from '@composables/useToast';
import useFullScenario from '@composables/useFullScenario';
import { MP_MODEL_STA_VIEWER, MP_MODEL_DTA_VIEWER } from '@keys/index';

const cachedModels = ref<TmTrafficModel[]>([]);
// registerCacheInvalidation('models', invalidateModels); // TODO: no need to invalidate cache on user login/logout for now
const fetchModelsPromise = ref<Promise<boolean | void>>(); // re-usable promise in case multiple components want to fetch models at the same time

export default function useModels() {
  const { t } = useI18n();
  const { makeRequest } = useMainApiRequest();
  const store = useStore();
  const { addToast } = useToast();
  const { invalidateFullScenario } = useFullScenario();

  const activeModel = computed(() => {
    const activeModelState: Partial<TmTrafficModel> = store.state.scenarios.model;
    if (!cachedModels.value.length) return activeModelState; // dummy return of default model state if we have no models fetched yet (only before the app is loaded)
    // with no id and only type specified - return default model of that type
    return getModel(activeModelState);
  });

  const computeRequestFrequency = computed(() => {
    const isDTA = store.getters['scenarios/isDtaModelTypeActive'];
    const dtaFrequency = import.meta.env.VITE_COMPUTATION_REQUEST_FREQUENCY_DTA || 2000;
    const staFrequency = import.meta.env.VITE_COMPUTATION_REQUEST_FREQUENCY_STA || 200;
    return isDTA ? Number(dtaFrequency) : Number(staFrequency);
  });

  async function fetchModels() {
    if (isCacheAvailable()) return;
    if (fetchModelsPromise.value) return fetchModelsPromise.value;

    const fetchAction = () =>
      makeRequest({
        url: 'models',
        method: 'get',
        message: {
          error: {
            404: { summary: t('models.no models found'), severity: 'info' },
            default: t('models.error while fetching models'),
          },
        },
        onSuccess: (result: { trafficModels: TmTrafficModel[] }) => {
          cachedModels.value = result.trafficModels;
          store.commit('scenarios/SET_MODEL', activeModel.value);
          // also adjust map view of the active model and the map style
          // (or maybe replace with watch on active model)
          const { center, zoom, linkOffset } = activeModel.value;
          store.commit('map/SET_MAP_STYLE', { newState: linkOffset, group: 'general', property: 'modelLinkOffset' });
          store.dispatch('map/setPosition', { center, zoom, defaultOnly: true });
        },
      });

    // re-use unresolved promise
    return (fetchModelsPromise.value = fetchAction().then(
      () => (fetchModelsPromise.value = undefined), // delete resolved promise
    ));
  }

  async function refreshModel(modelId?: number) {
    if (!modelId) throw new Error('No model ID to refresh');

    await makeRequest({
      url: `models/refresh`,
      method: 'post',
      data: { modelId },
      message: { error: t('models.error while trying to refresh the model') },
      onSuccess: () => {
        _trackActiveModelState();
        // invalidate scenarios to update their 'hasModelSectionsValid' state
        invalidateFullScenario();
      },
    });
  }

  async function switchActiveModel(modelId: number) {
    // invalidateFullScenario();
    const { name, type } = getModel({ id: modelId });
    // store.commit('scenarios/SET_MODEL', newActiveModel);
    // await store.dispatch('map/activateItem', {}); // deactivate all items
    // // adjust map view to the new traffic model (zoom, center)
    // const centerToArray = (z: string) => z?.split(',').map((value) => parseFloat(value));
    // await store.dispatch('map/setPosition', {
    //   center: centerToArray(newActiveModel.center),
    //   zoom: newActiveModel.zoom,
    // });

    const mapMode = type === 'DTA' ? MP_MODEL_DTA_VIEWER : MP_MODEL_STA_VIEWER;
    const baseUrl = import.meta.env.BASE_URL;
    const newUrl = `${window.location.origin}${baseUrl}models?mapmode=${mapMode}&model=${name}`;
    store.dispatch('refreshApp', newUrl); // reload app (TODO: until it is possible to refresh vuemap layers reactively)
  }

  function getModel({ id, name, type }: { id?: number; name?: string; type?: TmModelType }): TmTrafficModel {
    let model;
    if (id) model = cachedModels.value.find((m) => m.id === id);
    else if (name) model = cachedModels.value.find((m) => m.name === name);
    else if (type) model = cachedModels.value.find((m) => m.type === type && m.isDefault);
    if (!model) throw new Error('Traffic model is not available!');
    return model;
  }

  async function _getActiveModelState() {
    const modelId = activeModel.value.id;
    if (!modelId) throw new Error('No active model');

    let isValid = false;
    let isBroken = false;

    await makeRequest({
      url: `models/${modelId}`,
      method: 'get',
      onSuccess: (result: { trafficModel: TmTrafficModel }) => {
        ({ isValid, isBroken } = result.trafficModel);
      },
    });

    return { isValid, isBroken };
  }

  async function _trackActiveModelState() {
    store.dispatch('layout/startLoading');
    store.commit('scenarios/SET_MODEL', { isValid: false });

    let isValid = false;
    let isBroken = false;
    while (!isValid || isBroken) {
      ({ isValid, isBroken } = await _getActiveModelState());
      await new Promise((resolve) => setTimeout(resolve, computeRequestFrequency.value));
    }

    addToast({
      severity: isValid ? 'success' : 'error',
      summary: t('models.computing traffic model'),
      detail: isValid ? t('models.model restored') : t('models.error while refreshing model'),
    });

    store.commit('scenarios/SET_MODEL', { isValid, isBroken });
    store.dispatch('layout/finishLoading');
  }

  return {
    models: computed(() => cachedModels.value),
    // modelTypes: computed(() => [...new Set(cachedModels.value.map((m) => m.type))]),
    activeModel,
    fetchModels,
    refreshModel,
    switchActiveModel,
    invalidateModels,
    getModelCalendarSetting: (isDta = activeModel.value.type === 'DTA') => getModelCalendarSetting(isDta),
    dtaModelOutputOptions,
  };
}

function getModelCalendarSetting(isDta = false) {
  const staSettings = { min: 0, max: 23, step: 1 };
  const dtaSettings = { min: 7, max: 9.75, step: 0.25 };
  return isDta ? dtaSettings : staSettings;
}

function isCacheAvailable() {
  return !!cachedModels.value.length;
}

function invalidateModels() {
  cachedModels.value = [];
}

export const dtaModelOutputOptions = [
  { name: 'outflow', label: 'widgets.outflow', viewerPreset: { width: 'outFlow', color: 'none', text: 'outFlow' } },
  { name: 'inflow', label: 'widgets.inflow', viewerPreset: { width: 'inFlow', color: 'none', text: 'inFlow' } },
  {
    name: 'speed-width',
    label: 'widgets.speed as width',
    viewerPreset: { width: 'travelSpeed', color: 'none', text: 'outFlow' },
  },
  {
    name: 'speed-color',
    label: 'widgets.speed as color',
    viewerPreset: { width: 'none', color: 'travelSpeed', text: 'outFlow' },
  },
  {
    name: 'outflow-speed',
    label: 'widgets.outflow and speed',
    viewerPreset: { width: 'outFlow', color: 'travelSpeed', text: 'outFlow' },
  },
  {
    name: 'outflow-capacity',
    label: 'widgets.outflow and capacity',
    viewerPreset: { width: 'outFlow', color: 'outFlowIntensity', text: 'outFlow' },
  },
  {
    name: 'outflow-inflow',
    label: 'widgets.outflow vs inflow',
    viewerPreset: { width: 'flowDiff', color: 'flowDiff', text: 'flowDiff' },
  },
  {
    name: 'speed-free',
    label: 'widgets.speed vs free speed',
    viewerPreset: { width: 'none', color: 'freeSpeedDiff', text: 'outFlow' },
  },
  {
    name: 'speed-critical',
    label: 'widgets.speed vs critical speed',
    viewerPreset: { width: 'none', color: 'criticalSpeedDiff', text: 'outFlow' },
  },
  {
    name: 'delay-free',
    label: 'widgets.delay free speed',
    viewerPreset: { width: 'none', color: 'delayFreeSpeed', text: 'outFlow' },
  },
  {
    name: 'delay-critical',
    label: 'widgets.delay critical speed',
    viewerPreset: { width: 'none', color: 'delayCriticalSpeed', text: 'outFlow' },
  },
  {
    name: 'outflow-delay',
    label: 'widgets.outflow and delay',
    viewerPreset: { width: 'outFlow', color: 'delayFreeSpeed', text: 'outFlow' },
  },
];

export const dtaComparisonOutputOptions = [
  // source data
  'freeSpeedDiff',
  'capacityDiff',
  // section/context data
  'outFlowDiff',
  'inFlowDiff',
  'travelTimeDiff',
  // derived data
  'travelSpeedDiff',
  'outIntensityDiff',
];

export const dtaDataProperties = [
  // source data
  'length',
  'freeSpeed',
  'criticalSpeed',
  'capacity',
  // section/context data
  'outFlow',
  'inFlow',
  'travelTime',
  // derived data
  'travelSpeed',
  'outFlowIntensity',
  'freeSpeedTime',
  'criticalSpeedTime',
  'flowDiff',
  'freeSpeedDiff',
  'criticalSpeedDiff',
  'delayFreeSpeed',
  'delayCriticalSpeed',
];
