import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import useMainApiRequest from '@composables/useMainApiRequest';
import { get, set, unset } from 'lodash-es';
import { registerCacheInvalidation, isCacheEnabled } from '@composables/useCache';
import type { Ref } from 'vue';

export type NodeId = string | number | null; // TS_TODO: check the necessity of using both string/number types
export type NodeTransit = {
  id?: number;
  node: NodeId;
  value: number;
  isBaseModel: boolean;
  eventId: number | null;
  isExtraNode: boolean;
  linkFrom: TmFeatureId;
  linkTo: TmFeatureId;
  eventName?: string;
  dateTo?: TmDate;
  dateFrom?: TmDate;
  eventIncluded?: boolean;
}[];

export type NeighborLinks = { fromLinks?: TmFeatureId[]; toLinks?: TmFeatureId[] };

const cachedTransitByScenarioByNode = ref<Record<string, Record<string, NodeTransit>>>({});
const isDataUnreliable = ref(true);
registerCacheInvalidation('nodeTransit', _invalidateNodeTransit);

export default function useNodeTransit(
  scenarioId?: number,
  nodeId: Ref<NodeId> = ref(null),
  neighborLinks: Ref<NeighborLinks> = ref({}),
) {
  const store = useStore();
  const { t } = useI18n();
  const { makeRequest } = useMainApiRequest();

  // Wanted to import from useModels, but cycling import kicked in(through node transit invalidation in full scenario)
  const isDta = computed<boolean>(() => store.getters['scenarios/isDtaModelTypeActive']);
  const isExtraNode = computed<boolean>(() => typeof nodeId.value === 'string');
  const restrictedTurns = computed(
    () => get(cachedTransitByScenarioByNode.value, `${scenarioId}.${nodeId.value}`, []) as NodeTransit,
  );
  const unrestrictedTurnsValue = computed(() => (isDta.value ? -1 : 0));
  const unrestrictedTurns = computed(() =>
    _getUnrestrictedTurns(
      neighborLinks.value,
      nodeId.value,
      restrictedTurns.value,
      isExtraNode.value,
      unrestrictedTurnsValue.value,
    ),
  );
  const nodeTransit = computed<NodeTransit>(() => [...restrictedTurns.value, ...unrestrictedTurns.value]);

  const shouldLoadWithChanges = (id: number): boolean => store.getters['scenarios/isUsingChangedScenario'](id);
  const isCached = (nId: NodeId) => get(cachedTransitByScenarioByNode.value, `${scenarioId}.${nId}`, false);
  const isCacheAvailable = (nId: NodeId) => isCacheEnabled(store, 'nodeTransit') && isCached(nId);

  watch(
    nodeId,
    (nodeId) => {
      if (!nodeId) return;
      fetchTurnRestrictionsByNode(nodeId);
    },
    { immediate: true },
  );

  async function fetchTurnRestrictionsByNode(nodeId: NodeId) {
    if (isCacheAvailable(nodeId)) return;
    if (!scenarioId) throw new Error('No scenario ID provided for fetching turn restrictions.');

    await makeRequest({
      url: `scenarios/${scenarioId}/node-transit/${nodeId}`,
      method: 'get',
      params: {
        ...(shouldLoadWithChanges(scenarioId) && { useChanged: true }),
        ...(isExtraNode.value && { isExtraNode: true }),
      },
      message: {
        error: {
          default: t('modifications.error while fetching trs'),
        },
      },
      onSuccess: (result: NodeTransit) => {
        isDataUnreliable.value = false;
        set(cachedTransitByScenarioByNode.value, `${scenarioId}.${nodeId}`, result);
      },
      onFailure: () => {
        isDataUnreliable.value = true;
        _invalidateNodeTransit(scenarioId, nodeId);
      },
    });
  }

  return {
    isDataUnreliable,
    fetchTurnRestrictionsByNode,
    invalidateNodeTransit: (scId = scenarioId, nId = nodeId.value) => _invalidateNodeTransit(scId, nId),
    nodeTransit,
  };
}

function _getUnrestrictedTurns(
  linksByDirection: NeighborLinks,
  nodeId: NodeId,
  restrictedTurns: NodeTransit,
  isExtraNode: boolean,
  turnValue = 0,
): NodeTransit {
  const restrictedBaseModelTurns = restrictedTurns.filter((rt) => rt.isBaseModel);
  const restrictedIds = new Set(restrictedBaseModelTurns.map((rt) => `${rt.linkFrom}-${rt.linkTo}`));
  const { fromLinks = [], toLinks = [] } = linksByDirection;
  // TODO: should extra node be labeled as base model? :-O
  const unrestrictedTurnDefaults = {
    node: nodeId,
    value: turnValue,
    isBaseModel: true,
    eventId: null,
    isExtraNode,
  };

  return fromLinks.flatMap((linkFrom) =>
    toLinks.flatMap((linkTo) =>
      restrictedIds.has(`${linkFrom}-${linkTo}`) ? [] : { linkFrom, linkTo, ...unrestrictedTurnDefaults },
    ),
  );
}

function _invalidateNodeTransit(scenarioId?: number, nodeId?: NodeId) {
  if (scenarioId) {
    if (nodeId) unset(cachedTransitByScenarioByNode.value, `${scenarioId}.${nodeId}`);
    else unset(cachedTransitByScenarioByNode.value, `${scenarioId}`);
  } else cachedTransitByScenarioByNode.value = {};
}
