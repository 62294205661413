import isMobile from 'is-mobile';
import type { App, InjectionKey } from 'vue';

export const pluginKey: InjectionKey<typeof isMobile> = Symbol('is-mobile');
export const pluginGlobal = '$isMobile';

export default {
  install: (app: App) => {
    // provide plugin as global varible for template
    app.config.globalProperties[pluginGlobal] = isMobile;

    // provide plugin for setup()
    app.provide(pluginKey, isMobile);
  },
};
