<template>
  <div class="p-card p-component p-d-flex p-flex-column p-p-3">
    <div class="header">
      <slot name="header"></slot>
    </div>
    <div class="content" :class="{ 'min-height': hasMinHeight }">
      <slot name="content"></slot>
    </div>
    <div v-if="$slots.footer" class="footer p-pt-3"><slot name="footer"></slot></div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { VNode } from 'vue';

const slots = defineSlots<{
  header?: (props: {}) => VNode[];
  content?: (props: {}) => VNode[];
  footer?: (props: {}) => VNode[];
}>();

const hasMinHeight = computed(() => !!slots['content']);
</script>

<style scoped>
.p-card {
  max-width: 100%;
  max-height: 100%;
  overflow-y: auto; /* card itself should only be scrollable if header and footer themselves are too big to fit into full height card */
}
.content {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.content.min-height {
  min-height: 4rem; /* ensure that at least some space is always displayed between header and footer -> causes parent card to be scrollable */
}
:deep(.p-panel:last-child) {
  margin-bottom: 0 !important;
}
:deep(.p-panel:last-child) {
  margin-bottom: 0 !important;
}
/* styles for user-edited text formatted by quill editor  */
.editable-text {
  img,
  iframe,
  :deep(iframe),
  :deep(img) {
    display: block;
    max-width: 80%;
    margin: 2rem auto;
  }
  :deep(.ql-size-large) {
    font-size: 1.5rem;
    font-weight: 700;
  }
  :deep(.ql-size-huge) {
    font-size: 1.8rem;
    font-weight: 700;
  }
  :deep(.ql-indent-1) {
    margin-left: 1rem;
  }
  :deep(.ql-indent-2) {
    margin-left: 2rem;
  }
  :deep(.ql-indent-3) {
    margin-left: 3rem;
  }
}
</style>
