import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/vee-validate';
import i18n from './plugins/i18n';
import tmDate from './plugins/tm-date';
import registerPrimeVue from './plugins/prime-vue';
import registerGlobalComponents from './plugins/global-components';
import tmAbility from './plugins/tm-ability';
import registerVuemap from './plugins/vuemap';
import cookieConsent from './plugins/cookie-consent';
import { createMetaManager } from 'vue-meta';
import tmMobile from './plugins/tm-mobile';
import Vue3TouchEvents from 'vue3-touch-events';

const app = createApp(App);
app.use(router);
app.use(store);
app.use(i18n);
app.use(tmDate);
app.use(tmAbility);
app.use(createMetaManager());
app.use(tmMobile);
app.use(cookieConsent);
app.use(Vue3TouchEvents);
registerPrimeVue(app);
registerGlobalComponents(app);
registerVuemap(app);
// TODO most of these are not great for hash based caching
// doesnt matter much now anyway - https://github.com/vitejs/vite/issues/6773
// eslint-disable-next-line no-undef
app.provide('buildTime', __BUILD_TIME__); // global variable defined during vite config
// eslint-disable-next-line no-undef
app.provide('appVersion', __APP_VERSION__); // global variable defined during vite config
// eslint-disable-next-line no-undef
app.provide('branchName', __BRANCH_NAME__); // global variable defined during vite config
// eslint-disable-next-line no-undef
app.provide('commitMessage', __COMMIT_MESSAGE__); // global variable defined during vite config
app.mount('#app');
