export const formatRound = (value) => Math.round(value).toString();
export const formatFixed = (value) => value.toFixed(2).toString();
const MS_IN_HOUR = 1000 * 60 * 60;
export const formatHour = (hourPart) => {
  const isNegative = hourPart < 0;
  const positiveValue = Math.abs(hourPart);
  const date = new Date(MS_IN_HOUR * positiveValue);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getSeconds();
  return `${isNegative ? '- ' : ''}${hours ? hours + ':' : ''} ${minutes ? minutes : ''}:${seconds}`; // Debug with [${hourPart.toFixed(5)}]
};
