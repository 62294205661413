<template>
  <div class="content">
    <i class="p-mr-3 pi pi-exclamation-triangle" />
    {{ $t(message) }}
  </div>

  <div class="actions p-grid p-mx-0">
    <div v-if="dialog.callback?.onCompute" class="p-col">
      <tm-button :label="$t('dialogs.compute and leave')" type="confirm" @click="onCompute" />
    </div>
    <div class="p-col">
      <tm-button :label="$t('dialogs.save and leave')" type="confirm" @click="onSave" />
    </div>
    <div class="p-col">
      <tm-button :label="$t('dialogs.leave without saving')" type="confirm" @click="onLeave" />
    </div>
    <div class="p-col">
      <tm-button :label="$t('dialogs.cancel')" type="cancel" @click="onCancel" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import useDialog from '@composables/useDialog';

const { close: closeDialog, dialog } = useDialog();
const message = computed(() => {
  const modelType = dialog.value.data?.modelType;
  if (!modelType) return 'dialogs.confirm leave scenario';
  return `dialogs.confirm leave ${modelType} form`;
});

const onCompute = (ev: Event) => dialog.value.callback?.onCompute?.(ev);
const onSave = (ev: Event) => dialog.value.callback?.onSave?.(ev);
const onLeave = (ev: Event) => dialog.value.callback?.onLeave?.(ev);
const onCancel = (ev: Event) => {
  if (dialog.value.callback?.onCancel) dialog.value.callback.onCancel(ev);
  else closeDialog();
};
</script>

<style scoped>
.actions :deep(.tm-button) {
  height: 100%;
}
.actions :deep(.p-button) {
  height: 100%;
}
</style>
