import { ref, computed, watch, onBeforeMount, effectScope } from 'vue';
import { useStore } from 'vuex';
import { get, set, unset } from 'lodash-es';
import { registerCacheInvalidation, isCacheEnabled } from '@composables/useCache';
import useScenarios from '@composables/useScenarios';
import useEvents from '@composables/useEvents';
import useModifications from '@composables/useModifications';
import useNodeTransit from '@composables/useNodeTransit';
import { invalidateModelTrafficInterval } from '@composables/useModelTrafficInterval';
import { eagerComputed, useCurrentRouteParams } from '@composables/useCommonHelpers';
import { getDateRangeIntersectionEvaluator } from '@utils/tm-date';

const scenariosKey = 'scenarios';
const eventsKey = 'events';
const modificationsKey = 'modifications';
const scOverviewKey = 'scenariosOverview';

type CacheKey = typeof scenariosKey | typeof eventsKey | typeof modificationsKey | typeof scOverviewKey;

const resourceCache = ref({
  [scenariosKey]: <Record<number, TmScenario>>{}, // scenarios stored by 'scenarioId'
  [eventsKey]: <Record<number, Record<number, TmEvent>>>{}, // events stored by 'scenarioId.eventId'
  [modificationsKey]: <Record<number, Record<number, Record<number, TmModification>>>>{}, // modifications stored by 'scenarioId.eventId.modificationId'
  [scOverviewKey]: <Record<number, TmScenario>>{}, // shallow scenarios containing only general props stored by 'scenarioId'
});

const fetchPromises = ref<Record<string, Promise<boolean | void>>>({}); // re-usable promises in case multiple components want to fetch the same resource at the same time
const scenarioWatcherStarted = ref(false); // global scenario watcher state

export default function useFullScenario(itemIds: TmItemIds = {}) {
  const store = useStore();
  const { invalidateNodeTransit } = useNodeTransit(itemIds.scenarioId);
  const { getScenarioId, getEventId, getModificationId } = useCurrentRouteParams();

  const activeScenarioId = eagerComputed(() => {
    const viewScenarioId = getScenarioId();
    const expandedScenarioId: number | null = store.getters['map/getActiveItemId']({ itemLevel: 'scenario' });
    return viewScenarioId || expandedScenarioId || undefined;
  });

  const activeEventId = eagerComputed(() => {
    const viewEventId = getEventId();
    const expandedEventId: number | null = store.getters['map/getActiveItemId']({ itemLevel: 'event' });
    return viewEventId || expandedEventId || undefined;
  });

  const activeModificationId = eagerComputed(() => {
    const viewModificationId = getModificationId();
    const expandedModificationId: number | null = store.getters['map/getActiveItemId']({ itemLevel: 'modification' });
    return viewModificationId || expandedModificationId || undefined;
  });

  const activeScenario = computed<TmScenario>(() => {
    const scId = activeScenarioId.value;
    // empty object indicates that no scenario is activated
    if (!scId) return {};
    // if the active scenario is expected but it is not yet cached (fetched) - return non empty object to indicate it is pending
    if (!isResourceCached(scenariosKey, { scenarioId: scId })) return { isPending: true };
    const activeScenario = getScenario(scId);
    const activeEvents = getEvents(scId);
    let activeModifications: TmModification[] = [];
    activeEvents.forEach((ev) => {
      const mods = getModifications(scId, ev.id);
      ev.modifications = mods;
      activeModifications = [...activeModifications, ...mods];
    });
    return {
      ...activeScenario,
      events: activeEvents,
      modifications: activeModifications,
    };
  });

  const activeEvent = computed<TmEvent>(() => {
    const scId = activeScenarioId.value;
    const evId = activeEventId.value;
    if (!scId || !evId) return {};
    if (!isResourceCached(eventsKey, { scenarioId: scId, eventId: evId })) return { isPending: true };
    return { ...getEvent(scId, evId), modifications: getModifications(scId, evId) };
  });

  const activeModification = computed<TmModification>(() => {
    const scId = activeScenarioId.value;
    const evId = activeEventId.value;
    const modId = activeModificationId.value;
    if (!scId || !evId || !modId) return {};
    if (!isResourceCached(modificationsKey, { scenarioId: scId, eventId: evId, modificationId: modId }))
      return { isPending: true };
    return getModification(scId, evId, modId);
  });

  const fetchResource = ({
    cacheKey,
    fetchAction,
    scenarioId,
    eventId,
    modificationId,
    forced = false,
  }: {
    cacheKey: CacheKey;
    fetchAction: (resourceId?: number) => Promise<void>;
    scenarioId?: number;
    eventId?: number;
    modificationId?: number;
    forced: boolean;
  }) => {
    if (!forced && isCacheAvailable(cacheKey, { scenarioId, eventId, modificationId })) return Promise.resolve(); // resource is already fetched

    const promiseKey = `${cacheKey}_${fetchAction.name}_${scenarioId}_${eventId}_${modificationId}`;
    if (!fetchPromises.value?.[promiseKey])
      fetchPromises.value[promiseKey] = fetchAction(modificationId || eventId || scenarioId).then(
        () => delete fetchPromises.value[promiseKey], // delete resolved promise
      );
    // re-use unresolved promises
    return fetchPromises.value[promiseKey];
  };

  const cacheResource =
    (cacheKey: CacheKey) =>
    (resource: any, { scenarioId, eventId, modificationId }: TmItemIds = {}) => {
      // cache child resources separately if they are present
      if (resource.events) {
        cacheResource(eventsKey)(resource.events, { scenarioId });
        delete resource.events;
      }
      if (resource.modifications) {
        cacheResource(modificationsKey)(resource.modifications, { scenarioId, eventId });
        delete resource.modifications;
      }
      const path = getCachePath(cacheKey, { scenarioId, eventId, modificationId });
      if (Array.isArray(resource)) {
        // cache every resource in the list by its ID
        resource.forEach((res) => {
          cacheResource(cacheKey)(res, {
            scenarioId: [scenariosKey, scOverviewKey].includes(cacheKey) ? res.id : scenarioId,
            eventId: cacheKey === eventsKey ? res.id : eventId,
            modificationId: cacheKey === modificationsKey ? res.id : modificationId,
          });
        });
      } else {
        // Store eventId in mods, so it can be found when served directly from scenario without event
        // ScenarioId in most (all?) cases accessible as activeScenarioId
        // TODO possibly move to server, when server caches responses for non-edit mode scenarios
        if (cacheKey === modificationsKey && eventId) resource.eventId = eventId;
        // cache single resource
        set(resourceCache.value, path, resource);
      }
    };

  const invalidateResource =
    (cacheKey: CacheKey) =>
    ({ scenarioId, eventId, modificationId }: TmItemIds = {}) => {
      if (!scenarioId && !eventId && !modificationId) {
        set(resourceCache.value, cacheKey, {});
      } else {
        const path = getCachePath(cacheKey, { scenarioId, eventId, modificationId });
        unset(resourceCache.value, path);
      }
    };

  const updateResource =
    (cacheKey: CacheKey) =>
    (key: string, value: any, { scenarioId, eventId, modificationId }: TmItemIds = {}) => {
      const path = getCachePath(cacheKey, { scenarioId, eventId, modificationId });
      set(resourceCache.value, `${path}.${key}`, value);
    };

  const getResource =
    (cacheKey: CacheKey, { asArray = false } = {}) =>
    ({ scenarioId, eventId, modificationId }: TmItemIds = {}) => {
      const path = getCachePath(cacheKey, { scenarioId, eventId, modificationId });
      const resource = get(resourceCache.value, path, {});
      return asArray ? getObjectValues(resource) : resource;
    };

  const invalidateFullScenario = (
    scenarioId?: number,
    { invalidateOverview = true, invalidateTransit = true, invalidateCalcs = true } = {},
  ) => {
    invalidateResource(scenariosKey)({ scenarioId });
    invalidateResource(eventsKey)({ scenarioId });
    invalidateResource(modificationsKey)({ scenarioId });
    if (invalidateOverview) invalidateResource(scOverviewKey)();
    if (invalidateTransit) invalidateNodeTransit(scenarioId);
    if (invalidateCalcs) invalidateModelTrafficInterval(scenarioId);
  };

  const getCachePath = (cacheKey: CacheKey, { scenarioId, eventId, modificationId }: TmItemIds = {}) => {
    return (
      cacheKey +
      (scenarioId ? `.${scenarioId}` : '') +
      (eventId ? `.${eventId}` : '') +
      (modificationId ? `.${modificationId}` : '')
    );
  };

  const getObjectValues = (obj: any): {}[] => (obj.id ? [obj] : Object.values(obj).map(getObjectValues).flat());

  const isResourceCached = (cacheKey: CacheKey, { scenarioId, eventId, modificationId }: TmItemIds = {}) => {
    const path = getCachePath(cacheKey, { scenarioId, eventId, modificationId });
    const resource = get(resourceCache.value, path, {});
    return Object.keys(resource).length > 0;
  };

  const isCacheAvailable = (cacheKey: CacheKey, { scenarioId, eventId, modificationId }: TmItemIds = {}) =>
    isCacheEnabled(store, cacheKey) && isResourceCached(cacheKey, { scenarioId, eventId, modificationId });

  const getScenariosOverview = () => getResource(scOverviewKey, { asArray: true })() as TmScenario[];
  const getScenario = (scenarioId?: number) => getResource(scenariosKey)({ scenarioId }) as TmScenario;
  const getEvent = (scenarioId?: number, eventId?: number) =>
    getResource(eventsKey)({ scenarioId, eventId }) as TmEvent;
  const getModification = (scenarioId?: number, eventId?: number, modificationId?: number) =>
    getResource(modificationsKey)({ scenarioId, eventId, modificationId }) as TmModification;
  const getScenarios = () => getResource(scenariosKey, { asArray: true })() as TmScenario[];
  const getEvents = (scenarioId?: number) => getResource(eventsKey, { asArray: true })({ scenarioId }) as TmEvent[];
  const getModifications = (scenarioId?: number, eventId?: number, modificationId?: number) =>
    getResource(modificationsKey, { asArray: true })({ scenarioId, eventId, modificationId }) as TmModification[];
  const getActiveEventsOnDate = (activeDate: TmCalendarDate): TmEvent[] => {
    if (!activeScenario.value.events) return [];
    if (!activeDate) return activeScenario.value.events;
    const isActiveDateInsideDateRange = getDateRangeIntersectionEvaluator(activeDate);
    const events = activeScenario.value.events.filter((ev) => {
      const { dateFrom, dateTo, included } = ev as { dateFrom: TmDate; dateTo: TmDate; included: boolean };
      return included && isActiveDateInsideDateRange({ dateFrom, dateTo });
    });

    return events;
  };

  const scenariosActions = useScenarios({
    cacheOverview: cacheResource(scOverviewKey),
    invalidateOverview: invalidateResource(scOverviewKey),
    cacheScenario: cacheResource(scenariosKey),
    invalidateScenario: invalidateResource(scenariosKey),
  });

  const eventsActions = useEvents(itemIds, {
    cacheEvent: cacheResource(eventsKey),
    invalidateEvent: invalidateResource(eventsKey),
    updateCachedEvent: updateResource(eventsKey),
    invalidateModification: invalidateResource(modificationsKey),
    invalidateNodeTransit,
  });

  const modificationsActions = useModifications(itemIds, {
    cacheModification: cacheResource(modificationsKey),
    invalidateModification: invalidateResource(modificationsKey),
    invalidateNodeTransit,
  });

  const fetchScenario = (id?: number, { forced = false } = {}) => {
    return fetchResource({
      cacheKey: scenariosKey,
      fetchAction: scenariosActions.fetchScenario,
      scenarioId: id,
      forced,
    });
  };

  const startScenarioWatcher = () => {
    // trigger initial fetch if already pending
    if (activeScenario.value.isPending) fetchScenario(activeScenarioId.value);
    // if watcher already started do not start another watcher
    if (scenarioWatcherStarted.value) return;
    scenarioWatcherStarted.value = true;
    // use effectScope for the watcher so it is not deactivated on any component unmounts
    const scope = effectScope();
    scope.run(() => {
      watch(activeScenario, ({ isPending = false, id = null, hasModelSectionsValid = false }, { id: oldId }) => {
        const shouldBeRefetched = !!id && id !== oldId && hasModelSectionsValid === false;
        if (!isPending && !shouldBeRefetched) return; // only fetch active scenario if it is pending or has invalid model sections
        fetchScenario(activeScenarioId.value, { forced: shouldBeRefetched });
      });
    });
  };

  onBeforeMount(startScenarioWatcher);

  return {
    ...scenariosActions,
    ...eventsActions,
    ...modificationsActions,
    activeScenarioId,
    activeEventId,
    activeModificationId,
    activeScenario,
    activeEvent,
    activeModification,
    scenario: computed(() => getScenario(itemIds.scenarioId)),
    scenarios: computed(() => getScenarios()),
    scenariosOverview: computed(() => getScenariosOverview()),
    event: computed(() => getEvent(itemIds.scenarioId, itemIds.eventId)),
    events: computed(() => getEvents(itemIds.scenarioId)),
    modification: computed(() => getModification(itemIds.scenarioId, itemIds.eventId, itemIds.modificationId)),
    modifications: computed(() => getModifications(itemIds.scenarioId, itemIds.eventId)),
    fetchScenario: (id?: number, { forced = false } = {}) => fetchScenario(id || itemIds.scenarioId, { forced }),
    fetchScenariosOverview: ({ forced = false } = {}) =>
      fetchResource({ cacheKey: scOverviewKey, fetchAction: scenariosActions.fetchScenarios, forced }),
    invalidateFullScenario,
    getScenario,
    getEvent,
    getEvents,
    getModifications,
    getModification,
    getActiveEventsOnDate,
  };
}

registerCacheInvalidation(scOverviewKey, () => set(resourceCache.value, scOverviewKey, {}));
registerCacheInvalidation(scenariosKey, () => set(resourceCache.value, scenariosKey, {}));
registerCacheInvalidation(eventsKey, () => set(resourceCache.value, eventsKey, {}));
registerCacheInvalidation(modificationsKey, () => set(resourceCache.value, modificationsKey, {}));
