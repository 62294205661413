import type { usePrimeVue } from 'primevue/config';

type PrimeLocales = Record<TmLang, ReturnType<typeof usePrimeVue>['config']['locale']>;

const primeVueLocales: PrimeLocales = {
  cs: {
    startsWith: 'Začíná na',
    contains: 'Obsahuje',
    notContains: 'Neobsahuje',
    // endsWith: 'Končí na',
    equals: 'Se rovná',
    notEquals: 'Se nerovná',
    noFilter: 'Bez filtru',
    lt: 'Menší než',
    lte: 'Menší nebo stejný jako',
    gt: 'Větší než',
    gte: 'Větší nebo stejný jako',
    dateIs: 'Datum je',
    dateIsNot: 'Datum není',
    dateBefore: 'Datum je před',
    dateAfter: 'Datum je po',
    clear: 'Smazat',
    apply: 'Aplikovat',
    matchAll: 'Vyhovuje vše',
    matchAny: 'Vyhovuje jeden',
    addRule: 'Přidat pravidlo',
    removeRule: 'Odstranit pravidlo',
    accept: 'Ano',
    reject: 'Ne',
    choose: 'Vybrat',
    upload: 'Nahrát',
    cancel: 'Zrušit',
    dayNames: ['Neděle', 'Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota'],
    dayNamesShort: ['Ned', 'Pon', 'Úte', 'Stř', 'Čtv', 'Pát', 'Sob'],
    dayNamesMin: ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So'],
    monthNames: [
      'Leden',
      'Únor',
      'Březen',
      'Duben',
      'Květen',
      'Červen',
      'Červenec',
      'Srpen',
      'Září',
      'Říjen',
      'Listopad',
      'Prosinec',
    ],
    monthNamesShort: ['Led', 'Úno', 'Bře', 'Dub', 'Kvě', 'Čvn', 'Čvc', 'Srp', 'Zář', 'Říj', 'Lis', 'Pro'],
    today: 'Dnes',
    weekHeader: 'Týd',
    firstDayOfWeek: 1,
    dateFormat: 'dd. mm. yy',
    weak: 'Týden',
    medium: 'Střední',
    strong: 'Tučný',
    passwordPrompt: 'Zadejte heslo',
    emptyFilterMessage: 'Filtru nevyhovují žádné záznamy',
    emptyMessage: 'Nenalezeny žádné záznamy',
    aria: { navigation: 'Navigace ' },
  },
  en: {
    startsWith: 'Starts with',
    contains: 'Contains',
    notContains: 'Not contains',
    // endsWith: 'Ends with',
    equals: 'Equals',
    notEquals: 'Not equals',
    noFilter: 'No Filter',
    lt: 'Less than',
    lte: 'Less than or equal to',
    gt: 'Greater than',
    gte: 'Greater than or equal to',
    dateIs: 'Date is',
    dateIsNot: 'Date is not',
    dateBefore: 'Date is before',
    dateAfter: 'Date is after',
    clear: 'Clear',
    apply: 'Apply',
    matchAll: 'Match All',
    matchAny: 'Match Any',
    addRule: 'Add Rule',
    removeRule: 'Remove Rule',
    accept: 'Yes',
    reject: 'No',
    choose: 'Choose',
    upload: 'Upload',
    cancel: 'Cancel',
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    monthNames: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    today: 'Today',
    weekHeader: 'Wk',
    firstDayOfWeek: 0,
    dateFormat: 'mm/dd/yy',
    weak: 'Weak',
    medium: 'Medium',
    strong: 'Strong',
    passwordPrompt: 'Enter a password',
    emptyFilterMessage: 'No results found',
    emptyMessage: 'No available options',
    aria: { navigation: 'Navigation ' },
  },
};

export default primeVueLocales;
