import NotFoundView from '@views/errors/NotFound.vue';
import ErrorLayout from '@layouts/Error.vue';
import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/:catchAll(.*)',
    name: 'errors.not_found',
    component: NotFoundView,
    meta: {
      layout: ErrorLayout,
    },
  },
];

export default routes;
