<template>
  <transition-group v-if="ready" name="panellist">
    <tm-panel-list-item
      v-for="item in items"
      :key="getUid(item)"
      v-model:selected="selectedItems"
      :type="type"
      :item="item"
      :access="access"
      :access-event-object="accessEventObject"
      :watch-mode="watchMode"
      :data-test="type"
      :data-test-id="getUid(item)"
      :is-active="isItemActive(item)"
      @checked="$emit('item:checked', { item, checked: $event })"
      @clicked="switchActiveItem($event)"
      @action:clicked="$emit('item:action:clicked', { item, action: $event })"
    />
  </transition-group>
  <div v-else>
    <prime-skeleton height="3rem" class="p-mb-3" />
    <prime-skeleton height="3rem" class="p-mb-3" />
    <prime-skeleton height="3rem" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import TmPanelListItem from '@components/views/PanelListItem.vue';
import type { ItemType, ListItem, ItemAction } from '@components/views/PanelListItem.vue';

const props = withDefaults(
  defineProps<{
    type?: ItemType; // Type representing the view to witch the list belongs
    items: ListItem[]; // Array of items to display in the list
    activatedItemId?: string | number; // Default active item Id
    access?: TmAccessLevel; // User access to this list of items
    accessEventObject?: TmEvent; // Event object - only needed for type 'modifications' and used for authorization
    watchMode?: boolean; // Whether to display the item list only for watching (and disallow editing)
    ready?: boolean; // Whether the content is ready to be displayed (display skeleton if false)
    selected?: ListItem[]; // List of selected items
    uniqueIdField?: string; // Specifies which item property can be used as unique id
  }>(),
  {
    type: 'basic',
    activatedItemId: undefined,
    access: undefined,
    accessEventObject: undefined,
    watchMode: true,
    ready: true,
    selected: () => [],
    uniqueIdField: 'id',
  },
);

const emit = defineEmits<{
  'item:activated': [item: ListItem]; // Signals that specific list item was activated
  'item:deactivated': [item: ListItem]; // Signals that specific list item was deactivated
  'item:checked': [enhancedItem: { item: ListItem; checked: boolean }]; // Signals that specific list item was checked
  'item:action:clicked': [enhancedItem: { item: ListItem; action: ItemAction }]; // Signals that an item action was checked on specific list item
  'item:clicked': [item: ListItem]; // Signals that an item has been clicked on
  'update:selected': [items: ListItem[]]; // Updates original selected model
}>();

const switchActiveItem = (item: ListItem) => {
  emit('item:clicked', item);
  if (!isItemActive(item)) emit('item:activated', item);
  else emit('item:deactivated', item);
};

const selectedItems = computed({
  get: () => props.selected || [],
  set: (selected) => emit('update:selected', selected),
});

const getUid = (i: ListItem): string | number => i[props.uniqueIdField];
const isItemActive = (i: ListItem): boolean => getUid(i) == props.activatedItemId;
</script>

<style scoped>
.panellist-enter-active,
.panellist-leave-active {
  transition: all 0.1s ease;
}
.panellist-enter-from,
.panellist-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
:deep(.p-panel-header) {
  cursor: pointer;
  position: relative;
}
</style>
