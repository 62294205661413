<template>
  <div class="content">
    <tm-user-settings :user-id="userId" :is-admin-edit="true" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import useDialog from '@composables/useDialog';
import TmUserSettings from '@components/views/UserSettings.vue';

const { dialog } = useDialog();

const userId = computed<number>(() => dialog.value.data?.userId);
</script>
