<template>
  <prime-badge v-if="unresolvedScenariosCount" :value="unresolvedScenariosCount" />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';
import PrimeBadge from 'primevue/badge';

const store = useStore();
const unresolvedScenariosCount = computed(() => store.getters['scenarios/getUnresolvedScenariosCount']);
</script>
