import useMainApiRequest from '@composables/useMainApiRequest';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { invalidateCache } from '@composables/useCache';

export default function useAuth() {
  const { t } = useI18n();
  const { makeRequest } = useMainApiRequest();
  const router = useRouter();
  const store = useStore();

  async function login({ username, password }: { username: string; password: string }) {
    await makeRequest({
      url: 'auth/login',
      method: 'post',
      data: { username, password },
      message: {
        success: { summary: t('users.login successful'), severity: 'info' },
        error: t('users.bad credentials'),
      },
      onSuccess: async (response) => {
        store.dispatch('auth/login', await response);

        const currentRouteName = router.currentRoute.value.name as string;
        const nextRouteName = currentRouteName.startsWith('admin.') ? 'admin.users' : 'user';
        router.push({ name: nextRouteName });
      },
    });
  }

  async function logout() {
    await makeRequest({
      url: 'auth/logout',
      method: 'post',
      message: {
        success: { summary: t('users.logout successful'), severity: 'info' },
        error: t('users.failed to log user out'),
      },
      onSuccess: async () => {
        store.dispatch('auth/logout');

        const currentRouteName = router.currentRoute.value.name as string;
        const nextRouteName = currentRouteName.startsWith('admin.') ? 'admin.login' : 'user.login';
        router.push({ name: nextRouteName });
      },
    });
  }

  async function verify() {
    await makeRequest({
      url: 'auth/user',
      method: 'get',
      onSuccess: async (response) => {
        // re-setting auth data is dangerous since it triggers invalidation of everything via useCache
        // store.commit('auth/SET_AUTH', await response);
        const loggedUserId: number = store.state.auth.user.id;
        if (response.id !== loggedUserId) store.commit('auth/PURGE_AUTH'); // handle user mismatch
      },
      onFailure: () => store.commit('auth/PURGE_AUTH'),
    });
  }

  function startAuthWatcher() {
    store.subscribe((mutation) => {
      if (['auth/SET_AUTH', 'auth/PURGE_AUTH'].includes(mutation.type)) {
        invalidateCache();
      }
    });
  }

  return {
    login,
    logout,
    verify,
    startAuthWatcher,
    isUserLoggedAs: (role: TmRole) => store.getters['auth/hasRole'](role),
  };
}
