<template>
  <div ref="splitRef" class="p-splitbutton p-component">
    <prime-button
      type="button"
      data-test="split-button-default"
      :label="label"
      :disabled="disabled"
      class="p-splitbutton-defaultbutton"
      @click="$emit('default:clicked')"
    />
    <prime-button
      type="button"
      data-test="split-button-menu"
      class="p-splitbutton-menubutton"
      icon="pi pi-chevron-down"
      aria-haspopup="true"
      aria-controls="menu-overlay"
      @click="toggle"
    />
    <prime-menu
      id="menu-overlay"
      ref="menuRef"
      :model="options"
      :popup="true"
      append-to="body"
      :auto-z-index="true"
      :base-z-index="0"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import PrimeButton from 'primevue/button';
import PrimeMenu from 'primevue/menu';
import type { MenuItem } from 'primevue/menuitem';

withDefaults(
  defineProps<{
    label?: string; // label text of the default button
    disabled?: boolean; // disabled state of the default button
    options: MenuItem[]; // options list to display as popup
  }>(),
  {
    label: undefined,
    disabled: false,
  },
);

defineEmits<{
  'default:clicked': []; // indicates that the default button has been clicked on
}>();

const menuRef = ref<PrimeMenu>();
const splitRef = ref<HTMLElement>();

// @ts-ignore
const toggle = () => menuRef.value?.toggle({ currentTarget: splitRef.value, relativeAlign: false });
</script>

<style scoped>
.p-splitbutton {
  display: inline-flex;
  position: relative;
  width: 100%;
}
.p-splitbutton .p-splitbutton-defaultbutton {
  flex: 1 1 auto;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0 none;
}
.p-splitbutton-menubutton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.p-menu {
  padding: 0;
  background-color: #0094d2;
}
.p-menu :deep(.p-menuitem-text) {
  color: white;
}
.p-menu :deep(.p-menuitem-link:hover) {
  background-color: #007aad;
}
.p-menu :deep(.p-menuitem-link:not(.p-disabled):hover .p-menuitem-text) {
  color: white;
}
.p-menu :deep(.p-menuitem-link.p-disabled) {
  background-color: #66bfe4;
  opacity: 1;
}
.p-menu :deep(.p-menuitem:first-child .p-menuitem-link:not(.p-disabled)) {
  border-top: 0.25rem solid #0094d2;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.p-menu :deep(.p-menuitem:first-child .p-menuitem-link.p-disabled) {
  border-top: 0.25rem solid #66bfe4;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.p-menu :deep(.p-menuitem:last-child .p-menuitem-link:not(.p-disabled)) {
  border-bottom: 0.25rem solid #0094d2;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.p-menu :deep(.p-menuitem:last-child .p-menuitem-link.p-disabled) {
  border-bottom: 0.25rem solid #66bfe4;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.p-menu :deep(.p-menuitem:first-child .p-menuitem-link:hover) {
  border-top: 0.25rem solid #007aad;
}
.p-menu :deep(.p-menuitem:last-child .p-menuitem-link:hover) {
  border-bottom: 0.25rem solid #007aad;
}
</style>
