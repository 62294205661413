import { get, set } from 'lodash-es';
import { ref, readonly } from 'vue';
import { useModelTrafficData } from '@composables/useTrafficData';
import { MP_MODEL_DTA_VIEWER } from '@keys/index';

export default function useDTAIntervalExtract(intervalCache) {
  const { fetchTrafficData, getTrafficDataByKey, areTrafficDataAvailable } = useModelTrafficData('DTA');
  const isLoading = ref(false);

  const getLinksIntervalCtx = async ({ date, modelKey, scenarioId, extraFeaturesCodeList = {} }) => {
    const cacheKey = modelKey || MP_MODEL_DTA_VIEWER;
    isLoading.value = cacheKey;
    if (!areTrafficDataAvailable(cacheKey))
      await fetchTrafficData({
        urlKey: modelKey,
        cacheKey,
      });

    const trafficData = getTrafficDataByKey({ cacheKey, scenarioId, codeList: extraFeaturesCodeList.link });
    const context = _getIntervalData({
      dateTimeString: date,
      cacheKey,
      scenarioId,
      trafficData,
    });
    if (isLoading.value === cacheKey) isLoading.value = false;
    return context;
  };

  function _getIntervalData({ dateTimeString, cacheKey, scenarioId, trafficData }) {
    const hourCacheKey = `${scenarioId}.${cacheKey}.${dateTimeString}`;
    if (get(intervalCache, hourCacheKey, null)) return get(intervalCache, hourCacheKey);

    const context = {};
    const intervalIndex = _findIntervalIndex(new Date(dateTimeString), trafficData.modelInfo);

    for (const [id, entry] of Object.entries(trafficData)) {
      if (id === 'modelInfo') continue; // TODO improve trafficData struct together with STA
      const data = {
        outFlow: entry.outFlow[intervalIndex], // units vehicles per HOUR!
        inFlow: entry.inFlow[intervalIndex], // units vehicles per HOUR!
        travelTime: entry.travelTime[intervalIndex],
      };
      context[id] = data;
    }

    set(intervalCache, hourCacheKey, context);
    return context;
  }

  function _findIntervalIndex(dateTime, { startTime, endTime, numOfTimeIntervals } = {}) {
    const startDate = _getDateFromApiTime(dateTime, startTime);
    const endDate = _getDateFromApiTime(dateTime, endTime);
    const intervalStep = (endDate - startDate) / numOfTimeIntervals;
    return (dateTime - startDate) / intervalStep;
  }

  // DTA API returns only local timeString 'hh:mm:ss', not in utc
  // TODO review later, ask for API update
  function _getDateFromApiTime(sourceDate, timeString) {
    const dateCopy = new Date(sourceDate.valueOf());
    const [hours, minutes] = timeString.split(':');
    dateCopy.setHours(parseInt(hours), parseInt(minutes));
    return dateCopy;
  }

  return {
    getLinksIntervalCtx,
    isLoading: readonly(isLoading),
  };
}
