type InvalidationFunction = (...args: any) => void;
type InvalidationKey = string;

const invalidationFns: InvalidationFunction[] = [];
const invalidationKeys: InvalidationKey[] = [];
// TODO Review placement and usage of these functions (https://bitbucket.org/TraModFrontend/tramod-next/pull-requests/155)
// Composable x store x utils

// TODO Review usage of 'register' (https://bitbucket.org/TraModFrontend/tramod-next/pull-requests/155)
// Request register from composable files x export invalidations from composable and add "cache invalidation manager"
export function registerCacheInvalidation(key: InvalidationKey, invalidation: InvalidationFunction) {
  if (!invalidationKeys.includes(key)) invalidationFns.push(invalidation);
}

export function invalidateCache() {
  invalidationFns.forEach((invalidation) => invalidation());
}

export function isCacheEnabled(store: TmStore, cacheKey: InvalidationKey) {
  const cacheEnabled: boolean = store.state.scenarios.cache[cacheKey].enabled;
  const cacheExpired: boolean = store.getters['scenarios/isCacheExpired'](cacheKey);
  if (cacheExpired) store.commit('scenarios/RESET_CACHE_TIMER', { cacheKey });
  return cacheEnabled && !cacheExpired;
}
