import { defaults as interactions } from 'ol/interaction';
import { defineMap, mapManagerPlugin } from 'vuemap';
import { MAP_ID } from '@keys/index';
import { useStore } from 'vuex';

function mainMapSetup() {
  const store = useStore();
  const zoom = store.state.map.zoom || 8.14;
  const center = store.state.map.center || [1708085, 6413141];

  return {
    mapOptions: {
      interactions: interactions({ altShiftDragRotate: false, pinchRotate: false }),
      controls: [], // display no controls
    },
    viewOptions: { zoom, center }, // initial view position is later overwritten by specific traffic model setting as soon as it is fetched
    plugins: [mapManagerPlugin],
  };
}

export const useMainMap = defineMap(MAP_ID, mainMapSetup);
