<template>
  <component :is="mapLayout">
    <!-- router view -->
    <slot />
  </component>
</template>

<script setup lang="ts">
import { computed, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import useEditMode from '@composables/useEditMode';
import useAuth from '@composables/useAuth';
import useMobile from '@composables/useMobile';
import MapMobileLayout from '@layouts/MapMobile.vue';
import MapDesktopLayout from '@layouts/MapDesktop.vue';

const store = useStore();
const { verify: verifyUserLoggedOnServer } = useAuth();
const { verifyEditSessions } = useEditMode();
const isMobile = useMobile();

const mapLayout = computed(() => {
  return isMobile() ? MapMobileLayout : MapDesktopLayout;
});

const verifyLoggedUser = async () => {
  const isLogged = store.getters['auth/isLogged'];
  if (isLogged) await verifyUserLoggedOnServer();
};

onBeforeMount(async () => {
  await verifyLoggedUser(); // verify preserved logged user on the server-side
  await verifyEditSessions(); // verify ongoing edit sessions on the server-side
});
</script>
