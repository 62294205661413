<template>
  <div v-if="type == 'checkbox'" class="p-d-flex">
    <template v-for="opt in options">
      <div v-if="opt && typeof opt === 'object'" :key="getOptionKey(opt)" class="p-mr-2">
        <prime-checkbox
          :id="getOptionKey(opt)"
          v-model="selection"
          :value="optionValue ? opt[optionValue] : opt"
          :name="getOptionKey(opt)"
          :disabled="disabled || opt.disabled"
          :data-test="getOptionKey(opt) + '-filter'"
        />
        <label :for="getOptionKey(opt)" class="p-pl-1">{{ getLocalizedLabel(opt) }}</label>
      </div>
    </template>
  </div>
  <div v-else class="p-inputgroup">
    <span v-if="label" class="p-button button-label">{{ label }}</span>

    <prime-select-button
      v-if="type == 'radio'"
      v-model="selection"
      class="p-d-flex"
      :unselectable="false"
      :disabled="disabled"
      :options="options"
      :option-value="optionValue"
      :option-label="(option) => getLocalizedLabel(option)"
      @blur="$emit('blur')"
    />

    <prime-dropdown
      v-else-if="type == 'dropdown'"
      v-model="selection"
      :disabled="disabled"
      :options="options"
      :option-value="optionValue"
      :option-label="(option) => getLocalizedLabel(option)"
      placeholder="---"
      @blur="$emit('blur')"
    >
      <template #option="slotProps">
        <div>{{ getLocalizedLabel(slotProps.option) }}</div>
      </template>
    </prime-dropdown>

    <prime-auto-complete
      v-else-if="type.includes('autocomplete')"
      v-model="selection"
      :option-label="optionValue"
      :suggestions="options"
      :placeholder="placeholder"
      :force-selection="forcedSelection"
      :dropdown="type.includes('dropdown')"
      :multiple="type.includes('multiple')"
      append-to="body"
      @complete="$emit('autocomplete', $event.query)"
      @blur="$emit('blur')"
    />

    <prime-multi-select
      v-else-if="type.includes('multiselect')"
      v-model="selection"
      :options="options"
      :option-value="optionValue"
      :option-label="(option) => getLocalizedLabel(option)"
      :placeholder="placeholder"
      :disabled="disabled"
      display="chip"
      @blur="$emit('blur')"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import PrimeDropdown from 'primevue/dropdown';
import PrimeCheckbox from 'primevue/checkbox';
import PrimeSelectButton from 'primevue/selectbutton';
import PrimeAutoComplete from 'primevue/autocomplete';
import PrimeMultiSelect from 'primevue/multiselect';
import { useI18n } from 'vue-i18n';

type OptionObject = { name?: string; label?: string; disabled?: boolean; [key: string]: unknown };
type SelectionValue = string | number | boolean | OptionObject | null;

const props = withDefaults(
  defineProps<{
    type?:
      | 'radio'
      | 'checkbox'
      | 'dropdown'
      | 'autocomplete'
      | 'autocomplete-dropdown'
      | 'autocomplete-multiple'
      | 'multiselect'; // Type representing how the selection is displayed
    options?: SelectionValue[]; // Options to choose from
    selected?: SelectionValue | SelectionValue[]; // User-selected option or a list of selected options in case of multi-selection
    optionValue?: string; // Object property that is used as option value
    label?: string; // Label to display before the selection
    disabled?: boolean; // Disabled state of the entire selection
    placeholder?: string; // Text to display as placeholder
    forcedSelection?: boolean; // Whether to force selection in the autocomplete, ignoring user input
  }>(),
  {
    type: 'checkbox',
    options: () => [],
    selected: null,
    optionValue: undefined,
    label: undefined,
    disabled: false,
    placeholder: undefined,
    forcedSelection: true,
  },
);

const emit = defineEmits<{
  /** updates original model value */
  'update:selected': [value: SelectionValue | SelectionValue[]];
  /** triggers on search query in case of autocomplete selection */
  autocomplete: [query: string];
  /** fires when an element has lost focus */
  blur: [];
}>();

const { t } = useI18n();

const selection = computed({
  get: () => props.selected,
  set: (newSelection) => emit('update:selected', newSelection),
});

const getOptionKey = (option: SelectionValue) => {
  if (!option) return 'def-option';
  if (typeof option !== 'object') return option.toString();
  if (props.optionValue && option[props.optionValue]) return String(option[props.optionValue]);
  if (option.name) return option.name;
  if (option.label) return option.label;
  return 'def-option';
};

const getLocalizedLabel = (option: SelectionValue) => {
  if (!option) return '';
  if (typeof option === 'object' && typeof option.label === 'string') return t(option.label);
  if (typeof option === 'object' && typeof option.name === 'string') return option.name;
  return String(option);
};
</script>

<style scoped>
.p-selectbutton.p-buttonset {
  width: 100%;
}
.p-button.button-label {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
:deep(.p-selectbutton.p-buttonset .p-button) {
  flex: 1;
  padding: 0.5rem;
}
.p-inputgroup {
  width: auto;
}
:deep(.p-multiselect-trigger) {
  background: #0094d2;
  color: #ffffff;
  border-color: #0094d2;
}
:deep(.p-multiselect-trigger:hover) {
  background: #007aad;
  border-color: #007aad;
}
</style>
