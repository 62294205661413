import MapLayout from '@layouts/Map.vue';
import type { RouteRecordRaw } from 'vue-router';

const HomeView = () => import('@views/home/Home.vue');

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      layout: MapLayout,
    },
  },
];

export default routes;
