<template>
  <a
    v-if="scenarioStateIcon"
    v-tooltip="$t('scenarios.computation')"
    :class="{ hoverable: useLink }"
    @click="goToComputation()"
    ><i :class="scenarioStateIcon" class="status-icon"
  /></a>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useEditMode from '@composables/useEditMode';

const store = useStore();
const router = useRouter();
const { enterEditSession } = useEditMode();

const props = defineProps<{
  scenario: TmScenario;
  useLink?: Boolean;
}>();

const scenarioStateIcon = computed(() => {
  const scenarioId = props.scenario?.id;
  const hasAccess = ['editor', 'inserter'].includes(props.scenario?.level || '');
  if (!scenarioId || !hasAccess) return null;

  const isInsideComputation = store.getters['scenarios/isInsideComputation'](scenarioId);
  const currentRouteName = router.currentRoute.value.name as string;
  if (currentRouteName == 'scenarios.events' && !isInsideComputation) return null; // if inside scenario, only display icon in the computation

  const isBeingComputed = store.getters['scenarios/isScenarioBeingComputed'](scenarioId);
  const hasComputationReady = store.getters['scenarios/hasScenarioComputationReady'](scenarioId);
  const hasComputationError = store.getters['scenarios/hasScenarioComputationError'](scenarioId);

  if (hasComputationReady) return 'ri-checkbox-circle-line';
  if (isBeingComputed) return 'ri-time-line';
  if (hasComputationError) return 'ri-close-circle-line';

  return null;
});

const goToComputation = () => {
  if (!props.useLink) return;
  enterEditSession(props.scenario, { forceLoadChanges: true });
  router.push({ name: 'scenarios.events', params: { id: props.scenario?.id } });
};
</script>

<style scoped>
.status-icon {
  color: #0094d2;
  font-size: 1.5rem;
  font-weight: normal;
}
.hoverable .status-icon:hover {
  font-weight: bold;
}
</style>
