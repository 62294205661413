import { intersection } from 'lodash-es';
import { computed } from 'vue';
import { useStore } from 'vuex';
import useMapModes from '@composables/useMapModes';
import { MP_MODEL_STA_AGGREGATION, MP_MODEL_STA_AGGREGATION_COMPARISON } from '@keys/index';

export type TmDataModeObj = {
  name: TmDataMode;
  icon: string;
  tooltip: string;
  isBroken?: boolean;
  mapModes?: TmMapMode[];
};

const defaultDataModes: TmDataModeObj[] = [
  { name: 'historical', icon: 'ri-send-plane-2-line', tooltip: 'historical' },
  { name: 'live', icon: 'ri-send-plane-2-line', tooltip: 'live' },
  { name: 'model', icon: 'ri-send-plane-2-line', tooltip: 'model' },
  { name: 'comparison', icon: 'ri-tools-line', tooltip: 'comparison' },
];

export const ENABLED_DATA_MODES = import.meta.env.VITE_ENABLED_DATA_MODES?.split(', ') || [];

export default function useDataModes(dataModes = defaultDataModes) {
  const store = useStore();
  const { getMapModes, brokenMapModes } = useMapModes();
  const currentDataMode = computed<TmDataMode>(() => store.getters['map/getDataMode']);

  const availableDataModes = computed(() => {
    const enabledModes = dataModes.filter((dm) => ENABLED_DATA_MODES.includes(dm.name));

    return enabledModes.reduce((activeModes: TmDataModeObj[], dataMode) => {
      const mapModes = getMapModes(dataMode.name);
      if (!mapModes.length) return activeModes;
      const isBroken = intersection(brokenMapModes.value, mapModes).length > 0;
      activeModes.push({ ...dataMode, isBroken, mapModes });
      return activeModes;
    }, []);
  });

  const isActiveDataMode = (dataMode: TmDataModeObj) => dataMode.name === currentDataMode.value;

  const getDefaultMapMode = (dataMode: TmDataModeObj) => {
    // TODO: maybe add 'defaultMapMode' property to widget definition and use it here, also do auto set/unset the map mode on widget activation/deactivation
    // not trivial since default map mode could depend on current data mode and STA/DTA - so it can not be static (aggregation widgets are currently only for single data mode and STA only)
    const activeWidgetList = store.getters['map/getWidgets']({ dataMode: dataMode.name }); // obtain active widgets for the requested data mode
    if (activeWidgetList.includes('aggregation')) return MP_MODEL_STA_AGGREGATION;
    if (activeWidgetList.includes('aggregationComparison')) return MP_MODEL_STA_AGGREGATION_COMPARISON;
    const defaultMapMode = dataMode.mapModes?.[0]; // the first map mode in the list is considered the default one to navigate to
    return defaultMapMode;
  };

  const setDataMode = (dataMode: TmDataModeObj) => {
    if (isActiveDataMode(dataMode)) store.dispatch('layout/togglePanel', { panelSide: 'right' });
    else store.dispatch('map/setMapMode', { mode: getDefaultMapMode(dataMode), openWidgetPanel: true });
  };

  return {
    enabledDataModes: ENABLED_DATA_MODES,
    currentDataMode,
    availableDataModes,
    isActiveDataMode,
    setDataMode,
  };
}
