import { createI18n } from 'vue-i18n';
import messages from '@intlify/unplugin-vue-i18n/messages';
import store from '@store/index';
import i18nLocales from '@locales/plugins/i18n';

// Transpile messages files to locale.module.key structure
const parsedMessages = Object.entries(messages).reduce((acc: any, [key, nested]) => {
  const [topic, lang] = key.split('.');
  if (!acc[lang]) acc[lang] = {};
  acc[lang][topic] = nested;
  return acc;
}, {});

export const defaultLocale: TmLang = store.state.locale.lang;

export default createI18n({
  legacy: false, // use Compostion API
  globalInjection: true, // inject I18n properties and functions into the components - not usable inside setup() !!
  locale: defaultLocale,
  fallbackLocale: 'en',
  datetimeFormats: i18nLocales,
  messages: parsedMessages,
});
