// cookie-consent package defines its types globally... TODO: manage somehow?

const ccLocales: Record<TmLang, LanguageSetting> = {
  cs: {
    consent_modal: {
      title: 'Používáme cookie.',
      description:
        'Na tomto webu používáme soubory cookie k zajištění správné funkčnosti, optimalizaci, pro správu preferencí, analýzu rozsahu a anonymní statistiky. Získané údaje jsou anonymní a nesdílíme je s nikým dalším. Kdykoli máte možnost využít svého práva poskytnout nebo neposkytnout souhlas s oprávněným zájmem na základě konkrétního účelu. Máte také právo svůj souhlas kdykoliv odvolat. Podrobnější nastavení souhlasu provedete v  <button type="button" data-cc="c-settings" class="cc-link">nastavení</button>',
      primary_btn: {
        text: 'Přijmout vše',
        role: 'accept_all',
      },
      secondary_btn: {
        text: 'Přijmout pouze nezbytné',
        role: 'accept_necessary',
      },
    },
    settings_modal: {
      title: 'Nastavení cookie',
      save_settings_btn: 'Uložit',
      accept_all_btn: 'Přijmout vše',
      reject_all_btn: 'Odmítnout vše',
      close_btn_label: 'Zavřít',
      cookie_table_headers: [{ col1: 'Name' }, { col2: 'Domain' }, { col3: 'Expiration' }, { col4: 'Description' }],
      blocks: [
        {
          title: 'Používáme soubory cookie 📢',
          description:
            'Soubory cookie jsou krátké textové soubory, které si navštívený web ukládá ve vašem prohlížeči. Umožňují webu zaznamenat informace o vaší návštěvě a ty následně použít ke správnému fungování webu, případně ke statistickým nebo marketingovým účelům. Prohlížeč můžete nastavit tak, aby blokoval soubory cookie nebo o nich posílal upozornění. Mějte však na paměti, že některé stránky bez těchto souborů nemusí fungovat správně.',
        },
        {
          title: 'Naprosto nezbytné soubory cookie',
          description:
            'Jsou nezbytné k tomu, aby web fungoval, takže není možné je vypnout. Většinou jsou nastavené jako odezva na akce, které jste provedli, jako je požadavek služeb týkajících se bezpečnostních nastavení, přihlašování, vyplňování formulářů atp. Tyto soubory cookie neukládají žádné osobní identifikovatelné informace.',
          toggle: {
            value: 'necessary',
            enabled: true,
            readonly: true,
          },
        },
        {
          title: 'Analytické soubory cookie',
          description:
            'Pomáhají nám sestavit statistiky návštěvnosti webu. Konkrétně pomáhají sledovat počet návštěvníků, které stránky jsou nejoblíbenější, jakým způsobem se návštěvníci na webu pohybují a také z jakého zdroje provoz pochází. Všechny informace, které soubory cookie shromažďují, jsou souhrnné a anonymní.',
          toggle: {
            value: 'analytics',
            enabled: false,
            readonly: false,
          },
        },
        /*
           {
             title: "Marketingové soubory cookie",
             description: "Pomáhají sledovat, jak návštěvníci web používají, které stránky na webu navštěvují a na které odkazy klikají. Tyto anonymní informace využíváme v marketingovém nástroji Facebook Pixel. Všechny informace, které soubory cookie shromažďují, jsou souhrnné a anonymní.",
             toggle: {
               value: "marketing",
               enabled: false,
               readonly: false
             }
           },
           */
        {
          title: 'Více informací',
          description: import.meta.env.VITE_TEXT_COOKIE_CONTACT_CS || '',
        },
      ],
    },
  },
  en: {
    consent_modal: {
      title: 'We use cookies',
      description:
        'We use cookies on this website to ensure proper functionality, optimization, to manage preferences, to analyze the extent and for anonymous statistics. The data collected is anonymous and we do not share it with anyone else. At any time, you have the option to use your right to grant or withhold consent to a legitimate interest based on a specific purpose. You also have the right to withdraw your consent at any time. You can specify the detailes in the <button type="button" data-cc="c-settings" class="cc-link">settings</button>',
      primary_btn: {
        text: 'Accept all',
        role: 'accept_all',
      },
      secondary_btn: {
        text: 'Accept only necessary',
        role: 'accept_necessary',
      },
    },
    settings_modal: {
      title: 'Cookie settings',
      save_settings_btn: 'Save',
      accept_all_btn: 'Accept all',
      reject_all_btn: 'Reject all',
      close_btn_label: 'Close',
      cookie_table_headers: [{ col1: 'Name' }, { col2: 'Domain' }, { col3: 'Expiration' }, { col4: 'Description' }],
      blocks: [
        {
          title: 'We use cookies 📢',
          description:
            'Cookies are short text files that are stored in your browser when you visit a website. They allow a website to collect information about your visit and then use this information for the proper functioning of the website or for statistical or marketing purposes. You can set your browser to block cookies or send notifications about them. However, please note that some sites may not function properly without these files.',
        },
        {
          title: 'Necessary cookies',
          description:
            'They are necessary for the website to work, so it is not possible to turn them off. They are usually set up in response to actions you take, such as requesting services related to security settings, logging in, filling out forms, etc. These cookies do not store any personally identifiable information.',
          toggle: {
            value: 'necessary',
            enabled: true,
            readonly: true,
          },
        },
        {
          title: 'Analytical cookies',
          description:
            'They help us gather statistics on website visits. Specifically, they help us track the number of visitors, which pages are the most popular, how visitors move around the site, and the source of the traffic. All the information that cookies collect is aggregated and anonymous.',
          toggle: {
            value: 'analytics',
            enabled: false,
            readonly: false,
          },
        },
        /*
           {
             title: "Marketingové soubory cookie",
             description: "Pomáhají sledovat, jak návštěvníci web používají, které stránky na webu navštěvují a na které odkazy klikají. Tyto anonymní informace využíváme v marketingovém nástroji Facebook Pixel. Všechny informace, které soubory cookie shromažďují, jsou souhrnné a anonymní.",
             toggle: {
               value: "marketing",
               enabled: false,
               readonly: false
             }
           },
           */
        {
          title: 'More information',
          description: import.meta.env.VITE_TEXT_COOKIE_CONTACT_EN || '',
        },
      ],
    },
  },
};

export default ccLocales;
