<template>
  <tm-selection v-model:selected="locale" type="radio" :options="availableAppLangs" />
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { setLocale } from '@vee-validate/i18n';
import { watch } from 'vue';
import { useStore } from 'vuex';
import { usePrimeVue } from 'primevue/config';
import primeVueLocales from '@locales/plugins/prime-vue';
import useCookieConsent from '@composables/useCookieConsent';

const { locale } = useI18n({ useScope: 'global' });
const store = useStore();
const primevue = usePrimeVue();
const cookieConsent = useCookieConsent();
const enabledLangs = import.meta.env.VITE_ENABLED_LANGUAGES;
const availableAppLangs = enabledLangs?.split(', ');

watch(locale, (newLocale) => {
  if (typeof newLocale !== 'string') return;
  setLocale(newLocale); // change language for vee-validate
  const localeObjects = primeVueLocales as { [key: string]: any }; // TS_TODO: indexing literal object with string
  primevue.config.locale = localeObjects[newLocale]; // change language for primeVue
  cookieConsent.updateLanguage(newLocale, true); // change locale for cookie-consent
  store.commit('locale/SET_LANG', { lang: newLocale });
});
</script>
