<template>
  <prime-panel
    :collapsed="!isActive"
    class="p-mb-3"
    :class="{ 'active-item': isActive, 'mobile-view': $isMobile() }"
    @click="handleClick"
    @dblclick="handleDoubleClick"
    @mouseenter="showActions = true"
    @mouseleave="showActions = false"
    @mousedown.left="preventSelectionDbl"
  >
    <template #header>
      <div v-if="fields.switchInput" class="p-mr-3">
        <tm-switch v-model="switchState" :disabled="fields.switchInput.disabled === true" @click.stop />
      </div>
      <div v-if="fields.checkbox" class="p-mr-3">
        <prime-checkbox v-model="selectedItems" :value="item" @click.stop />
      </div>
      <div v-if="type === 'scenarios'">
        <tm-scenario-state-icon :scenario="item" :use-link="true" class="p-mr-3" />
      </div>
      <div v-if="fields.modIcon" class="p-mr-3">
        <tm-button
          type="create"
          :icon="fields.modIcon.icon"
          :border-color="fields.modIcon.border"
          class="p-mr-1 panel-icon"
        />
      </div>
      <div>
        <span class="item-heading">{{ getItemName() }}</span>
      </div>
      <div
        v-if="['basic', 'scenarios', 'events', 'modifications', 'imports'].includes(type)"
        class="p-ml-auto item-date"
        :class="{ transparent: showActions }"
      >
        {{ $tmdate.formatScenarioDatePeriod(item[fields.dates.from], item[fields.dates.to]) }}
      </div>
      <div v-show="showActions" class="p-py-3 p-pl-3 item-actions">
        <a
          v-for="action in fields.actions"
          :key="action.action"
          v-tooltip="$t(`scenarios.${action.action}`)"
          class="p-mr-3 item-action"
          ><i
            :class="action.icon + ' pi'"
            :data-type="action.action"
            @click.stop="$emit('action:clicked', action.action)"
        /></a>
      </div>
    </template>

    <div class="p-d-flex">
      <div v-for="(opt, i) in fields.content" :key="i" class="p-mr-2" :class="opt.class">
        <span v-if="opt.field">{{ $t(opt.name) }}: </span>
        <strong v-else>{{ $t(opt.name) }}</strong>
        <strong v-if="opt.field">{{
          item[opt.field] === true
            ? $t('scenarios.yes')
            : item[opt.field] === false
            ? $t('scenarios.no')
            : item[opt.field]
        }}</strong>
        <strong v-if="opt.field2">/{{ item[opt.field2] }}</strong>
        <span v-if="opt.units">&nbsp;{{ $t(opt.units) }}</span>
      </div>
      <div v-if="['scenarios', 'events'].includes(type)" class="p-ml-auto">
        <span>{{ $t('scenarios.last edit') }}: </span>
        <strong>{{ $d(new Date(item[fields.dates.lastEdit]), 'default') }}</strong>
      </div>
    </div>
  </prime-panel>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import useAbility from '@composables/useAbility';
import { types as modificationTypes } from '@composables/useModifications';
import PrimePanel from 'primevue/panel';
import PrimeCheckbox from 'primevue/checkbox';
import TmScenarioStateIcon from '@components/views/ScenarioStateIcon.vue';

export type ItemType = 'scenarios' | 'events' | 'modifications' | 'imports' | 'basic';
export type ListItem = { [key: string]: any };
export type ItemAction = 'enter' | 'copy' | 'delete' | 'share';
type ItemFields = {
  header: string;
  dates: { from: keyof ListItem; to: keyof ListItem; lastEdit: keyof ListItem };
  content: { name: string; field?: keyof ListItem; field2?: keyof ListItem; units?: string; class?: string }[];
  actions: { action: ItemAction; icon: string }[];
  switchInput?: { field: keyof ListItem; disabled: boolean };
  modIcon?: (typeof modificationTypes)[0];
  checkbox?: boolean;
};

const props = withDefaults(
  defineProps<{
    type?: ItemType; // Type representing the view to witch the item belongs
    item: ListItem; //  Item object to display
    isActive?: boolean; // Whether the item is in 'active' mode and should display details
    access?: TmAccessLevel; //  User access to this item - affects displayed action icons
    accessEventObject?: TmEvent; // Event object - only needed for type 'modifications' and used for authorization
    watchMode?: boolean; // Whether to display the item in watch mode (without any action icons)
    selected?: ListItem[]; // List of selected items
  }>(),
  {
    type: 'basic',
    isActive: false,
    access: undefined,
    accessEventObject: undefined,
    watchMode: true,
    selected: () => [],
  },
);

const emit = defineEmits<{
  clicked: [item: ListItem]; // Signals that an item has been clicked on
  checked: [checked: boolean]; // Signals that an item has been checked
  'update:selected': [items: ListItem[]]; // Updates original selected model
  'action:clicked': [action: ItemAction]; // Signals that the item action was checked
}>();

const { can } = useAbility();

const isEnterAction = computed(() => fields.value.actions.some((action) => action.action === 'enter'));

// Prevents text selection on item with enter action on doubleclick as requested in TraMod-1080,1095
const preventSelectionDbl = (ev: MouseEvent) => {
  if (isEnterAction.value && ev.detail === 2) ev.preventDefault();
};

const handleClick = (ev: MouseEvent) => {
  if (ev.detail > 1) return; // prevent multiple emits if clicked more than once
  emit('clicked', props.item);
};

const handleDoubleClick = () => {
  if (isEnterAction.value) emit('action:clicked', 'enter');
};

const getCustomScenarioFields = (): Partial<ItemFields> => {
  const scenarioItem = props.item as TmScenario;
  return {
    actions: [
      ...(props.watchMode || can('enter', { scenario: scenarioItem })
        ? [{ action: 'enter' as ItemAction, icon: 'pi-directions' }]
        : []),
      ...(!props.watchMode && can('update', { scenario: scenarioItem }) && _isItemValid(scenarioItem)
        ? [{ action: 'share' as ItemAction, icon: 'pi-share-alt' }]
        : []),
      ...(!props.watchMode && _isItemValid(scenarioItem) ? [{ action: 'copy' as ItemAction, icon: 'pi-copy' }] : []),
      ...(!props.watchMode && can('delete', { scenario: scenarioItem }) && _isItemValid(scenarioItem)
        ? [{ action: 'delete' as ItemAction, icon: 'pi-trash' }]
        : []),
    ],
  };
};

const getCustomEventFields = (): Partial<ItemFields> => {
  const eventItem = props.item as TmEvent;
  return {
    actions: [
      ...(props.watchMode || can('enter', { scenario: { level: props.access, events: [eventItem] } })
        ? [{ action: 'enter' as ItemAction, icon: 'pi-directions' }]
        : []),
      ...(!props.watchMode &&
      can('create:event', { scenario: { level: props.access, events: [eventItem] } }) &&
      _isItemValid(eventItem)
        ? [{ action: 'copy' as ItemAction, icon: 'pi-copy' }]
        : []),
      ...(!props.watchMode &&
      can('delete:event', { scenario: { level: props.access, events: [eventItem] } }) &&
      _isItemValid(eventItem)
        ? [{ action: 'delete' as ItemAction, icon: 'pi-trash' }]
        : []),
    ],
    switchInput: {
      field: 'included',
      disabled:
        props.watchMode || !can('update:event:included', { scenario: { level: props.access, events: [eventItem] } }),
    },
  };
};

const getCustomModificationFields = (): Partial<ItemFields> => {
  const modificationItem = props.item as TmModification;
  return {
    content: [
      ...(modificationItem.type == 'link' && modificationItem.mode == 'new'
        ? [
            {
              field: 'capacity',
              field2: modificationItem.twoWay ? 'twoWayCapacity' : '',
              name: 'modifications.capacity',
              units: 'map.vehicles per hour',
            },
            {
              field: 'speed',
              field2: modificationItem.twoWay ? 'twoWaySpeed' : '',
              name: 'modifications.speed',
              units: 'map.kilometers per hour',
            },
          ]
        : []),
      ...(modificationItem.type == 'link' &&
      modificationItem.mode == 'existing' &&
      (modificationItem.capacity > 0 || modificationItem.speed > 0)
        ? [
            {
              field: 'capacity',
              name: 'modifications.capacity',
              units: 'map.vehicles per hour',
            },
            {
              field: 'speed',
              name: 'modifications.speed',
              units: 'map.kilometers per hour',
            },
          ]
        : []),
      ...(modificationItem.type == 'link' &&
      modificationItem.mode == 'existing' &&
      !modificationItem.capacity &&
      !modificationItem.speed
        ? [{ name: 'modifications.closure' }]
        : []),
      ...(modificationItem.type == 'node' && modificationItem.mode == 'existing' && modificationItem.cost < 0
        ? [{ name: 'modifications.closure' }]
        : []),
      ...(modificationItem.type == 'node' && modificationItem.mode == 'existing' && modificationItem.cost > 0
        ? [
            {
              field: 'cost',
              name: 'modifications.delay',
              units: 'modifications.seconds',
            },
          ]
        : []),
      ...(modificationItem.type == 'generator'
        ? [
            {
              field: 'inTraffic',
              field2: 'outTraffic',
              name: 'modifications.target source traffic',
              units: 'map.vehicles per hour',
            },
          ]
        : []),
    ],
    actions: [
      ...(props.watchMode || can('enter', { scenario: { level: props.access, events: [props.accessEventObject] } })
        ? [{ action: 'enter' as ItemAction, icon: 'pi-directions' }]
        : []),
      ...(!props.watchMode &&
      can('update:event', { scenario: { level: props.access, events: [props.accessEventObject] } }) &&
      _isItemValid(modificationItem)
        ? [{ action: 'copy' as ItemAction, icon: 'pi-copy' }]
        : []),
      ...(!props.watchMode &&
      can('delete:event', { scenario: { level: props.access, events: [props.accessEventObject] } }) &&
      _isItemValid(modificationItem)
        ? [{ action: 'delete' as ItemAction, icon: 'pi-trash' }]
        : []),
    ],
    modIcon: modificationTypes.find((el) => el.type == modificationItem.type && el.mode == modificationItem.mode),
  };
};

const getCustomImportFields = (): Partial<ItemFields> => ({
  content: [
    { field: 'scenarioName', name: 'scenarios.scenario' },
    { field: 'included', name: 'scenarios.included', class: 'p-ml-auto' },
  ],
  checkbox: true,
});

const fields = computed<ItemFields>(() => ({
  header: 'name',
  dates: { from: 'dateFrom', to: 'dateTo', lastEdit: 'updatedAt' },
  content: [{ field: 'owner', name: 'scenarios.owner' }],
  actions: [],
  ...(props.type === 'scenarios' ? getCustomScenarioFields() : {}),
  ...(props.type === 'events' ? getCustomEventFields() : {}),
  ...(props.type === 'modifications' ? getCustomModificationFields() : {}),
  ...(props.type === 'imports' ? getCustomImportFields() : {}),
}));

const switchState = computed<boolean>({
  get: () => !!fields.value.switchInput && !!props.item[fields.value.switchInput.field as keyof typeof props.item],
  set: (newState) => emit('checked', newState),
});
const showActions = ref<boolean>(false);
const getItemName = (): string => {
  if (props.type !== 'modifications') return props.item[fields.value.header as keyof typeof props.item];
  // custom name for modifications
  const modificationItem = props.item as TmModification;
  return modificationItem.name || `${modificationItem.type}-${modificationItem.id}`;
};

const selectedItems = computed<ListItem[]>({
  get: () => props.selected,
  set: (selected) => emit('update:selected', selected),
});

const _isItemValid = (item: ListItem): boolean => [undefined, true].includes(item.hasModelSectionsValid);
</script>

<style scoped>
.item-actions {
  white-space: nowrap;
  position: absolute;
  right: 0;
  background-color: rgb(248, 249, 250);
}
.item-action {
  cursor: pointer;
  color: #0094d2;
}
.p-panel-header:hover .item-heading,
.item-action:hover i,
.active-item .item-heading {
  font-weight: bold;
}
.p-panel-header .item-date.transparent {
  opacity: 0.5;
}
.tm-button.panel-icon :deep(.p-button) {
  font-size: 0.8rem;
  height: 1.8rem !important;
  width: 1.8rem !important;
  pointer-events: none;
}
.mobile-view .item-date,
.mobile-view .item-actions,
.mobile-view :deep(.p-toggleable-content) {
  display: none;
}
</style>
