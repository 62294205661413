import { useToast } from 'primevue/usetoast';
// import type { ToastMessageOptions } from 'primevue/toast';

// ToastMessageOptions type is very vague and it is better to use own custom types
export type TmToastSeverity = 'info' | 'error' | 'success' | 'warn';

const MESSAGE_LIFETIME = 4000;

export default function useTmToast() {
  const toast = useToast();

  const addToast = ({
    severity = <TmToastSeverity>'info',
    life = <number>MESSAGE_LIFETIME,
    summary = <string>'',
    detail = <string>'',
  }) =>
    toast.add({
      severity,
      life,
      summary,
      detail,
    });

  return { addToast };
}
