<template>
  <!-- timeline card for mobile view -->
  <tm-card v-if="$isMobile()">
    <template #header>
      <tm-heading :text="$t(`widgets.timeline widget`)" widget-name="timeline" />
      <tm-divider />
    </template>

    <template #content>
      <div class="p-d-flex p-jc-center p-mt-5 p-pb-1">
        <tm-button
          type="create"
          :icon="props.icon"
          :tooltip="$t('widgets.display timeline widget')"
          :disabled="!isTimelineActive"
          @click="isTimelineActive && openTimeline()"
        />
      </div>
    </template>
  </tm-card>

  <!-- button-only mode for desktop view -->
  <tm-button
    v-else
    v-show="isTimelineActive"
    type="widget"
    :icon="props.icon"
    :tooltip="$t('widgets.timeline widget')"
    @click="openTimeline()"
  />
</template>

<script setup lang="ts">
import useMapItems from '@composables/useMapItems';

const props = withDefaults(defineProps<{ icon?: string }>(), { icon: 'ri-bar-chart-horizontal-fill' });

const { openTimeline, isTimelineActive } = useMapItems();
</script>
