import { unset } from 'lodash-es';
import modelSTATrafficInterval from './useModelSTATrafficInterval';
import modelDTATrafficInterval from './useModelDTATrafficInterval';

const STAIntervalCache = {};
const DTAIntervalCache = {};

export const invalidateModelTrafficInterval = (scenarioId) => {
  unset(STAIntervalCache, `${scenarioId}`);
  unset(DTAIntervalCache, `${scenarioId}`);
};

export const useModelSTATrafficInterval = () => modelSTATrafficInterval(STAIntervalCache);
export const useModelDTATrafficInterval = () => modelDTATrafficInterval(DTAIntervalCache);
