<template>
  <div class="controls p-d-flex p-jc-between">
    <tm-locale v-if="controls.includes('lang')" />
    <div v-if="controls.includes('zoom')" class="zoom p-ml-3">
      <tm-button type="zoom" icon="pi-plus" @click="zoomIn()" />
      <tm-button type="zoom" icon="ri-search-eye-line" @click="zoomToDefaultView()" />
      <tm-button type="zoom" icon="pi-minus" @click="zoomOut()" />
    </div>
    <tm-selection
      v-if="controls.includes('map')"
      v-model:selected="mapBase"
      :options="['OSM', 'OSM-standard']"
      class="base-switcher p-ml-3"
      type="dropdown"
    />
    <prime-toggle-button
      v-if="controls.includes('vehicles')"
      v-model="vehiclesCountDisplayed"
      class="p-ml-3"
      :on-label="$t('navigation.vehicles count')"
      :off-label="$t('navigation.vehicles count')"
      on-icon="pi pi-eye"
      off-icon="pi pi-eye-slash"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';
import PrimeToggleButton from 'primevue/togglebutton';
import TmLocale from '@components/layouts/Locale.vue';

type TmControls = 'lang' | 'zoom' | 'map' | 'vehicles';

withDefaults(
  defineProps<{
    controls?: TmControls[]; // List of controls to display
  }>(),
  {
    controls: () => ['lang', 'zoom', 'map', 'vehicles'],
  },
);

const store = useStore();

const vehiclesCountDisplayed = computed({
  get: () => store.state.map.mapStyle.general.vehiclesCountDisplayed,
  set: (newState) =>
    store.commit('map/SET_MAP_STYLE', { newState, group: 'general', property: 'vehiclesCountDisplayed' }),
});

const mapBase = computed({
  get: () => store.state.map.base,
  set: (baseId) => store.dispatch('map/setBase', { baseId }),
});

const zoomIn = () => store.dispatch('map/zoomIn');
const zoomOut = () => store.dispatch('map/zoomOut');
const zoomToDefaultView = () => {
  const zoom = store.state.scenarios.model?.zoom;
  const center = store.state.scenarios.model?.center;
  if (!zoom || !center) return;
  store.dispatch('map/setPosition', { zoom, center });
};
</script>

<style scoped>
.zoom {
  white-space: nowrap;
}
</style>
