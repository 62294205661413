<template>
  <metainfo />

  <component :is="layout || 'div'">
    <router-view />
  </component>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { onBeforeMount, computed } from 'vue';
import useCookieConsent from '@composables/useCookieConsent';
import useAuth from '@composables/useAuth';
import useQuerySync from '@composables/useQuerySync';
import { useMeta } from 'vue-meta';
import { useI18n } from 'vue-i18n';
import useToast from '@composables/useToast';

const route = useRoute();
const store = useStore();
const layout = computed(() => route.meta?.layout);
const cookieConsent = useCookieConsent();
const { startAuthWatcher } = useAuth();
const { startQuerySync } = useQuerySync();
const { locale, t } = useI18n();
const { addToast } = useToast();

useMeta(
  computed(() => {
    const envLangSuffix = locale.value.toUpperCase();
    // TODO: this is temporary solution until it is decided to store custom texts elsewhere (db)
    return {
      title: import.meta.env[`VITE_TEXT_APP_TITLE_${envLangSuffix}`] || 'Traffic Modeller',
      description: import.meta.env[`VITE_TEXT_APP_DESCRIPTION_${envLangSuffix}`] || '',
      htmlAttrs: { lang: locale.value, 'xml:lang': locale.value },
      meta: [{ 'http-equiv': 'Content-Language', content: locale.value }],
    };
  }),
);

function startGuardedRouteAlertWatcher() {
  store.subscribe((mutation) => {
    if (mutation.type == 'layout/SET_GUARDED_ROUTE_ALERT') {
      addToast({
        severity: 'info',
        summary: t('accesses.please login to continue'),
      });
    }
  });
}

onBeforeMount(() => {
  startQuerySync();
  startAuthWatcher();
  startGuardedRouteAlertWatcher();

  cookieConsent.onConsentChange(() => {
    const userPreferences = cookieConsent.getUserPreferences();

    const { accepted_categories: accepted } = userPreferences;
    if (!accepted || !accepted.includes('analytics')) {
      console.log('Cookie consent denied');
      // if no analytics -> delete GA cookies manually - just to be sure
      cookieConsent.eraseCookies(['_ga', '_gid']);
      store.commit('layout/SET_ANALYTICS', { isEnabled: false });
    } else {
      console.log('Cookie consent approved');
      store.commit('layout/SET_ANALYTICS', { isEnabled: true });
      // TODO: tell tracking plugin we can apply analytics (if we decide to use some tracking plugin ) (vue-gtm, vue-gtag)
    }
  });
});
</script>

<style>
/* GLOBAL styling! */
html {
  /* this automatically scales all PrimeVue components */
  font-size: 16px;
}
a {
  color: #0094d2;
}
/* dialog elements are outside #app scope and must be styled globally */
.p-dialog {
  max-width: 100%;
  max-height: 100%;
}
.p-dialog .p-dialog-content .content i {
  font-size: 2rem;
}
.tm-dialog.p-dialog .p-dialog-content {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.tm-dialog.p-dialog .p-dialog-content > .content {
  overflow-y: auto;
  padding: 0 1.5rem 1.5rem 1.5rem;
}
.tm-dialog.p-dialog .p-dialog-content .actions {
  padding: 0 1.5rem 1.5rem 1.5rem;
}
/* dropdown option elements are are outside #app scope and must be styled globally */
body > .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0 !important;
}
body > .p-dropdown-panel .p-dropdown-items .p-dropdown-item div {
  padding: 0.5rem 1rem;
}
/* toast elements are outside #app scope and must be styled globally */
.p-toast {
  max-width: 95%;
  z-index: 2000 !important;
}
.p-toast-detail {
  /* this reflects new line (\n) inside message text */
  white-space: pre-line;
}
/* global scrollbar stylings */
::-webkit-scrollbar {
  width: 0.5rem;
}
::-webkit-scrollbar-track {
  background: #dee2e6;
}
::-webkit-scrollbar-thumb {
  background: #6c757d;
}
/* standardized border colors - should be consistent for the entire app therefore global */
[class*=' border-color-'] {
  border-width: 3px !important;
}
.border-color-red {
  border-color: #f05a28 !important;
}
.border-color-green {
  border-color: #3ec723 !important;
}
.border-color-yellow {
  border-color: #f4ee07 !important;
}
.border-color-blue {
  border-color: #007aad !important;
}
</style>
