<template>
  <div class="p-mt-3 p-mb-3">
    <div v-if="ready">
      <div v-if="searchPhrase !== undefined">
        <div class="p-inputgroup p-mb-3">
          <prime-button icon="ri ri-search-line" />
          <prime-input-text v-model="textPhrase" name="search" :placeholder="$t('scenarios.search')" />
        </div>
      </div>

      <div class="select-wrapper">
        <prime-multi-select
          v-model="groupedSelection"
          :options="groupedOptions"
          :option-label="(option) => getLocalizedLabel(option)"
          :option-group-label="(group) => getLocalizedLabel(group)"
          option-group-children="items"
          :placeholder="$t('scenarios.filters')"
          :show-toggle-all="false"
          data-test="grouped-filters"
        >
          <template #value>
            <i class="ri-filter-line filter-icon" />
            {{ $t('scenarios.filters') }}
            <prime-badge v-if="groupedSelection.length" :value="groupedSelection.length" />
          </template>
        </prime-multi-select>

        <prime-dropdown
          v-model="sortingSelection"
          :options="sortingOptions"
          :placeholder="$t('scenarios.sorting')"
          :option-label="(option) => getLocalizedLabel(option)"
          data-test="sorting"
        >
          <template #option="slotProps">
            <div>{{ getLocalizedLabel(slotProps.option) }}</div>
          </template>
        </prime-dropdown>
      </div>
    </div>
    <div v-else>
      <div class="p-d-flex p-ai-end p-mb-3">
        <prime-skeleton height="2rem" width="30%" class="p-mr-2" />
        <prime-skeleton height="2rem" width="30%" class="p-mr-2" />
        <prime-skeleton height="2rem" width="30%" class="p-mr-2" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import PrimeMultiSelect from 'primevue/multiselect';
import PrimeDropdown from 'primevue/dropdown';
import PrimeBadge from 'primevue/badge';
import PrimeInputText from 'primevue/inputtext';
import PrimeButton from 'primevue/button';
import type { Filter, Sorting } from '@composables/useFilters';

type SearchPhrase = string | undefined;

const props = defineProps<{
  selectedSharing?: Filter[];
  selectedDating?: Filter[];
  selectedInclusion?: Filter[];
  selectedSorting?: Sorting;
  sharingOptions?: Filter[];
  datingOptions?: Filter[];
  inclusionOptions?: Filter[];
  sortingOptions?: Sorting[];
  searchPhrase?: SearchPhrase;
  ready: boolean; // Whether the content is ready to be displayed (if false - it will display temporal skeleton panels)
}>();

const emit = defineEmits<{
  'update:selectedSharing': [selected: Filter[]];
  'update:selectedDating': [selected: Filter[]];
  'update:selectedInclusion': [selected: Filter[]];
  'update:selectedSorting': [selected?: Sorting];
  'update:searchPhrase': [phrase: SearchPhrase];
}>();

const { t } = useI18n();

const groupedOptions = computed(() => {
  const options = [];
  if (props.sharingOptions) options.push({ label: 'scenarios.sharing', items: props.sharingOptions });
  if (props.datingOptions) options.push({ label: 'scenarios.date', items: props.datingOptions });
  if (props.inclusionOptions) options.push({ label: 'scenarios.inclusion', items: props.inclusionOptions });
  return options;
});

const groupedSelection = computed({
  get: () => [...(props.selectedSharing || []), ...(props.selectedDating || []), ...(props.selectedInclusion || [])],
  set: (newGroupedSelection) => {
    const sharingOptionNames = props.sharingOptions?.map((o) => o.name) || [];
    const datingOptionNames = props.datingOptions?.map((o) => o.name) || [];
    const inclusionOptionNames = props.inclusionOptions?.map((o) => o.name) || [];
    const sharingSelection = newGroupedSelection.filter((s) => sharingOptionNames.includes(s.name));
    const datingSelection = newGroupedSelection.filter((s) => datingOptionNames.includes(s.name));
    const inclusionSelection = newGroupedSelection.filter((s) => inclusionOptionNames.includes(s.name));
    emit('update:selectedSharing', sharingSelection);
    emit('update:selectedDating', datingSelection);
    emit('update:selectedInclusion', inclusionSelection);
  },
});

const sortingSelection = computed({
  get: () => props.selectedSorting,
  set: (newSelection) => emit('update:selectedSorting', newSelection),
});

const textPhrase = computed({
  get: () => props.searchPhrase,
  set: (phrase) => emit('update:searchPhrase', phrase),
});

const getLocalizedLabel = (option: string | { label: string } | { optionGroup: { label: string } }) => {
  if (typeof option === 'string') return option;
  if ('label' in option) return t(option.label);
  if ('optionGroup' in option) return t(option.optionGroup.label);
  return 'n/a';
};
</script>

<style scoped>
.select-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.select-wrapper > div {
  padding: 0;
  margin: 0;
  width: 49%;
}
:deep(.p-multiselect-token),
:deep(.p-multiselect-token-icon) {
  font-size: 0.9rem;
}
:deep(.p-multiselect),
:deep(.p-dropdown) {
  overflow: hidden;
}
:deep(.p-multiselect-label),
:deep(.p-dropdown-label.p-inputtext) {
  padding: 0.2rem 0rem 0.2rem 0.5rem !important;
}
:deep(.p-multiselect-token) {
  margin: 0.2rem 0 0 0 !important;
  display: flex;
  justify-content: space-between;
}
:deep(.p-multiselect-token-label) {
  max-width: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
:deep(.p-multiselect-token-icon) {
  margin-left: 0.1rem !important;
}
:deep(.p-multiselect-label .filter-icon),
:deep(.p-dropdown-label.p-inputtext:before) {
  padding-right: 0.3rem;
  vertical-align: -5%;
}
:deep(.p-dropdown-label.p-inputtext:before) {
  font-family: 'remixicon' !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  content: '\f160';
  vertical-align: -10%;
}
:deep(.p-badge) {
  height: 1rem;
  width: 1rem;
  min-width: 1rem;
  vertical-align: 10%;
  line-height: 1rem;
  margin-left: 0.3rem;
}
</style>
