import { defineLayer, tileLayer } from 'vuemap';

export const useOsm = defineLayer('osm', {
  layerType: tileLayer,
  sourceOptions: { type: 'OSM', url: import.meta.env.VITE_API_OSM },
});

export const useOsmLight = defineLayer('osmLight', {
  layerType: tileLayer,
  layerOptions: { className: 'tm-layer-pastel' },
  sourceOptions: { type: 'OSM', url: import.meta.env.VITE_API_OSM },
});

export const useOsmWorld = defineLayer('osmWorld', {
  layerType: tileLayer,
  layerOptions: { className: 'tm-layer-pastel' },
  sourceOptions: { type: 'OSM', url: import.meta.env.VITE_API_OSM_WORLD },
});
