import MapLayout from '@layouts/Map.vue';
import type { RouteRecordRaw } from 'vue-router';

const ModelsView = () => import('@views/models/Models.vue');

const routes: RouteRecordRaw[] = [
  {
    path: '/models',
    name: 'models',
    component: ModelsView,
    meta: {
      layout: MapLayout,
      requiresAuth: false,
    },
  },
];

export default routes;
