import { ref } from 'vue';
import { createMongoAbility, createAliasResolver, subject } from '@casl/ability';
import store from '@store/index';
import { pluginKey, pluginGlobal } from './keys';
import type { App } from 'vue';
import type { MongoAbility, SubjectRawRule, ExtractSubjectType, Subject, MongoQuery } from '@casl/ability';
import type { AliasesMap } from '@casl/ability/dist/types/types';

const ability = ref<MongoAbility>();
let resolveAction: (action: string | string[]) => string[];

const initialize = () => {
  const aliases: AliasesMap = store.state.auth.casl?.aliases || {};
  const rules: SubjectRawRule<string, ExtractSubjectType<Subject>, MongoQuery<any>>[] =
    store.state.auth.casl?.rules || [];

  // init with rules and aliases from the store (in case of page refresh)
  resolveAction = createAliasResolver(aliases);
  ability.value = createMongoAbility(rules, { resolveAction });

  // update rules on login / logout
  store.subscribe((mutation, state) => {
    if (['auth/SET_AUTH', 'auth/PURGE_AUTH'].includes(mutation.type)) {
      const aliases: AliasesMap = state.auth.casl.aliases;
      const rules: SubjectRawRule<string, ExtractSubjectType<Subject>, MongoQuery<any>>[] = state.auth.casl.rules;
      // ability.update(rules);  // this is the suggested way to update ability, but it does not allow to update aliases..
      // another approach would be to have static aliases or fetch aliases before ability initialization
      resolveAction = createAliasResolver(aliases);
      ability.value = createMongoAbility(rules, { resolveAction });
    }
  });
};

export default {
  install: (app: App) => {
    initialize();
    app.config.globalProperties[pluginGlobal] = { can };
    app.provide(pluginKey, { can });
  },
};

export const can = (action: string, subjectObj: any) => {
  if (!ability.value) return false;

  const subjectName = Object.keys(subjectObj)[0];
  const subjectModel = subject(subjectName, subjectObj[subjectName]);
  return ability.value.can(action, subjectModel);
};
