<template>
  <div data-test="heading" class="heading" :class="{ widget: widgetName }">
    <tm-button v-if="backToRoute" icon="pi-arrow-left" type="back" @click="goToRoute(backToRoute)" />
    <span v-if="text">{{ text }}</span>
    <slot v-if="$slots.default" />
    <i v-if="!text && !$slots.default" class="pi pi-spin pi-spinner loader" />
    <button v-if="widgetName" class="close-icon p-link" @click="closeWidget(widgetName as string)">
      <i class="pi pi-times" />
    </button>
  </div>
</template>

<script setup lang="ts">
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    text?: string; // Text of the heading
    textSize?: 'small' | 'medium' | 'large'; // Define the font-size of text
    backToRoute?: string | Function; // Name of the route to go back to via 'back' button OR a custom 'go back' function
    widgetName?: string; // Name of the widget, specify in case this heading is intended for a widget card
  }>(),
  {
    text: undefined,
    textSize: 'large',
    backToRoute: undefined,
    widgetName: undefined,
  },
);

const emit = defineEmits<{
  'widget:closed': [];
}>();

const store = useStore();
const router = useRouter();

const textFontSize = computed(() => {
  if (props.textSize === 'small') return '1.2rem';
  if (props.textSize === 'medium') return '1.5rem';
  return '1.8rem';
});

const closeWidget = (name: string) => {
  store.dispatch('map/hideWidget', { name });
  emit('widget:closed');
};

const goToRoute = (goTo: string | Function) => {
  if (typeof goTo === 'function') return goTo();
  router.push({ name: goTo });
};
</script>

<style scoped>
.heading {
  display: flex;
  flex-shrink: 0;
}
.heading > span {
  font-size: v-bind(textFontSize);
  font-weight: 700;
}
.heading.widget {
  align-items: baseline;
  justify-content: space-between;
}
.loader {
  right: 0.5rem;
  color: #6c757d;
  position: absolute;
  top: 50%;
  margin-top: -0.3rem;
}
.close-icon {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  text-align: center;
}
.close-icon i {
  font-size: 1.2rem;
  line-height: 1.7;
}
.close-icon:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
</style>
