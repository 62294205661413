import { set, get } from 'lodash-es';
import { today, getModelMonday } from '@utils/tm-date';
import { getDataModeByMapMode } from '@composables/useMapModes';
import {
  INTERACTION_MAP_MODES,
  COMPARISON_MAP_MODES,
  MP_MODEL_DTA_VIEWER,
  MP_MODEL_STA_VIEWER,
  MP_SHORTEST_SELECT,
  MP_SHORTEST_VIEW,
  MP_MODEL_DTA_COMPARISON,
  MP_MODEL_STA_COMPARISON,
  MP_HISTORICAL_COMPARISON,
  getModelModeByModelType,
  getModelTypeByModelName,
  MP_HISTORICAL_AGGREGATION,
  AGGREGATION_MAP_MODES,
  MP_H_AGGREGATION_COMPARISON,
  MP_MODEL_STA_AGGREGATION,
  MP_MODEL_STA_AGGREGATION_COMPARISON,
  MP_LINK_EXPORT_SELECT,
  MP_HISTORICAL_VIEWER,
  MP_LINK_EXPORT_SELECT_AGGREGATION,
} from '@keys/index';
import type { Router } from 'vue-router';

type ExpectedQueryParam = {
  key: string;
  path: string;
  isArray?: boolean;
  valueType?: NumberConstructor;
  queryTransformFn?: (...args: any[]) => any;
  stateTransformFn?: (...args: any[]) => any;
  conditionalDisplayFn?: (arg: any) => boolean;
};

const expectedQueryParams: ExpectedQueryParam[] = [
  { key: 'mapmode', path: 'map.mapMode' },
  { key: 'mapitem', path: 'map.item', isArray: true, valueType: Number },
  { key: 'mapzoom', path: 'map.zoom', valueType: Number },
  { key: 'mapcenter', path: 'map.center', isArray: true, valueType: Number },
  {
    key: 'maptime',
    path: 'map.calendar',
    queryTransformFn: (date: TmDate, query) => {
      // additional 'query to state' transformation specific for this param
      const mapMode = query.get('mapmode') || import.meta.env.VITE_DEFAULT_MAP_MODE;
      const modelName = query.get('model') || import.meta.env.VITE_DEFAULT_TRAFFIC_MODEL;
      const modelType = getModelTypeByModelName(modelName);
      return [{ date, mapMode: _getCalendarMapMode({ mapMode, modelType }), modelType }];
    },
    stateTransformFn: (calendarState, state) => {
      // additional 'state to query' transformation specific for this param
      return getCalendarDate({ calendarState, mapMode: state.map.mapMode, modelType: state.scenarios.model.type });
    },
  },
  {
    key: 'comparison',
    path: 'map.comparison',
    isArray: true,
    conditionalDisplayFn: (state) => COMPARISON_MAP_MODES.includes(get(state, 'map.mapMode')),
  },
  {
    key: 'base',
    path: 'map.base',
    conditionalDisplayFn: (state) => get(state, 'map.base') !== 'OSM',
  },
  {
    key: 'lang',
    path: 'locale.lang',
    conditionalDisplayFn: (state) => get(state, 'locale.lang') !== defaultAppLang,
  },
  {
    key: 'widget',
    path: 'layout.panels.right',
    conditionalDisplayFn: (state) => get(state, 'layout.panels.right') === 'closed',
  },
  {
    key: 'model',
    path: 'scenarios.model.name',
  },
];

const legacyValues = [
  { key: 'mapmode', old: 'model', new: MP_MODEL_STA_VIEWER }, // map mode is now specific to model type
  { key: 'mapmode', old: 'm_comparison', new: MP_MODEL_STA_COMPARISON },
  { key: 'mapmode', old: 'h_comparison', new: MP_HISTORICAL_COMPARISON },
  { key: 'widget', old: 'open', new: 'opened' }, // legacy redirect link - issue TraMod-947
  { key: 'model', old: 'DTA', new: 'dtm_pilsen' }, // model query used to contain only the model type, now it is the traffic model name
  { key: 'mapmode', old: MP_HISTORICAL_AGGREGATION, new: MP_MODEL_STA_COMPARISON }, // this is not exactly legacy, but if anyone tries to access aggregation mapMode via url -> replace with default comparison mapMode since aggregation can only be active with opened aggregation widget
  { key: 'mapmode', old: MP_H_AGGREGATION_COMPARISON, new: MP_MODEL_STA_COMPARISON }, // same as above
  { key: 'mapmode', old: MP_MODEL_STA_AGGREGATION, new: MP_MODEL_STA_COMPARISON }, // same as above
  { key: 'mapmode', old: MP_MODEL_STA_AGGREGATION_COMPARISON, new: MP_MODEL_STA_COMPARISON }, // same as above
  { key: 'mapmode', old: MP_SHORTEST_VIEW, new: MP_MODEL_STA_VIEWER }, // shortest path map modes are also only relevant with opened shortest path widget
  { key: 'mapmode', old: MP_SHORTEST_SELECT, new: MP_MODEL_STA_VIEWER }, // same as above
];

export const queryAffectingMutations = [
  'layout/SET_PANEL_STATE',
  'locale/SET_LANG',
  'map/SET_MAP_MODE',
  'map/SET_CALENDAR',
  'map/SET_ITEM',
  'map/SET_COMPARISON',
  'map/SET_CENTER',
  'map/SET_ZOOM',
  'map/SET_BASE',
  'scenarios/SET_MODEL',
];

export const getSynchronizedModuleState = ({ module, state }: { module: string; state: TmStoreState }) => {
  const query = _getUrlQueryParams();
  query.forEach((val, key) => {
    const expectedParam = expectedQueryParams.find((qp) => qp.key == key);
    if (!expectedParam) return; // ignore unexpected query params

    const statePath = _getStatePath({ expectedParam, module });
    if (!statePath) return; // ignore query params not relevant to the module

    const paramValue = _parseParamValue({ expectedParam, query });
    set(state, statePath, paramValue);
  });

  return state;
};

export const synchronizeUrlWithModuleState = ({ state, router }: { state: TmStoreState; router: Router }) => {
  const oldQuery = { ...router.currentRoute.value.query }; // don't update router query directly, just make copy and update via .replace() to properly propagate URL change
  const newQuery: { [key: string]: any } = {};

  expectedQueryParams.forEach((expectedParam) => {
    const statePath = _getStatePath({ expectedParam });
    if (!statePath) return;
    const shouldDisplay = _shouldBeDisplayedQuery({ expectedParam, state });
    let value = get(state, statePath, null);
    if (value && expectedParam.stateTransformFn) value = expectedParam.stateTransformFn(value, state);
    if (value && shouldDisplay) newQuery[expectedParam.key] = value;
    else delete oldQuery[expectedParam.key];
  });

  const query = { ...oldQuery, ...newQuery };
  router.replace({ query });
};

export { getDataModeByMapMode };

export const getCalendarDate = ({
  calendarState,
  mapMode,
  modelType,
}: {
  calendarState: TmStoreState;
  mapMode: TmMapMode;
  modelType: TmModelType;
}): TmCalendarDate => {
  const calendarMapMode = _getCalendarMapMode({ mapMode, modelType });
  const calendar = calendarState.find((c: TmStoreState) => c.mapMode == calendarMapMode && c.modelType == modelType);
  return calendar ? calendar.date : _getDefaultCalendarDate({ mapMode: calendarMapMode });
};

export const updateCalendarDate = ({
  calendarState,
  date,
  mapMode,
  modelType,
}: {
  calendarState: TmStoreState;
  date: TmDate;
  mapMode: TmMapMode;
  modelType: TmModelType;
}) => {
  const calendarMapMode = _getCalendarMapMode({ mapMode, modelType });
  const stateIndex = calendarState.findIndex(
    (i: TmStoreState) => i.mapMode == calendarMapMode && i.modelType == modelType,
  );
  if (stateIndex >= 0) calendarState[stateIndex].date = date;
  else calendarState.push({ date, mapMode: calendarMapMode, modelType });
};

export const getWidgetStateIndex = ({
  widgetsState,
  name,
  dataMode,
  modelType,
}: {
  widgetsState: TmStoreState;
  name: string;
  dataMode: string;
  modelType: TmModelType;
}) => {
  return widgetsState.findIndex(
    (w: TmStoreState) => w.name == name && w.dataMode == dataMode && w.modelType == modelType,
  );
};

const _getStatePath = ({
  expectedParam,
  module = null,
}: {
  expectedParam: ExpectedQueryParam;
  module?: string | null;
}) => {
  let path = expectedParam.path;
  if (module) {
    const [moduleMismatch, modulePath] = path.split(`${module}.`);
    if (moduleMismatch) return null;
    path = modulePath;
  }

  return path;
};

const _parseParamValue = ({ expectedParam, query }: { expectedParam: ExpectedQueryParam; query: URLSearchParams }) => {
  let value: any = query.getAll(expectedParam.key);
  if (expectedParam.valueType) value = value.map(expectedParam.valueType); // re-type values
  if (!expectedParam.isArray && value.length === 1) value = value[0]; // extract non-array value
  const legacyVal = legacyValues.find((lv) => lv.key == expectedParam.key && lv.old == value);
  if (legacyVal) value = legacyVal.new; // replace legacy value
  if (expectedParam.queryTransformFn) value = expectedParam.queryTransformFn(value, query);

  return value;
};

const _getUrlQueryParams = () => {
  // get query from window since store initializes before router
  const query = window.location.search;
  return new URLSearchParams(query);
};

const _shouldBeDisplayedQuery = ({
  expectedParam,
  state,
}: {
  expectedParam: ExpectedQueryParam;
  state: TmStoreState;
}) => {
  const { conditionalDisplayFn } = expectedParam;
  if (!conditionalDisplayFn) return true;
  return conditionalDisplayFn(state);
};

const _getDefaultAppLang = (): TmLang => {
  const enabledLangs = import.meta.env.VITE_ENABLED_LANGUAGES?.split(', ') || [];
  const defaultLocale = enabledLangs[0] || 'cs';

  const availableBrowserLangs = navigator.languages;
  if (!availableBrowserLangs) return defaultLocale;

  const formatLang = (l: string) => l.split('-')[0];
  const preferredBrowserLang = availableBrowserLangs.find((lang) => enabledLangs.includes(formatLang(lang)));
  if (!preferredBrowserLang) return defaultLocale;

  return formatLang(preferredBrowserLang);
};

const _getCalendarMapMode = ({ mapMode, modelType }: { mapMode: TmMapMode; modelType: TmModelType }) => {
  // following map modes are considered as just an alias of another map mode for the purpose of storing/retrieving calendar date
  if (INTERACTION_MAP_MODES.includes(mapMode)) return getModelModeByModelType(modelType);
  if ([MP_SHORTEST_SELECT, MP_SHORTEST_VIEW].includes(mapMode)) return MP_MODEL_STA_VIEWER; // map modes for selecting and displaying shortest path share date with basic sta model view
  if (MP_LINK_EXPORT_SELECT === mapMode) return MP_HISTORICAL_VIEWER; // map mode for link selection in historicalExport widget shares date with historical view
  if (MP_LINK_EXPORT_SELECT_AGGREGATION === mapMode) return MP_HISTORICAL_AGGREGATION; // map mode for aggregated link selection in historicalExport widget shares date with historical aggregation view
  return mapMode;
};

const _getDefaultCalendarDate = ({ mapMode }: { mapMode: TmMapMode }): TmCalendarDate => {
  const isComparison = COMPARISON_MAP_MODES.includes(mapMode);
  const isAggregation = AGGREGATION_MAP_MODES.includes(mapMode);
  const isDta = [MP_MODEL_DTA_VIEWER, MP_MODEL_DTA_COMPARISON].includes(mapMode);
  // return special default dates based on specific mapMode
  if (isAggregation && isComparison) return [null, null, null, null]; // [sourceDateFrom, sourceDateTo, targetDateFrom, targetDateTo]
  if (isAggregation) return [null, null]; // [sourceDate, targetDate]
  if (isDta) return getModelMonday({ hour: 7 }); // DTA specific 'model' date
  // in every other case - the actual current date is returned
  const currentDate = today({ stripMins: true }); // calendar component only allows to set hours, so it is not desired to display them
  if (isComparison) return [currentDate, currentDate]; // [dateFrom, dateTo]
  return currentDate;
};

export const defaultAppLang = _getDefaultAppLang();
