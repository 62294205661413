import MapLayout from '@layouts/Map.vue';
import { getScenarioItemIdsFromRouteParams } from '@utils/route-param';
import type { RouteRecordRaw } from 'vue-router';

const ScenarioListView = () => import('@views/scenarios/ScenarioList.vue');
const ScenarioCreateView = () => import('@views/scenarios/ScenarioCreate.vue');
const ScenarioShareView = () => import('@views/scenarios/ScenarioShare.vue');
const EventListView = () => import('@views/scenarios/EventList.vue');
const EventCreateView = () => import('@views/scenarios/EventCreate.vue');
const EventImportView = () => import('@views/scenarios/EventImport.vue');
const ModificationListView = () => import('@views/scenarios/ModificationList.vue');
const ModificationCreateEditView = () => import('@views/scenarios/ModificationCreateEdit.vue');

const routes: RouteRecordRaw[] = [
  {
    path: '/scenarios',
    name: 'scenarios',
    component: ScenarioListView,
    meta: {
      requiresAuth: false,
      layout: MapLayout,
    },
  },
  {
    path: '/scenarios/new',
    name: 'scenarios.create',
    component: ScenarioCreateView,
    meta: {
      requiresAuth: true,
      layout: MapLayout,
    },
  },
  {
    path: '/scenarios/:id/share',
    name: 'scenarios.share',
    component: ScenarioShareView,
    meta: {
      requiresAuth: true,
      layout: MapLayout,
    },
    props: ({ params }) => getScenarioItemIdsFromRouteParams(params),
  },
  {
    path: '/scenarios/:id/import',
    name: 'scenarios.events.import',
    component: EventImportView,
    meta: {
      requiresAuth: true,
      layout: MapLayout,
    },
    props: ({ params }) => getScenarioItemIdsFromRouteParams(params),
  },
  {
    path: '/scenarios/:id/events',
    name: 'scenarios.events',
    component: EventListView,
    meta: {
      requiresAuth: false,
      layout: MapLayout,
    },
    props: ({ params }) => getScenarioItemIdsFromRouteParams(params),
  },
  {
    path: '/scenarios/:id/events/new',
    name: 'scenarios.events.create',
    component: EventCreateView,
    meta: {
      requiresAuth: true,
      layout: MapLayout,
    },
    props: ({ params }) => getScenarioItemIdsFromRouteParams(params),
  },
  {
    path: '/scenarios/:id/events/:evId/modifications',
    name: 'scenarios.events.modifications',
    component: ModificationListView,
    meta: {
      requiresAuth: false,
      layout: MapLayout,
    },
    props: ({ params }) => getScenarioItemIdsFromRouteParams(params),
  },
  {
    path: '/scenarios/:id/events/:evId/modifications/:modType/:modMode/new',
    name: 'scenarios.events.modifications.create',
    component: ModificationCreateEditView,
    meta: {
      requiresAuth: true,
      layout: MapLayout,
    },
    props: ({ params }) => ({
      ...getScenarioItemIdsFromRouteParams(params),
      modType: params.modType as TmModificationType,
      modMode: params.modMode as TmModificationMode,
    }),
  },
  {
    path: '/scenarios/:id/events/:evId/modifications/:modType/:modMode/:modId',
    name: 'scenarios.events.modifications.edit',
    component: ModificationCreateEditView,
    meta: {
      requiresAuth: true,
      layout: MapLayout,
    },
    props: ({ params }) => ({
      ...getScenarioItemIdsFromRouteParams(params),
      modType: params.modType as TmModificationType,
      modMode: params.modMode as TmModificationMode,
    }),
  },
];

export default routes;
