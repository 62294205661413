<template>
  <aside :class="{ inactive: mapPanning }">
    <!-- Left panel with router view -->
    <tm-side-panel stick-side="left" @mouseenter="unfoldPanels">
      <!-- router view -->
      <slot />
    </tm-side-panel>

    <!-- Right panel with widgets -->
    <tm-side-panel v-show="!isWidgetPanelClosed" stick-side="right" @mouseenter="unfoldPanels" />

    <!-- overlay loader -->
    <prime-block-ui :blocked="spinnerLoading" :full-screen="true" />
    <prime-progress-spinner v-if="spinnerLoading" animation-duration="2s" stroke-width="4" class="overlayspinner" />
    <!-- content loader -->
    <prime-progress-bar v-if="barLoading" mode="indeterminate" class="progressbar" />

    <!-- alert messages -->
    <prime-toast position="bottom-center" />

    <!-- confirmation dialog -->
    <prime-confirm-dialog />

    <!-- custom tm dialog -->
    <tm-dialog />
  </aside>

  <header :class="{ inactive: mapPanning }">
    <tm-navigation @mouseenter="unfoldPanels" />
  </header>

  <main>
    <tm-map
      class="map"
      @mousedown="
        mapPanning = true;
        foldPanels();
      "
      @mouseup="mapPanning = false"
    ></tm-map>
  </main>

  <footer />
</template>

<script setup lang="ts">
import PrimeToast from 'primevue/toast';
import PrimeConfirmDialog from 'primevue/confirmdialog';
import PrimeBlockUi from 'primevue/blockui';
import PrimeProgressSpinner from 'primevue/progressspinner';
import PrimeProgressBar from 'primevue/progressbar';
import TmDialog from '@components/layouts/Dialog.vue';
import TmNavigation from '@components/layouts/Navigation.vue';
import TmSidePanel from '@components/layouts/SidePanel.vue';
import TmMap from '@components/map/Map.vue';
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import type { Ref } from 'vue';

const store = useStore();

const mapPanning = ref(false);
const foldPanels: () => void = () => store.dispatch('layout/foldPanels');
const unfoldPanels: () => void = () => store.dispatch('layout/unfoldPanels');

const spinnerLoading: Ref<boolean> = computed(() => store.getters['layout/isLoading']({ type: 'spinner' }));
const barLoading: Ref<boolean> = computed(() => store.getters['layout/isLoading']());
const isWidgetPanelClosed = computed(() => store.state.layout.panels.right === 'closed');
</script>

<style scoped>
:deep(.map) {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
}
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: block;
  padding: 1rem;
}
.overlayspinner {
  width: 5rem;
  height: 5rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
}
.progressbar {
  height: 0.5rem;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}
.panel.folded,
.panel.opened,
header {
  pointer-events: none;
}
.panel.folded :deep(.p-card),
.panel.opened :deep(.p-card),
.panel :deep(.panel-content),
header :deep(nav .interactive) {
  pointer-events: auto;
}
.inactive,
.inactive :deep(.p-card),
.inactive :deep(.interactive),
.inactive :deep(.panel-content) {
  pointer-events: none !important;
}
</style>
