import type { I18nOptions } from 'vue-i18n';

const datetimeFormats: I18nOptions['datetimeFormats'] = {
  cs: {
    default: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    short: {
      month: 'short',
      day: 'numeric',
    },
    full: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    },
    hour: {
      hour: 'numeric',
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
    },
    datetime: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  en: {
    default: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    short: {
      month: 'short',
      day: 'numeric',
    },
    full: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    },
    hour: {
      hour: 'numeric',
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
    },
    datetime: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
};

export default datetimeFormats;
