<template>
  <prime-input-switch v-model="checked" :disabled="disabled" />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import PrimeInputSwitch from 'primevue/inputswitch';

const props = withDefaults(
  defineProps<{
    modelValue: boolean; // checked state
    disabled?: boolean; // disabled state
  }>(),
  {
    disabled: false,
  },
);

const emit = defineEmits<{
  'update:modelValue': [checked: boolean];
}>();

const checked = computed({
  get: () => props.modelValue,
  set: (state) => emit('update:modelValue', state),
});
</script>

<style scoped></style>
