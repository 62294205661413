import useAxios from '@composables/useAxios';
import { useStore } from 'vuex';
import useToast from '@composables/useToast';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useAppRefresh } from '@composables/useCommonHelpers';
import type { TmToastSeverity } from '@composables/useToast';

const API_HOST = import.meta.env.VITE_API_HOST;

type TmMessage = string | TmMessageBody | TmMessageByCode;

type TmMessageByCode = {
  default?: string | TmMessageBody;
  [key: string]: string | TmMessageBody | null | undefined;
  [key: number]: string | TmMessageBody | null | undefined;
};

type TmMessageBody = {
  severity: TmToastSeverity;
  summary: string;
  detail?: string;
};

export default function useMainApiRequest() {
  const store = useStore();
  const { addToast } = useToast();
  const router = useRouter();
  const { confirmAppRefreshDueToModelUpdate } = useAppRefresh();
  const { t, d } = useI18n();

  async function makeRequest({
    url,
    method,
    data = {},
    params = {},
    message = { error: null, success: null },
    onSuccess = null,
    onFailure = null,
    withCredentials = true,
    timeout = 1000 * 5, // 5 seconds
  }: {
    url: string;
    method: 'post' | 'get' | 'patch' | 'delete';
    data?: any;
    params?: any;
    message?: { success?: null | TmMessage; error?: null | TmMessage };
    onSuccess?: ((arg: any) => Promise<any> | any) | null;
    onFailure?: ((arg: any) => Promise<any> | any) | null;
    withCredentials?: boolean;
    timeout?: number;
  }) {
    const { exec, response, hasError } = useAxios(); // separate response and error for each request call
    store.dispatch('layout/startLoading');
    await exec({ url: API_HOST + url, method, data, params, withCredentials, timeout });
    store.dispatch('layout/finishLoading');

    if (!response.value) throw new Error('Encountered unexpected response');
    _handleTrafficModelResponse(response.value);
    _handleEditSessionResponse(response.value, url);

    if (!hasError.value) {
      if (message.success) _displaySuccessMessage(message.success, response.value);
      if (typeof onSuccess === 'function') await onSuccess(response.value.data);
    } else {
      if (message.error) _displayErrorMessage(message.error, response.value);
      if (typeof onFailure === 'function') await onFailure(response.value);
      _logoutAfter401(response.value.status, url);
      _exitExpiredEditMode(url, response.value.code);
    }
  }

  function _displaySuccessMessage(clientMessage: TmMessage, response: TmApiResponse) {
    const { status, code } = response;
    const parsedMessage = _parseMessage(clientMessage, status, code);
    if (parsedMessage === null) return; // null represents the intention to show no message (unlike undefined)

    addToast({
      severity: _parseSeverity(parsedMessage) || 'success',
      summary: _parseSummary(parsedMessage),
      detail: _parseDetail(parsedMessage),
    });
  }

  function _displayErrorMessage(clientMessage: TmMessage, response: TmApiResponse) {
    const { message, status, code, data } = response;
    const parsedMessage = _parseMessage(clientMessage, status, code);
    if (parsedMessage === null) return; // null represents the intention to show no message (unlike undefined)

    addToast({
      severity: _parseSeverity(parsedMessage) || 'error',
      summary: _parseSummary(parsedMessage),
      detail:
        _parseDetail(parsedMessage) || _formatErrorMessage(code, data) || _formatServerResponse(status, code, message),
    });
  }

  function _logoutAfter401(statusCode: number, url: string) {
    if (statusCode === 401 && url !== 'auth/login') {
      store.dispatch('auth/logout');
      addToast({ severity: 'info', summary: t('users.session expired') });
      // TODO: maybe show login dialog instead to enable continuing with unfinished work
      // TODO: wait for management decision
      const currentRouteName = router.currentRoute.value.name as string;
      const nextRouteName = currentRouteName.startsWith('admin.') ? 'admin.login' : 'user.login';
      router.push({ name: nextRouteName });
    }
  }

  function _exitExpiredEditMode(url: string, errCode?: string) {
    if (errCode !== 'EDIT_NOT_FOUND') return;
    const scenarioId = _getScenarioIdFromUrl(url);
    store.dispatch('scenarios/exitEditMode', { scenarioId });
  }

  function _getScenarioIdFromUrl(url: string) {
    return url.split('scenarios/')?.pop()?.split('/')[0] || null;
  }

  function _handleTrafficModelResponse(response: TmApiResponse) {
    const trafficModel = response.data?.trafficModel;
    if (!trafficModel) return;
    store.commit('scenarios/SET_MODEL', { isValid: trafficModel.isValid, isBroken: trafficModel.isBroken });
    if (!trafficModel.isValid) return;
    const currentModelVersion = store.state.scenarios.model.timestamp;
    const newModelVersion = trafficModel.timestamp;
    if (!newModelVersion || currentModelVersion == newModelVersion || currentModelVersion === undefined) return;
    // new model is ready -> reload layers via app refresh  // TODO: reload layers without app refresh
    confirmAppRefreshDueToModelUpdate(trafficModel);
  }

  function _handleEditSessionResponse(response: TmApiResponse, url: string) {
    const editSession = response.data?.editSession;
    if (!editSession) return;

    const scenarioId = _getScenarioIdFromUrl(url);
    if (!scenarioId) return;

    // set progress of the corresponding scenario
    store.dispatch('scenarios/updateScenarioProgress', { scenarioId, editSession });
  }

  function _parseMessage(message: TmMessage, statusCode: number, customCode?: string) {
    const isMessageBody = (m: any): m is TmMessageBody => !!m.severity;

    if (typeof message === 'string') return message;
    if (isMessageBody(message)) return message;
    if (customCode && typeof message[customCode] !== 'undefined') return message[customCode];
    if (statusCode && typeof message[statusCode] !== 'undefined') return message[statusCode];
    return message.default;
  }

  function _parseSeverity(message: TmMessageBody | string | undefined) {
    if (!message) return;
    if (typeof message === 'string') return;
    return message.severity;
  }

  function _parseDetail(message: TmMessageBody | string | undefined) {
    if (!message) return;
    if (typeof message === 'string') return;
    return message.detail;
  }

  function _parseSummary(message: TmMessageBody | string | undefined) {
    if (!message) return;
    if (typeof message === 'string') return message;
    return message.summary;
  }

  function _formatErrorMessage(code?: string, data?: any) {
    if (!code || !data) return;
    let formattedError;

    switch (code) {
      case 'DEPENDENCIES_ERROR':
        formattedError = _formatDependencyError(data, t, d);
        break;
      default:
        formattedError = typeof data === 'string' ? t(`errors.${data}`) : null;
    }

    return formattedError;
  }

  function _formatServerResponse(status: number, code?: string, message?: unknown) {
    if (!code || !message) return;
    let formattedResponse;

    switch (`${code}-${status}`) {
      case 'EDIT_NOT_FOUND-404':
        formattedResponse = t(`errors.edit session expired`);
        break;
      case 'AUTHENTICATION_INVALID-401':
        formattedResponse = t(`errors.not authorized`);
        break;
      case 'NOT_AUTHORIZED-403':
        formattedResponse = t(`errors.not authorized`);
        break;
      case 'INPUT_INVALID-400':
        formattedResponse = _formatInvalidInputServerError(message, t);
        break;
      default:
        formattedResponse = JSON.stringify(message);
    }

    return formattedResponse;
  }

  return { makeRequest, checkIds: _checkRequiredItemIds };
}

// TODO: maybe move custom formatting functions to separate file (if there will ever be more then one)
function _formatDependencyError(
  dependentMods: { id: string; dependency: 'parent' | 'child'; dateFrom: TmDate; dateTo: TmDate }[],
  t: (phrase: string) => string,
  d: (date: TmDate, phrase: string) => string,
) {
  const depModIds: string[] = [];
  let minDateFrom: TmDate = null;
  let maxDateTo: TmDate = null;
  let maxDateFrom: TmDate = null;
  let minDateTo: TmDate = null;

  dependentMods.forEach((mod) => {
    depModIds.push(mod.id);

    if (mod.dependency === 'parent') {
      minDateFrom = !minDateFrom || (mod.dateFrom || 0) > minDateFrom ? mod.dateFrom : minDateFrom;
      maxDateTo = !maxDateTo || (mod.dateTo || 0) < maxDateTo ? mod.dateTo : maxDateTo;
    }

    if (mod.dependency === 'child') {
      maxDateFrom = !maxDateFrom || (mod.dateFrom || 0) < maxDateFrom ? mod.dateFrom : maxDateFrom;
      minDateTo = !minDateTo || (mod.dateTo || 0) > minDateTo ? mod.dateTo : minDateTo;
    }
  });

  let dateLimits = '';
  if (minDateFrom) dateLimits += `${t('errors.minimal date from')}: ${d(minDateFrom, 'full')} \n`;
  if (maxDateTo) dateLimits += `${t('errors.maximal date to')}: ${d(maxDateTo, 'full')} \n`;
  if (maxDateFrom) dateLimits += `${t('errors.maximal date from')}: ${d(maxDateFrom, 'full')} \n`;
  if (minDateTo) dateLimits += `${t('errors.minimal date to')}: ${d(minDateTo, 'full')} \n`;

  return `${t('errors.dependent modifications')}: ${depModIds.join(',')} \n ${dateLimits}`;
}

function _formatInvalidInputServerError(message: unknown, t: (phrase: string) => string) {
  const inputErrorMessageArray = message as { msg: string }[];
  const inputErrorMsg = inputErrorMessageArray[0]?.msg;

  switch (inputErrorMsg) {
    case 'One of speed,capacity has to be defined':
      return t('errors.edit at least one parameter');
    case 'E-mail must be valid and unique.':
      return t('errors.email must be unique');
    default:
      return JSON.stringify(message);
  }
}

function _checkRequiredItemIds(itemIds: (number | undefined)[] = []) {
  const checkedIds: number[] = [];
  itemIds.forEach((id) => {
    if (!id) throw new Error('Missing required item ID');
    checkedIds.push(id);
  });
  return checkedIds;
}
