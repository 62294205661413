import type { App, Component } from 'vue';
// custom TM components
import TmButton from '@components/core/Button.vue';
import TmCalendar from '@components/core/Calendar.vue';
import TmInput from '@components/core/Input.vue';
import TmTextarea from '@components/core/Textarea.vue';
import TmBreadcrumb from '@components/core/Breadcrumb.vue';
import TmSlider from '@components/core/Slider.vue';
import TmSelection from '@components/core/Selection.vue';
import TmSplitButton from '@components/core/SplitButton.vue';
import TmSwitch from '@components/core/Switch.vue';
import TmDivider from '@components/core/Divider.vue';
import TmInputSelector from '@components/core/InputSelector.vue';
import TmFieldset from '@components/core/Fieldset.vue';
import TmDateRange from '@components/core/DateRange.vue';
import TmHeading from '@components/core/Heading.vue';
import TmCard from '@components/core/Card.vue';
import TmTabs from '@components/core/Tabs.vue';
import TmTabPanel from '@components/core/TabPanel.vue';
// PrimeVue components
import PrimeSkeleton from 'primevue/skeleton';

export const globalComponentList: [string, Component][] = [
  ['TmButton', TmButton],
  ['TmCalendar', TmCalendar],
  ['TmInput', TmInput],
  ['TmTextarea', TmTextarea],
  ['TmBreadcrumb', TmBreadcrumb],
  ['TmSlider', TmSlider],
  ['TmSelection', TmSelection],
  ['TmSplitButton', TmSplitButton],
  ['TmSwitch', TmSwitch],
  ['TmDivider', TmDivider],
  ['TmInputSelector', TmInputSelector],
  ['TmFieldset', TmFieldset],
  ['TmDateRange', TmDateRange],
  ['TmHeading', TmHeading],
  ['TmCard', TmCard],
  ['TmTabs', TmTabs],
  ['TmTabPanel', TmTabPanel],
  ['PrimeSkeleton', PrimeSkeleton],
];

export default function registerGlobalComponents(app: App) {
  for (const [name, component] of globalComponentList) {
    app.component(name, component); // TS_TODO what about TS typings?
  }
}
