import { ref } from 'vue';
import type { Ref, Component } from 'vue';

// Dialog object type
type Dialog = {
  type?: 'timeline' | 'dependencies' | 'unfinished' | 'user' | 'exports' | 'images';
  message?: string;
  header?: string;
  component?: Component;
  modal?: boolean;
  dismissable?: boolean;
  showHeader?: boolean;
  showMaximized?: boolean;
  data?: any;
  callback?: {
    onConfirm?: (arg: any) => Promise<void> | void;
    onCompute?: (arg: any) => Promise<void> | void;
    onSave?: (arg: any) => Promise<void> | void;
    onLeave?: (arg: any) => Promise<void> | void;
    onCancel?: (arg: any) => Promise<void> | void;
    onEventClick?: (tEv: TmTimelineEvent) => Promise<void> | void;
    onModificationClick?: (tEv: TmTimelineEvent) => Promise<void> | void;
    onImageSelection?: (img: string) => Promise<void> | void;
  };
};

// global refs
const displayed = ref<boolean>(false);
const dialog = ref<Dialog>({});

export default function useDialog(): {
  dialog: Ref<Dialog>;
  displayed: Ref<boolean>;
  show: (dialogOptions: Dialog) => void;
  close: () => void;
} {
  const show = (dialogOptions: Dialog): void => {
    dialog.value = dialogOptions;
    displayed.value = true;
  };

  const close = (): void => {
    dialog.value = {};
    displayed.value = false;
  };

  return {
    dialog,
    displayed,
    show,
    close,
  };
}
