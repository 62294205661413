// TODO Review placement of these helpers
import { onUnmounted, onMounted, ref, watch, shallowRef, watchEffect, readonly } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useConfirm } from 'primevue/useconfirm';
import useToast from '@composables/useToast';
import { getModelModeByModelType } from '@keys/index';
import { parseRouteParamAsNumber } from '@utils/route-param';
import type { ComputedGetter } from 'vue';

// SetMapMode helper, intended to use in component setups where store&router are otherwise unused
export function useSetMapMode({
  formerModeOnUnmount = true,
  setOnMount,
}: { formerModeOnUnmount?: boolean; setOnMount?: string } = {}) {
  const store = useStore();
  const setMapMode = (mode: TmMapMode) => store.dispatch('map/setMapMode', { mode });

  if (setOnMount) onMounted(() => setMapMode(setOnMount));

  if (formerModeOnUnmount) {
    const modelType: TmModelType = store.state.scenarios.model.type;
    const formerMode = getModelModeByModelType(modelType);
    onUnmounted(() => setMapMode(formerMode));
  }

  return { setMapMode };
}

// TS_TODO: the entire function
export function useInteractionDataEvaluator({
  props,
  fields = [],
  refreshStateFn,
  dataSources,
  validatorFn,
}: any = {}) {
  const { addToast } = useToast();
  const { t } = useI18n();
  const isValid = ref(true);
  const isInitialized = ref(false);
  // Flag to save if current instance was valid on initialization, if so its not known to enter invalid state, while initially invalid can be used again with revert changes
  const onceInvalid = ref(false);

  const setInvalid = () => (isValid.value = false);

  const createWarning = (msg: string, msgData: any) =>
    !isInitialized.value &&
    addToast({
      severity: 'warn',
      summary: t(msg, msgData),
    });

  const createError = (msg: string, msgData?: any) =>
    !isInitialized.value &&
    addToast({
      severity: 'error',
      summary: t(msg, msgData),
      life: 8000,
    });

  const validateItem = ({ id, testFn, warningMsg }: any = {}) => {
    const isValid = testFn(id);
    if (!isValid) {
      if (warningMsg) createWarning(warningMsg, { id });
      setInvalid();
    }
  };

  const validateList = ({ items, testFn, warningMsg, onValidItem }: any = {}) => {
    const validItems = [];
    for (const item of items) {
      const isId = typeof item !== 'object';
      const id = isId ? item : item.id;
      const isValid = testFn(id);
      if (isValid) {
        validItems.push(item);
        if (onValidItem) onValidItem(item);
      } else {
        setInvalid();
        if (warningMsg) createWarning(warningMsg, isId ? { id } : item);
      }
    }
    return validItems;
  };

  const onValidationEnd = (onError: any, errorMsg: any) => {
    if (!isValid.value && errorMsg) createError(errorMsg);
    isInitialized.value = true;

    if (!isValid.value) {
      onceInvalid.value = true;
      if (onError) onError();
    } else refreshStateFn();
  };

  const validate = () => {
    isValid.value = true;
    validatorFn({ createError, createWarning, validateList, validateItem, onValidationEnd, isValid });
  };

  const watchFieldsArray = fields.map((field: string) => () => props[field]);
  const originalValues = JSON.stringify(watchFieldsArray.map((field: any) => field()));
  watch(watchFieldsArray, (values, olds) => {
    if (isInitialized.value && JSON.stringify(values) !== JSON.stringify(olds)) {
      if (onceInvalid.value && originalValues === JSON.stringify(values))
        validate(); // Revert changes called with originally invalid data
      else refreshStateFn();
    }
  });

  async function initInteraction() {
    await Promise.all(dataSources.map(async (source: any) => source.state.isLoading));

    if (fields.every((field: string) => !props[field] || (Array.isArray(props[field]) && props[field].length === 0))) {
      isInitialized.value = true;
      return refreshStateFn();
    }

    validate();
  }

  return { isValid, isInitialized, initInteraction };
}

export function useAppRefresh() {
  const store = useStore();
  const confirm = useConfirm();
  const { t } = useI18n();

  function confirmAppRefreshDueToModelUpdate({ timestamp }: Partial<TmTrafficModel>) {
    confirm.require({
      message: t('models.confirm layers refresh'),
      header: t('models.refreshing layers'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        store.commit('scenarios/SET_MODEL', { timestamp }); // update timestamp prior to refresh to prevent infinite reload in case some request is faster than models
        store.dispatch('refreshApp'); // app refresh will SET_MODEL automatically
      },
      reject: () => store.commit('scenarios/SET_MODEL', { isValid: false }),
    });
  }

  return { confirmAppRefreshDueToModelUpdate };
}

// custom eager computed in case we need to avoid expensive re-evaluations
export function eagerComputed<T>(effect: ComputedGetter<T>) {
  const holder = shallowRef<T>();
  watchEffect(
    () => {
      holder.value = effect();
    },
    { flush: 'sync' }, // needed for immediate updates
  );
  return readonly(holder);
}

export function useCurrentRouteParams() {
  const route = useRoute();

  return {
    getScenarioId: () => parseRouteParamAsNumber(route.params.id),
    getEventId: () => parseRouteParamAsNumber(route.params.evId),
    getModificationId: () => parseRouteParamAsNumber(route.params.modId),
  };
}
