import MapLayout from '@layouts/Map.vue';
import type { RouteRecordRaw } from 'vue-router';
// import store from '@store';

const SettingsView = () => import('@views/settings/Settings.vue');
const AboutView = () => import('@views/settings/About.vue');
const HelpView = () => import('@views/settings/Help.vue');
const ChangelogView = () => import('@views/settings/Changelog.vue');

const routes: RouteRecordRaw[] = [
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView,
    meta: {
      // requiresAuth: true,
      layout: MapLayout,
    },
    // authorization example - specific permission for specific route
    // beforeEnter: () => store.getters['auth/hasRole']('admin'),
  },
  {
    path: '/settings/about',
    name: 'settings.about',
    component: AboutView,
    meta: {
      layout: MapLayout,
    },
  },
  {
    path: '/settings/help',
    name: 'settings.help',
    component: HelpView,
    meta: {
      layout: MapLayout,
    },
  },
  {
    path: '/settings/changelog',
    name: 'settings.changelog',
    component: ChangelogView,
    meta: {
      layout: MapLayout,
    },
  },
];

export default routes;
