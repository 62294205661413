import { useI18n } from 'vue-i18n';
import * as XLSX from 'xlsx-js-style';
import useToast from '@composables/useToast';
import { saveAs } from 'file-saver';
// @ts-ignore // no type definitions exist for the geojson2shp package
import { GeoJson2Shp } from '@gis-js/geojson2shp';
import JSZip from 'jszip';
import type { FeatureCollection } from 'geojson';

export type XlsxCellStyle = {
  border: { top: { style: string }; bottom: { style: string }; left: { style: string }; right: { style: string } };
};
export type XlsxCell = { v: string | number; t: string; s: XlsxCellStyle };
export type CsvCell = { [key: string]: string | number };
export type MergeCellsRange = { s: { r: number; c: number }; e: { r: number; c: number } };
export type ColWidth = { wch: number };
export type FeaturesData = FeatureCollection & { crs: { type: string; properties: { name: string } } }; // GeoJSON standard no longer supports explicit specification of crs, so this is custom type to maintain compatibility

export default function useExport() {
  const { addToast } = useToast();
  const { t } = useI18n();
  const parseDate = (date: TmDate) => date?.replaceAll('. ', '.');

  const generateCsvContent = (data: CsvCell[]): string => {
    const csvRows = [];
    // csvRows.push('sep=|');
    const headers = Object.keys(data[0]);
    csvRows.push(headers.map((h) => `"${h}"`).join(','));
    for (const item of data) {
      const values = headers.map((h) => `"${item[h] || ''}"`);
      csvRows.push(values.join(','));
    }
    return csvRows.join('\n');
  };

  const generateXlsxContent = (
    data: XlsxCell[][],
    mergeCellsRanges: MergeCellsRange[] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 0 } }],
    colWidth: ColWidth[] = [],
  ): string => {
    const ws = XLSX.utils.aoa_to_sheet(data);
    // Add the merge to the worksheet
    ws['!merges'] = mergeCellsRanges;
    // Add col width
    ws['!cols'] = colWidth;
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    return XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  };

  const downloadAsCsv = (dataAsCsv: CsvCell[], exportDate: string, exportName: string) => {
    // datatable.value.exportCSV(); // CSV export can also be done using the Prime datatable method
    const fileName = `${exportName} - ${exportDate}.csv`;

    if (!dataAsCsv.length)
      return addToast({
        severity: 'error',
        summary: t('export.export canceled'),
        detail: t('export.no data for export'),
      });
    const csvContent = generateCsvContent(dataAsCsv);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, fileName);
  };

  const downloadAsXlsx = ({
    xlsxData,
    exportDate,
    exportName,
    mergeCellsRanges,
    colWidth,
  }: {
    xlsxData: XlsxCell[][];
    exportDate: string;
    exportName: string;
    mergeCellsRanges?: MergeCellsRange[];
    colWidth?: ColWidth[];
  }) => {
    const fileName = `${exportName} - ${exportDate}.xlsx`;
    if (!xlsxData.length)
      return addToast({
        severity: 'error',
        summary: t('export.export canceled'),
        detail: t('export.no data for export'),
      });
    const xlsxContent = generateXlsxContent(xlsxData, mergeCellsRanges, colWidth);
    const blob = new Blob([xlsxContent], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
  };

  const downloadFeaturesAsGeojson = async (data: FeaturesData, outputFileName: string) => {
    const json = JSON.stringify(data);
    const blob = new Blob([json], { type: 'application/geo+json' });
    saveAs(blob, `${outputFileName}.geojson`);
  };

  const downloadFeaturesAsShp = async (data: FeaturesData, outputFileName: string) => {
    const cpg = 'UTF-8';
    const projection =
      'GEOGCS["GCS_S_JTSK",DATUM["D_S_JTSK",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]]';
    const zip = new JSZip();
    const layers = zip.folder('layers');
    const g2s = new GeoJson2Shp(data);
    if (!layers) return addToast({ severity: 'error', summary: t('export.export canceled') });
    const writeToFile = (fileName: string) => (err: any, files: any) => {
      layers.file(`${fileName}.shp`, files.shp.buffer, { binary: true });
      layers.file(`${fileName}.shx`, files.shx.buffer, { binary: true });
      layers.file(`${fileName}.dbf`, files.dbf.buffer, { binary: true });
      layers.file(`${fileName}.prj`, projection);
      layers.file(`${fileName}.cpg`, cpg);
    };
    await g2s.writePoint(writeToFile('points')); // write all features with point geometry if any
    await g2s.writePolyline(writeToFile('lines')); // write all features with line geometry if any
    const blob = await zip.generateAsync({ type: 'blob' });
    saveAs(blob, `${outputFileName}.zip`);
  };

  return {
    generateCsvContent,
    generateXlsxContent,
    downloadAsCsv,
    downloadAsXlsx,
    parseDate,
    downloadFeaturesAsGeojson,
    downloadFeaturesAsShp,
  };
}
