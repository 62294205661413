<template>
  <div class="content">
    <tm-divider class="p-mb-3 p-mt-0" />

    <div class="p-pl-1 p-pb-3">
      <strong>{{ activeScenario.name }}</strong>
      {{ ' - ' + $t('exports.traffic restrictions on date') }}
      <strong>{{ getFormattedDate(calendarDate) }}</strong>
    </div>

    <prime-data-table ref="datatable" :value="currentEvents" :striped-rows="true" scrollable scroll-height="flex">
      <prime-column field="name" sortable :header="$t('exports.event name')"></prime-column>
      <prime-column
        field="description"
        :header="$t('exports.event description')"
        :style="`width:${shouldDisplayExternalId ? 40 : 55}%;`"
      >
        <template #body="{ data }">
          <div class="desc-text">{{ data.description }}</div>
        </template>
      </prime-column>
      <prime-column field="dateFrom" sortable :header="$t('exports.date from')">
        <template #body="{ data }">
          <span>{{ $tmdate.formatScenarioDate(data.dateFrom, 'datetime', false) }}</span>
        </template>
      </prime-column>
      <prime-column field="dateTo" sortable :header="$t('exports.date to')">
        <template #body="{ data }">
          <span>{{ $tmdate.formatScenarioDate(data.dateTo, 'datetime', false) }}</span>
        </template>
      </prime-column>
      <prime-column v-if="shouldDisplayExternalId" field="externalId" sortable header="ID_SUPERDIO">
        <template #body="{ data }">
          <span>{{ data.externalId }}</span>
          <tm-button
            v-if="data.externalId"
            type="action"
            icon="pi-link"
            class="p-ml-3"
            :tooltip="$t('scenarios.open link')"
            @click="goToExternalLink(data.externalId)"
          />
        </template>
      </prime-column>
    </prime-data-table>
  </div>

  <div class="actions p-d-flex p-jc-center p-mb-1">
    <tm-selection v-model:selected="downloadAs" :options="['CSV', 'XLSX']" class="p-mr-3" type="dropdown" />
    <tm-button
      type="create"
      icon="ri-download-2-line"
      :disabled="!currentEvents.length"
      :tooltip="$t('exports.csv export')"
      @click="downloadActiveElements()"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, VNode } from 'vue';
import PrimeDataTable from 'primevue/datatable';
import PrimeColumn from 'primevue/column';
import { useStore } from 'vuex';
import useFullScenario from '@composables/useFullScenario';
import { formatScenarioDate, formatScenarioDatePeriod, today } from '@utils/tm-date';
import { useI18n } from 'vue-i18n';
import useExport, { CsvCell, XlsxCell, XlsxCellStyle } from '@composables/useExport';

const store = useStore();
const { activeScenario, getActiveEventsOnDate } = useFullScenario();
const { t, d } = useI18n();
const eventExternalLink = import.meta.env.VITE_EVENT_EXTERNAL_LINK;
const { downloadAsCsv, downloadAsXlsx } = useExport();

const datatable = ref<VNode>();
const calendarDate = computed<TmCalendarDate>(() => store.getters['map/getCalendarDate']());
const currentEvents = computed<TmEvent[]>(() => getActiveEventsOnDate(calendarDate.value));
const downloadAs = ref('CSV');
const shouldDisplayExternalId = computed(() => eventExternalLink && store.getters['auth/isLogged']);
const eventsDate = computed(() => getFormattedDate(calendarDate.value));

const downloadActiveElements = async () => {
  const exportName = activeScenario.value.name || 'Export';
  if (downloadAs.value === 'XLSX') {
    const xlsxData = getEventDataAsXlsx();
    const mergeCellsRanges = [{ s: { r: 0, c: 1 }, e: { r: 0, c: shouldDisplayExternalId.value ? 4 : 3 } }];
    const colWidth = [
      { wch: 30 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
      ...(shouldDisplayExternalId.value ? [{ wch: 25 }] : []),
    ];
    downloadAsXlsx({ xlsxData, exportDate: eventsDate.value, exportName, mergeCellsRanges, colWidth });
  } else {
    const csvData = getEventDataAsCsv();
    downloadAsCsv(csvData, eventsDate.value, exportName);
  }
};

const getFormattedDate = (dateOrDateRange?: TmCalendarDate) => {
  let date = dateOrDateRange;
  if (Array.isArray(date)) {
    date = formatScenarioDatePeriod(date[0], date[1], 'datetime', false, true, () => ({ t, d }));
  } else {
    date = formatScenarioDate(date, 'datetime', false, () => ({ t, d }));
  }
  return date;
};

const getEventDataAsCsv = (): CsvCell[] => {
  const parsedData = currentEvents.value.map((ev) => {
    // const modCount = ev.modifications?.length;
    // const superdioId = ev.externalId || 'n/a';
    return {
      [`${t('exports.event name')}`]: ev.name || '',
      [`${t('exports.event description')}`]: ev.description || '',
      [`${t('exports.date from')}`]: getFormattedDate(ev.dateFrom) || '', // direct usage of the utils function to avoid i18n initialization errors
      [`${t('exports.date to')}`]: getFormattedDate(ev.dateTo) || '',
      ...(shouldDisplayExternalId.value ? { ID_SUPERDIO: ev.externalId || '' } : {}),
    };
  });
  return parsedData;
};

const getEventDataAsXlsx = (): XlsxCell[][] => {
  const downloadDate = getFormattedDate(today({ stripMins: false }));
  const parseDate = (d?: TmDate) => d?.split('.')[0].replace('T', ' ') || '';
  const externalLink = import.meta.env.VITE_EVENT_EXTERNAL_LINK;
  const parseLink = (id?: string | null) => (externalLink && id ? `${externalLink}${id}` : null);
  const borderedCellStyle: XlsxCellStyle = {
    border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } },
  };
  const dataArray: any[] = currentEvents.value.map((item) => [
    { v: item.name, t: 's', s: borderedCellStyle },
    { v: item.description || '', t: 's', s: { ...borderedCellStyle, alignment: { wrapText: false } } },
    { v: parseDate(item.dateFrom), t: item.dateFrom ? 'd' : 't', z: 'yyyy-mm-dd hh:mm:ss', s: borderedCellStyle },
    { v: parseDate(item.dateTo), t: item.dateTo ? 'd' : 't', z: 'yyyy-mm-dd hh:mm:ss', s: borderedCellStyle },
    ...(shouldDisplayExternalId.value && !item.externalId ? [{ v: '', t: 's', s: borderedCellStyle }] : []), // TODO: does this fix empty superdio id bug?
    ...(shouldDisplayExternalId.value && !!item.externalId
      ? [{ v: item.externalId, t: 's', l: { Target: parseLink(item.externalId) }, s: borderedCellStyle }]
      : []),
  ]);
  const headers = [
    { v: t('exports.event name'), t: 's', s: borderedCellStyle },
    { v: t('exports.event description'), t: 's', s: borderedCellStyle },
    { v: t('exports.date from'), t: 's', s: borderedCellStyle },
    { v: t('exports.date to'), t: 's', s: borderedCellStyle },
    ...(shouldDisplayExternalId.value ? [{ v: 'ID_SUPERDIO', t: 's', s: borderedCellStyle }] : []),
  ];
  dataArray.unshift(headers);
  dataArray.unshift([
    { v: activeScenario.value.name, t: 's' },
    { v: `${t('exports.traffic event date')} ${eventsDate.value}`, t: 's', s: { alignment: { horizontal: 'center' } } },
  ]);
  dataArray.push(['', '', '', `${t('exports.event download date')} ${downloadDate}`]);
  return dataArray;
};

const goToExternalLink = (id: number) => {
  if (!shouldDisplayExternalId.value || !id) return;
  window.open(`${eventExternalLink}${id}`, '_blank');
};
</script>

<style scoped>
.p-card {
  margin: 1rem auto;
  max-height: 95vh;
  width: 100%;
}
.p-card :deep(.content) {
  overflow-y: hidden;
}
:deep(.heading) {
  margin-bottom: 0 !important;
}
:deep(.header > div) {
  line-height: 2rem;
}
:deep(.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even)) {
  background: #f8f9fa;
}
:deep(.p-datatable-table tr td),
:deep(.p-datatable-table tr th) {
  flex: none !important;
  width: 15%;
  min-width: 100px;
}
:deep(.p-datatable-wrapper) {
  max-height: 60vh;
  width: 100%;
}
:deep(.p-datatable-table) {
  width: 90vw;
}
.desc-text {
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
