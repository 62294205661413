import { defaultLocale } from '@plugins/i18n'; // default locale logic decided at i18n
import cookieConsentLocales from '@locales/plugins/cookie-consent';

// cookie-consent package defines its types globally... TODO: manage somehow?

const userConfig: UserConfig = {
  current_lang: defaultLocale,
  mode: 'opt-in', // gdpr compliant
  autoclear_cookies: true,
  page_scripts: true,
  revision: 1,
  gui_options: {
    consent_modal: {
      layout: 'box',
      position: 'bottom right',
    },
    settings_modal: {
      layout: 'box',
    },
  },
  languages: cookieConsentLocales,
};

export default userConfig;
