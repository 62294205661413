import { useI18n } from 'vue-i18n';
import * as datesUtils from '@utils/tm-date';
import type { App, InjectionKey } from 'vue';

export const pluginKey: InjectionKey<typeof tmDate> = Symbol('tmdate');
export const pluginGlobal = '$tmdate';

export default {
  install: (app: App) => {
    // provide plugin as global variable for template
    app.config.globalProperties[pluginGlobal] = tmDate;

    // provide plugin for setup()
    app.provide(pluginKey, tmDate);
  },
};

export const tmDate = {
  ...datesUtils,
  // @ts-ignore  // too deep instantiations
  formatScenarioDate: (d?: TmDate, f?: string, t?: boolean): string => datesUtils.formatScenarioDate(d, f, t, useI18n),
  formatScenarioDatePeriod: (d1?: TmDate, d2?: TmDate, f?: string, t?: boolean, s?: boolean): string =>
    datesUtils.formatScenarioDatePeriod(d1, d2, f, t, s, useI18n),
};
