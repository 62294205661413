import { createRouter, createWebHistory } from 'vue-router';
import errors from './errors';
import home from './home';
import settings from './settings';
import scenarios from './scenarios';
import user from './user';
import admin from './admin';
import models from './models';
import store from '@store/index';
import type { RouteLocationNormalized } from 'vue-router';

// 1.1 compatibility redirects
const redirects = [
  { path: '/public', redirect: { name: 'home' } },
  { path: '/editor', redirect: { name: 'home' } },
];

const routes = [...errors, ...home, ...settings, ...scenarios, ...user, ...admin, ...models, ...redirects];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

// route guards
router.beforeEach((to, from) => {
  // if user comes from admin layout to another (map) layout - hard reload is needed to avoid map reactivity issues (issue 1008)
  if (isAdminRoute(from) && !isAdminRoute(to)) {
    const baseUrl = import.meta.env.BASE_URL.slice(0, -1);
    store.dispatch('refreshApp', `${baseUrl}${to.fullPath}`);
  }
  // Authentication check; Authorization is at Per-Route Guard
  else if (to.matched.some((record) => record.meta.requiresAuth) && !store.state.auth.user.id) {
    // redirect unauthenticated user to the login view
    const loginRouteName = isAdminRoute(to) ? 'admin.login' : 'user.login';
    store.commit('layout/SET_GUARDED_ROUTE_ALERT', { shouldDisplay: true });
    router.push({ name: loginRouteName });
  } else {
    // Preserve query param between routes
    if (Object.keys(to.query).length === 0 && Object.keys(from.query).length !== 0) {
      // this also doubles every other route guard check - maybe try older vue-router approach using 'next()'?
      router.push({ ...to, query: from.query });
    } else {
      // route is validated
      return true;
    }
  }
});

function isAdminRoute(route: RouteLocationNormalized) {
  const routeName = route.name;
  return typeof routeName === 'string' && routeName.startsWith('admin.');
}

export { routes };
export default router;
