import { ref } from 'vue';
import 'vanilla-cookieconsent';
// import 'vanilla-cookieconsent/dist/cookieconsent.css';
import './theme.css'; // customized TM theme
import consentOptions from './config';
import type { App, InjectionKey, Plugin } from 'vue';

type Callback = () => void;
// cookie-consent package defines its types globally... TODO: manage somehow?
interface TmCookieConsent extends CookieConsent {
  onConsentChange: (c: Callback) => void;
}

export const pluginKey: InjectionKey<TmCookieConsent> = Symbol('cc');
export const pluginGlobal = '$cc';

const callbacks = ref<Callback[]>([]);

const cookieConsentPlugin: Plugin = (app: App) => {
  const cookieConsent = window.initCookieConsent();

  const onConsentChange = (callback: Callback) => {
    callbacks.value.push(callback);
  };

  const callCallbacks = () => {
    callbacks.value.forEach((cb) => cb());
  };

  consentOptions.onAccept = callCallbacks;
  consentOptions.onChange = callCallbacks;
  cookieConsent.run(consentOptions);

  // provide plugin for setup()
  app.provide(pluginKey, { ...cookieConsent, onConsentChange });

  // provide plugin as global variable for template
  app.config.globalProperties[pluginGlobal] = cookieConsent;
};

export default cookieConsentPlugin;
