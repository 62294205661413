<template>
  <div class="textarea-wrapper">
    <prime-textarea
      v-model="value"
      :name="name"
      :rows="rows"
      :disabled="disabled"
      :class="{ 'p-invalid': errorMessage, readonly }"
      :placeholder="placeholder"
      :aria-describedby="'help-input-' + name"
      :readonly="readonly"
      @input="emitValue"
      @blur="$emit('blur')"
    />
    <i v-if="loading" class="pi pi-spin pi-spinner loader" />
    <small :id="'help-input-' + name" class="p-error">{{ errorMessage }}</small>
  </div>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate';
import PrimeTextarea from 'primevue/textarea';

const props = withDefaults(
  defineProps<{
    modelValue?: string;
    placeholder?: string; // Text to display as placeholder
    name: string; // Name attribute of the textarea
    rules?: string; // Vee-validate rules string (e.g. "required|min:5")
    rows?: number; // Number of rows to display
    disabled?: boolean; // Disabled attribute of the textarea
    loading?: boolean; // Whether the textarea data is still loading
    readonly?: boolean; // Readonly attribute of the textarea
  }>(),
  {
    modelValue: undefined,
    placeholder: undefined,
    rules: undefined,
    rows: 6,
    disabled: false,
    loading: false,
    readonly: false,
  },
);

const emit = defineEmits<{
  'update:modelValue': [value: string];
  blur: [];
}>();

const { errorMessage, value } = useField(props.name, props.rules, {
  initialValue: props.modelValue,
});

const emitValue = (event: Event) => {
  const eventTarget = event.target as HTMLInputElement;
  emit('update:modelValue', eventTarget.value);
};
</script>

<style scoped>
textarea {
  width: 100%;
  resize: vertical;
  border: none;
  padding: 0;
}
.p-inputtext.p-invalid.p-component {
  box-shadow: 0 0 0 0.2rem #f44336;
}
.p-inputtext:not(.readonly):enabled:hover {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
  border-color: #0094d2;
}
.textarea-wrapper {
  position: relative;
  padding: 1rem 0 0.5rem 0;
}
.loader {
  right: 0.5rem;
  color: #6c757d;
  position: absolute;
  margin-top: 0.5rem;
}
</style>
