import { ref, shallowRef } from 'vue';
import axios from 'axios';
import type { Ref } from 'vue';
import type { AxiosRequestConfig } from 'axios';

export default function useAxios({ isShallow = false } = {}) {
  const response: Ref<TmApiResponse | null> = isShallow ? shallowRef(null) : ref(null);
  const hasError = ref(false);
  const isLoading = ref(false);
  const uploadProgress = ref(0);

  function initWithoutFiles() {
    return axios.create();
  }

  function initWithFiles() {
    return axios.create({
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: progressHandler,
    });
  }

  function progressHandler(progressEvent: any) {
    uploadProgress.value = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total).toString(), 10);
  }

  async function exec(options: AxiosRequestConfig<any>, isUploadingFiles = false) {
    isLoading.value = true;

    try {
      const instance = !isUploadingFiles ? initWithoutFiles() : initWithFiles();
      const axiosResponse = await instance({ ...options });
      isLoading.value = false;
      hasError.value = false;
      response.value = {
        status: axiosResponse.status,
        data: axiosResponse.data,
        code: axiosResponse.data?.status,
        message: axiosResponse.data?.message,
      };
    } catch (issue: any) {
      isLoading.value = false;
      hasError.value = true;
      response.value = {
        status: issue.response?.status,
        data: issue.response?.data?.data,
        code: issue.response?.data?.code,
        message: issue.response?.data?.error || issue.message,
      };
    }
  }

  return {
    exec,
    response,
    hasError,
    isLoading,
    uploadProgress,
  };
}
