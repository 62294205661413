<template>
  <div :key="displayMode" class="content scrolled">
    <tm-timeline :data="items" @event:clicked="onItemClick" />
  </div>

  <div class="actions p-d-flex p-jc-center">
    <tm-button
      :tooltip="$t(`dialogs.show ${displayMode == 'events' ? 'modifications' : 'events'}`)"
      :class="{ rotated: buttonIcon.includes('grouped') }"
      :icon="buttonIcon"
      type="create"
      @click="toggleDisplayMode()"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, defineAsyncComponent } from 'vue';
import useDialog from '@composables/useDialog';

const TmTimeline = defineAsyncComponent(() => import('@components/timeline/Timeline.vue'));

const { dialog } = useDialog();
const displayMode = ref('events');

const items = computed(() => {
  if (displayMode.value == 'events') return dialog.value.data.events;
  return dialog.value.data.modifications;
});

const buttonIcon = computed(() => {
  if (displayMode.value == 'events') return 'ri-bar-chart-grouped-fill';
  return 'ri-bar-chart-horizontal-fill';
});

const onItemClick = (timelineEvent: TmTimelineEvent) => {
  if (displayMode.value == 'events') dialog.value.callback?.onEventClick?.(timelineEvent);
  else dialog.value.callback?.onModificationClick?.(timelineEvent);
};

const toggleDisplayMode = () => {
  const newMode = displayMode.value == 'events' ? 'modifications' : 'events';
  displayMode.value = newMode;
  dialog.value.header = `dialogs.${newMode} timeline`;
};
</script>

<style scoped>
:deep(.tm-button.rotated span.ri) {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.scrolled {
  display: flex;
  flex-direction: column-reverse;
}
</style>
