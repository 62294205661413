import useMainApiRequest from '@composables/useMainApiRequest';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useToast from '@composables/useToast';

export default function useScenarios({
  cacheOverview,
  invalidateOverview,
  cacheScenario,
  invalidateScenario,
}: {
  cacheOverview: (sc: TmScenario[] | TmScenario, ids?: { scenarioId?: number }) => void;
  invalidateOverview: (ids?: { scenarioId?: number }) => void;
  cacheScenario: (sc: TmScenario, ids: { scenarioId?: number }) => void;
  invalidateScenario: (ids?: { scenarioId?: number }) => void;
}) {
  const { t } = useI18n();
  const { makeRequest, checkIds } = useMainApiRequest();
  const store = useStore();
  const router = useRouter();
  const { addToast } = useToast();

  async function fetchScenarios() {
    await makeRequest({
      url: 'scenarios',
      method: 'get',
      params: { modelId: store.state.scenarios.model.id },
      message: {
        error: {
          404: { summary: t('scenarios.no scenarios found'), severity: 'info' },
          default: t('scenarios.error while fetching scenarios'),
        },
      },
      onSuccess: (result: { scenarios: TmScenario[] }) => cacheOverview(result.scenarios),
      onFailure: () => invalidateOverview(),
    });
  }

  async function fetchScenario(id?: number, { withEvents = true } = {}) {
    const [scenarioId] = checkIds([id]);
    const shouldLoadWithChanges: boolean = store.getters['scenarios/isUsingChangedScenario'](scenarioId);

    await makeRequest({
      url: `scenarios/${scenarioId}`,
      method: 'get',
      params: {
        ...(shouldLoadWithChanges && { useChanged: true }),
        ...(withEvents && { withEvents: true }),
      },
      message: {
        error: t('scenarios.error while fetching scenario'),
      },
      onSuccess: (result: { scenario: TmScenario }) => {
        cacheScenario(result.scenario, { scenarioId });
        if (!result.scenario.hasModelSectionsValid) _displayInvalidScenarioWarning();
      },
      onFailure: () => invalidateScenario({ scenarioId }),
    });
  }

  async function createScenario(
    scenario: TmScenario,
    {
      leaveAfter = true,
      onSuccess = () => {},
    }: {
      leaveAfter?: boolean;
      onSuccess?: (arg: any) => Promise<void> | void;
    } = {},
  ) {
    await makeRequest({
      url: 'scenarios',
      method: 'post',
      data: {
        name: scenario.name,
        description: scenario.description,
        note: scenario.note,
        modelId: store.state.scenarios.model.id, // active model ID
      },
      message: {
        success: t('scenarios.scenario created'),
        error: t('scenarios.failed to create scenario'),
      },
      onSuccess: async (result: { scenario: TmScenario }) => {
        const resultedScenario = result.scenario;
        cacheOverview(resultedScenario, { scenarioId: resultedScenario.id });
        await onSuccess(resultedScenario);

        if (leaveAfter) router.push({ name: 'scenarios.events', params: { id: resultedScenario.id } });
      },
    });
  }

  async function updateScenario(scenario: TmScenario, { leaveAfter = true } = {}) {
    await makeRequest({
      url: `scenarios/${scenario.id}`,
      method: 'patch',
      data: {
        name: scenario.name,
        description: scenario.description,
        note: scenario.note,
        isPublic: scenario.isPublic,
        isInPublicList: scenario.isInPublicList,
      },
      message: {
        success: t('scenarios.scenario updated'),
        error: {
          EDIT_WAITING: {
            summary: t('scenarios.failed to update scenario'),
            detail: t('scenarios.unresolved scenario can not be updated'),
            severity: 'error',
          },
          default: t('scenarios.failed to update scenario'),
        },
      },
      onSuccess: (result: { scenario: TmScenario; editSession: TmEditSession }) => {
        const resultedScenario = result.scenario;
        cacheScenario(resultedScenario, { scenarioId: resultedScenario.id });
        cacheOverview(resultedScenario, { scenarioId: resultedScenario.id });

        if (leaveAfter) router.push({ name: 'scenarios.events', params: { id: resultedScenario.id } });
      },
    });
  }

  async function updateScenarioPublication(scenario: TmScenario) {
    const modelSections = scenario.modelSections || null;
    await makeRequest({
      url: `scenarios/${scenario.id}/public`,
      method: 'patch',
      data: {
        isPublic: scenario.isPublic,
        isInPublicList: scenario.isInPublicList,
      },
      message: {
        success: t('scenarios.publication updated'),
        error: t('scenarios.failed to update publication'),
      },
      onSuccess: (result: { scenario: TmScenario }) => {
        const resultedScenario = result.scenario;
        if (modelSections) resultedScenario.modelSections = modelSections;
        cacheScenario(resultedScenario, { scenarioId: resultedScenario.id });
        invalidateOverview(); // public list might have changed
      },
    });
  }

  async function deleteScenario(id?: number) {
    const [scenarioId] = checkIds([id]);

    await makeRequest({
      url: `scenarios/${scenarioId}`,
      method: 'delete',
      message: {
        success: t('scenarios.scenario deleted'),
        error: t('scenarios.failed to delete scenario'),
      },
      onSuccess: () => {
        invalidateScenario({ scenarioId });
        invalidateOverview({ scenarioId });

        const activeScenarioId: number | null = store.getters['map/getActiveItemId']({ itemLevel: 'scenario' });
        if (scenarioId == activeScenarioId) store.dispatch('map/activateItem', {}); // deactivate deleted scenario
      },
    });
  }

  async function copyScenario(id?: number) {
    const [scenarioId] = checkIds([id]);

    await makeRequest({
      url: `scenarios/${scenarioId}/copy`,
      method: 'post',
      message: {
        success: t('scenarios.scenario copied'),
        error: t('scenarios.failed to copy scenario'),
      },
      onSuccess: (result: { scenario: TmScenario }) => {
        const resultedScenario = result.scenario;
        cacheOverview(resultedScenario, { scenarioId: resultedScenario.id });
        // activate copied item
        store.dispatch('map/activateItem', { scenarioId: resultedScenario.id });
      },
    });
  }

  function _displayInvalidScenarioWarning() {
    addToast({
      severity: 'info',
      summary: t('scenarios.not reliable scenario data'),
    });
  }

  return {
    fetchScenarios,
    fetchScenario,
    createScenario,
    updateScenario,
    deleteScenario,
    copyScenario,
    updateScenarioPublication,
  };
}
