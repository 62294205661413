import { transform } from 'ol/proj';

// Mutates geojson
export function transformGeojson(geojson, { sourceProj, targetProj }) {
  if (geojson.crs?.properties) geojson.crs.properties.name = `urn:ogc:def:crs:EPSG::${targetProj.split(':')[1]}`;
  for (const feature of geojson.features) {
    const oldCoords = feature.geometry.coordinates;
    const newCoords = transformCoords(oldCoords, { sourceProj, targetProj });
    feature.geometry.coordinates = newCoords;
  }
  return geojson;
}

const transformCoords = (coordOrCoords, { sourceProj, targetProj }) => {
  if (isCoord(coordOrCoords)) return transform(coordOrCoords, sourceProj, targetProj);
  return coordOrCoords.map((coord) => transformCoords(coord, { sourceProj, targetProj }));
};

const isCoord = (arr) => {
  if (arr.length === 2 && typeof arr[0] === 'number') return true;
};
