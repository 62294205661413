import AdminLayout from '@layouts/Admin.vue';
import store from '@store/index';
import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/admin',
    name: 'admin.login',
    component: () => import('@views/admin/Login.vue'),
    meta: {
      layout: AdminLayout,
    },
  },
  {
    path: '/admin/users',
    name: 'admin.users',
    component: () => import('@views/admin/Users.vue'),
    meta: {
      requiresAuth: true,
      layout: AdminLayout,
    },
    beforeEnter: () => store.getters['auth/hasRole']('userManager'), // admin, userManager only
  },
  {
    path: '/admin/texts/:text?',
    name: 'admin.texts',
    component: () => import('@views/admin/Texts.vue'),
    meta: {
      requiresAuth: true,
      layout: AdminLayout,
    },
    beforeEnter: () => store.getters['auth/hasRole']('textManager'), // admin, textManager only
    props: true,
  },
];

export default routes;
