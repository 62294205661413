import useMainApiRequest from '@composables/useMainApiRequest';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import useToast, { TmToastSeverity } from '@composables/useToast';
import { useRouter } from 'vue-router';
import useFullScenario from '@composables/useFullScenario';
import useMobile from '@composables/useMobile';
import { useConfirm } from 'primevue/useconfirm';
import { useCurrentRouteParams } from '@composables/useCommonHelpers';

export default function useEditMode(defScenarioId?: number) {
  const { t } = useI18n();
  const { makeRequest } = useMainApiRequest();
  const store = useStore();
  const { addToast } = useToast();
  const router = useRouter();
  const isMobile = useMobile();
  const { invalidateFullScenario, fetchScenario, fetchScenariosOverview } = useFullScenario({
    scenarioId: defScenarioId,
  });
  const confirm = useConfirm();
  const { getScenarioId } = useCurrentRouteParams();

  const computeRequestFrequency = computed(() => {
    const isDTA: boolean = store.getters['scenarios/isDtaModelTypeActive'];
    return isDTA
      ? Number(import.meta.env.VITE_COMPUTATION_REQUEST_FREQUENCY_DTA || 20000)
      : Number(import.meta.env.VITE_COMPUTATION_REQUEST_FREQUENCY_STA || 2000);
  });

  async function enterEditSession(scenario: TmScenario, { forceLoadChanges = false } = {}) {
    if (isMobile()) return; // no edit session on mobile devices atm
    if (!scenario.level) return; // must have some level of access to the scenario
    if (!store.getters['auth/isLogged'] || !['editor', 'inserter'].includes(scenario.level)) return;

    await makeRequest({
      url: `scenarios/${scenario.id}/edit/enter`,
      method: 'post',
      message: {
        success: {
          EDIT_ENTERED: { summary: t('scenarios.start edit mode'), severity: 'info' },
        },
        error: {
          EDIT_OCCUPIED: { summary: t('scenarios.edit mode occupied'), severity: 'error' },
          EDIT_MODEL_REFRESH: { summary: t('scenarios.not reliable scenario data'), severity: 'info' },
          default: { summary: t('scenarios.failed to start edit mode'), severity: 'error' },
        },
      },
      onSuccess: (result: { editSession: TmEditSession }) => {
        const scenarioId = scenario.id;
        const editSession = result.editSession || {};
        const hasComputationError = editSession.computationState == 'error';
        const hasChanges = editSession.hasChange == true;
        // start loading changes only if the corresponding scenario is not unresolved
        const isScenarioUnresolved = store.getters['scenarios/isScenarioUnresolved'](scenarioId);
        _startEditMode(scenarioId, { withChanges: forceLoadChanges || !isScenarioUnresolved || hasComputationError });

        if (hasComputationError) addToast({ severity: 'error', summary: t('scenarios.failed computation entered') });
        if (hasChanges && !isScenarioUnresolved)
          addToast({ severity: 'info', summary: t('scenarios.changes resumed') });
      },
    });
  }

  async function exitEditSession(scenarioId = defScenarioId) {
    await makeRequest({
      url: `scenarios/${scenarioId}/edit/leave`,
      method: 'post',
      message: {
        success: {
          summary: t('scenarios.edit mode closed'),
          severity: 'info',
        },
        error: t('scenarios.failed to close edit mode'),
      },
      onSuccess: () => {
        _exitEditMode();
      },
    });
  }

  async function cancelEditSession(scenarioId = defScenarioId, { leaveAfter = false } = {}) {
    await makeRequest({
      url: `scenarios/${scenarioId}/edit/cancel`,
      method: 'post',
      message: {
        success: {
          summary: t('scenarios.edit mode canceled'),
          severity: 'info',
        },
        error: t('scenarios.failed to cancel edit mode'),
      },
      onSuccess: () => {
        if (leaveAfter) router.push({ name: 'scenarios' });
        else _updateScenarioData(scenarioId);
      },
    });
  }

  function _startEditMode(scenarioId = defScenarioId, { withChanges = false } = {}) {
    store.dispatch('scenarios/startEditMode', { scenarioId, withChanges });
    _updateScenarioData(scenarioId);
  }

  function _exitEditMode(scenarioId = defScenarioId) {
    store.dispatch('scenarios/exitEditMode', { scenarioId });
    _updateScenarioData(scenarioId);
  }

  function switchToChangedScenario(scenarioId = defScenarioId) {
    store.dispatch('scenarios/switchToChangedScenario');
    _updateScenarioData(scenarioId);
  }

  function switchToOriginalScenario(scenarioId = defScenarioId) {
    store.dispatch('scenarios/switchToOriginalScenario');
    _updateScenarioData(scenarioId);
  }

  async function _getEditSessionState(scenarioId = defScenarioId) {
    let sessionState = <TmEditSession | null>null;

    await makeRequest({
      url: `scenarios/${scenarioId}/edit`,
      method: 'get',
      onSuccess: (result: { editSession: TmEditSession }) => {
        sessionState = result.editSession;
      },
    });

    return sessionState;
  }

  async function _getAllEditSessionsState() {
    let sessionsState: TmEditSession[] = [];

    await makeRequest({
      url: `scenarios/edit/overview`,
      method: 'get',
      onSuccess: (result) => (sessionsState = result),
    });

    return sessionsState;
  }

  async function verifyEditSessions() {
    const isLoggedUser = store.getters['auth/isLogged'];
    if (!isLoggedUser) return; // user has only access to edit sessions if he is logged in

    const editSessionStates = await _getAllEditSessionsState();
    editSessionStates.forEach((editSession) => {
      // update corresponding scenario progress
      store.dispatch('scenarios/updateScenarioProgress', { scenarioId: editSession.scenarioId, editSession });
    });
    const editModeScenarioId = store.state?.scenarios?.editMode?.scenarioId || null;
    if (!editModeScenarioId) return;
    const openSessionScenarioIds = editSessionStates.map((s) => s.scenarioId) || [];
    // client side edit mode does not match any open server side session -> exit edit mode
    if (!openSessionScenarioIds.includes(editModeScenarioId)) return _exitEditMode(editModeScenarioId);
  }

  async function _trackComputationState(scenarioId = defScenarioId, { saveAfter }: { saveAfter: boolean }) {
    const computationStatuses = ['computed', 'notStarted', 'error', 'unlinked']; // unlinked is client specific status
    const [computedStatus, notStartedStatus, errorStatus, unlinkedStatus] = computationStatuses;
    const finishedStatuses = saveAfter
      ? [notStartedStatus, errorStatus] // notStarted status is expected whit the saveAfter flag or after canceling computation during tracking
      : [computedStatus, errorStatus, notStartedStatus];
    let trackingFinished = false;
    let scenarioSaved = false;
    let scenarioLeft = false;
    let resumeChanges = false;
    let computationStatus;
    let editSessionState;

    store.dispatch('layout/startLoading');

    while (!trackingFinished) {
      // update saveOnComputed flag from the last session state before computing is finished (it can be changed during computing)
      saveAfter = editSessionState?.saveOnComputed ?? saveAfter;
      editSessionState = await _getEditSessionState(scenarioId);
      if (editSessionState && finishedStatuses.includes(editSessionState.computationState)) {
        trackingFinished = true;
        computationStatus = editSessionState.computationState;
        scenarioSaved = saveAfter && !editSessionState.hasChange && !!editSessionState.computationTimestamp;
        scenarioLeft = getScenarioId() != scenarioId;
        resumeChanges = scenarioSaved || (computationStatus == errorStatus && editSessionState.hasChange);
      }
      if (!editSessionState) {
        // request failed (can occur if user is unlinked from the edit session while tracking)
        trackingFinished = true;
        computationStatus = unlinkedStatus;
      }
      if (!trackingFinished) await new Promise((resolve) => setTimeout(resolve, computeRequestFrequency.value));
    }

    store.dispatch('layout/finishLoading');

    const isUsingChangedScenario = store.getters['scenarios/isUsingChangedScenario'](scenarioId);
    if (isUsingChangedScenario) _updateScenarioData(scenarioId);

    const msg = _getComputedMessage(computationStatuses, computationStatus, scenarioSaved);
    addToast({ severity: msg.severity, summary: t(msg.summary) });

    // scenario was saved automatically on server - display message and then switch to the changed scenario if not scenarioLeft
    if (scenarioSaved) addToast({ severity: 'success', summary: t('scenarios.scenario saved') });
    if (resumeChanges && !scenarioLeft) switchToChangedScenario(scenarioId);

    if (computedStatus && editSessionState?.computationWarning) {
      // computation with warning (e.g. isolated network)
      addToast({ severity: 'info', summary: t('scenarios.computing finished with warning') });
    }
  }

  async function computeScenario(scenarioId = defScenarioId, { saveAfter = false, leaveAfter = false } = {}) {
    await makeRequest({
      url: `scenarios/${scenarioId}/edit/compute`,
      method: 'post',
      params: {
        saveOnComputed: saveAfter,
      },
      message: {
        success: { summary: t('scenarios.scenario start computing'), severity: 'info' },
        error: t('scenarios.failed to start computing scenario'),
      },
      timeout: 30 * 1000,
      onSuccess: () => {
        store.commit('scenarios/SET_PROGRESS', { scenarioId, isBeingComputed: true });
        _trackComputationState(scenarioId, { saveAfter });
        switchToOriginalScenario(scenarioId); // go back to the original scenario
        if (leaveAfter) router.push({ name: 'scenarios' });
      },
      onFailure: (err) => {
        if (err.code !== 'EDIT_NOT_COMPUTABLE') return;
        store.commit('scenarios/SET_PROGRESS', { scenarioId, isToBeComputed: false });
      },
    });
  }

  async function saveScenario(scenarioId = defScenarioId, { leaveAfter = false, saveAsCopy = false } = {}) {
    await makeRequest({
      url: `scenarios/${scenarioId}/edit/save`,
      method: 'post',
      params: { saveAsCopy },
      message: {
        success: {
          EDIT_COMPUTATION_RUNNING: { summary: t('scenarios.scenario will be saved'), severity: 'info' },
          EDIT_SAVED_COPY: { summary: t('scenarios.scenario saved as copy'), severity: 'success' },
          default: t('scenarios.scenario saved'),
        },
        error: t('scenarios.failed to save scenario'),
      },
      onSuccess: () => {
        if (leaveAfter) router.push({ name: 'scenarios' });
        else _updateScenarioData(scenarioId);
      },
      onFailure: (err) => {
        if (err.code !== 'EDIT_NOT_CHANGED') return;
        store.commit('scenarios/SET_PROGRESS', { scenarioId, isToBeSaved: false });
      },
    });
  }

  const confirmCancelScenarioChanges = async (
    scenarioId = defScenarioId,
    { leaveAfter = false, cancelInProgress = false } = {},
  ) => {
    confirm.require({
      message: t(`scenarios.confirm delete computation${cancelInProgress ? ' in progress' : ''}`),
      header: t('scenarios.delete computation'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => cancelEditSession(scenarioId, { leaveAfter }),
    });
  };

  const confirmSaveScenarioChanges = (scenarioId = defScenarioId, { leaveAfter = false, saveAsCopy = false } = {}) => {
    const isToBeComputed = store.getters['scenarios/isScenarioToBeComputed'](scenarioId);
    if (!isToBeComputed) return saveScenario(scenarioId, { leaveAfter, saveAsCopy });

    confirm.require({
      message: t('scenarios.confirm compute scenario'),
      header: t('scenarios.compute scenario'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => computeScenario(scenarioId, { saveAfter: true, leaveAfter }),
    });
  };

  function _updateScenarioData(scenarioId?: number) {
    if (!scenarioId) return;
    invalidateFullScenario(scenarioId); // run corresponding invalidations // TODO: is this needed in every case?
    fetchScenario(scenarioId, { forced: true });
    const isOverviewNeeded = () => {
      const isInScenariosView = router.currentRoute?.value?.name == 'scenarios';
      const isComparisonWidgetOpen = store.getters['map/getWidgets']()?.includes('comparison');
      return isInScenariosView || isComparisonWidgetOpen;
    };
    if (isOverviewNeeded()) fetchScenariosOverview({ forced: true });
  }

  return {
    computeScenario,
    saveScenario,
    enterEditSession,
    cancelEditSession,
    exitEditSession,
    verifyEditSessions,
    switchToChangedScenario,
    switchToOriginalScenario,
    confirmCancelScenarioChanges,
    confirmSaveScenarioChanges,
  };
}

function _getComputedMessage(
  [computedStatus, notStartedStatus, errorStatus, unlinkedStatus]: string[],
  status?: string,
  isSaved?: boolean,
): {
  severity: TmToastSeverity;
  summary: string;
} {
  switch (status) {
    case computedStatus:
      return { severity: 'success', summary: 'scenarios.computing finished' };
    case notStartedStatus:
      return {
        severity: isSaved ? 'success' : 'info',
        summary: isSaved ? 'scenarios.computing finished' : 'scenarios.computation stopped', // notStarted is the desired computation status if we also wanted to saveAfter
      };
    case errorStatus:
      return { severity: 'error', summary: 'scenarios.computing not finished' };
    case unlinkedStatus:
      return { severity: 'info', summary: 'scenarios.computing status not available' }; // user is unlinked while tracking computation status
    default:
      return { severity: 'error', summary: 'scenarios.computing status not available' };
  }
}
