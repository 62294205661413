<template>
  <div class="panel" :class="[opened ? 'opened transitionfast' : 'folded transitionslow', stickSide]">
    <div v-if="stickSide == 'right'" class="panel-content" :class="{ scrollable: activeWidgets > 1 }">
      <tm-widgets ref="widgetsRef" />
    </div>
    <slot v-else />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import TmWidgets from '@components/layouts/Widgets.vue';

const props = defineProps<{
  stickSide: 'left' | 'right'; //  Defines left or right side to which the panel will be aligned to
}>();

const store = useStore();
const opened = computed(() => store.state.layout.panels[props.stickSide] === 'opened');
const widgetsRef = ref<typeof TmWidgets>();
const activeWidgets = computed(() => widgetsRef.value?.widgets?.length);
</script>

<style scoped>
.panel {
  width: 33rem;
  max-width: 50%;
  position: fixed;
  top: 5rem;
  bottom: 0;
  padding: 1rem;
}
.panel-content {
  display: unset;
}
.panel-content.scrollable {
  display: block;
  max-height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 1px;
}
.transitionslow {
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}
.transitionfast {
  -webkit-transition: all 0.05s linear;
  -moz-transition: all 0.05s linear;
  -o-transition: all 0.05s linear;
  transition: all 0.05s linear;
}
.panel.opened.left {
  left: 0;
}
.panel.opened.right {
  right: 0;
}
.panel.folded.left {
  left: -20rem;
}
.panel.folded.right {
  right: -20rem;
}
.panel.folded :deep(.p-card .p-card-body),
.panel.folded :deep(.p-card .header),
.panel.folded :deep(.p-card .content),
.panel.folded :deep(.p-card .footer) {
  filter: blur(3px);
}
</style>
