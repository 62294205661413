import { getSynchronizedModuleState } from '@store/helpers';
import type { Commit } from 'vuex';

type LoadingState = { [key: string]: number };
type PanelState = 'opened' | 'closed' | 'folded'; // folded state is represented as half closed and blurred
type PanelSide = 'left' | 'right';

const moduleState = {
  loading: <LoadingState>{},
  panels: {
    left: <PanelState>'opened',
    right: <PanelState>'opened',
  },
  analyticsEnabled: false,
  guardedRouteAlertDisplayed: false,
};

export const getters = {
  isLoading:
    (state: typeof moduleState) =>
    ({ type = 'default' } = {}) => {
      return !!state.loading[type] && state.loading[type] > 0;
    },
};

export const actions = {
  foldPanels({ commit, state }: { commit: Commit; state: typeof moduleState }, { left = true, right = true } = {}) {
    if (left && state.panels.left == 'opened') {
      commit('SET_PANEL_STATE', { panelSide: 'left', panelState: 'folded' });
    }
    if (right && state.panels.right == 'opened') {
      commit('SET_PANEL_STATE', { panelSide: 'right', panelState: 'folded' });
    }
  },
  unfoldPanels({ commit, state }: { commit: Commit; state: typeof moduleState }, { left = true, right = true } = {}) {
    if (left && state.panels.left == 'folded') {
      commit('SET_PANEL_STATE', { panelSide: 'left', panelState: 'opened' });
    }
    if (right && state.panels.right == 'folded') {
      commit('SET_PANEL_STATE', { panelSide: 'right', panelState: 'opened' });
    }
  },
  openPanel({ commit }: { commit: Commit }, { panelSide = 'right' } = {}) {
    commit('SET_PANEL_STATE', { panelSide, panelState: 'opened' });
  },
  closePanel({ commit }: { commit: Commit }, { panelSide = 'right' } = {}) {
    commit('SET_PANEL_STATE', { panelSide, panelState: 'closed' });
  },
  togglePanel(
    { commit, state }: { commit: Commit; state: typeof moduleState },
    { panelSide = 'right' }: Partial<{ panelSide: PanelSide }> = {},
  ) {
    const panelState = state.panels[panelSide] !== 'closed' ? 'closed' : 'opened';
    commit('SET_PANEL_STATE', { panelSide, panelState });
  },
  startLoading({ commit, state }: { commit: Commit; state: typeof moduleState }, { type = 'default' } = {}) {
    let loadingCounter = state.loading[type] ?? 0;
    commit('SET_LOADING', { loading: ++loadingCounter, type });
  },
  finishLoading({ commit, state }: { commit: Commit; state: typeof moduleState }, { type = 'default' } = {}) {
    let loadingCounter = state.loading[type] ?? 0;
    commit('SET_LOADING', { loading: --loadingCounter, type });
  },
};

export const mutations = {
  SET_LOADING(state: typeof moduleState, { type, loading }: { type: string; loading: number }) {
    state.loading[type] = loading;
  },
  SET_PANEL_STATE(
    state: typeof moduleState,
    { panelSide, panelState }: { panelSide: PanelSide; panelState: PanelState },
  ) {
    state.panels[panelSide] = panelState;
  },
  SET_ANALYTICS(state: typeof moduleState, { isEnabled }: { isEnabled: boolean }) {
    state.analyticsEnabled = isEnabled;
  },
  SET_GUARDED_ROUTE_ALERT(state: typeof moduleState, { shouldDisplay }: { shouldDisplay: boolean }) {
    state.guardedRouteAlertDisplayed = shouldDisplay;
  },
};

export default {
  namespaced: true,
  state: getSynchronizedModuleState({
    module: 'layout',
    state: moduleState,
  }),
  getters,
  actions,
  mutations,
};
