<template>
  <div class="content">
    <tm-divider class="p-mb-3 p-mt-0" />
    <div class="image-wrapper">
      <div v-for="(imgSrc, i) in availableImages" :key="i" class="image-block">
        <img :src="parseImagePath(imgSrc)" @click="selectImage(imgSrc)" />
        {{ parseImagePath(imgSrc, true) }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useDialog from '@composables/useDialog';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const aboutCsFiles = import.meta.glob('/public/images/about/cs/*.png');
const helpCsFiles = import.meta.glob('/public/images/help/cs/*.png');
const aboutEnFiles = import.meta.glob('/public/images/about/en/*.png');
const helpEnFiles = import.meta.glob('/public/images/help/en/*.png');

const { close: closeDialog, dialog } = useDialog();
const { locale } = useI18n();

const availableImages = computed(() => {
  const fileNamesByLang: Record<TmLang, string[]> = {
    cs: [...Object.keys(aboutCsFiles), ...Object.keys(helpCsFiles)],
    en: [...Object.keys(aboutEnFiles), ...Object.keys(helpEnFiles)],
  };
  return fileNamesByLang[locale.value || 'en'];
});

const selectImage = (src: string) => {
  dialog.value.callback?.onImageSelection?.(parseImagePath(src));
  closeDialog();
};

const parseImagePath = (src: string, nameOnly?: boolean) => {
  const baseUrl = import.meta.env.BASE_URL;
  if (!nameOnly) return src.replace('public/', baseUrl).replace('//', '/');
  return src.split('/')[5];
};
</script>

<style scoped>
.content {
  max-width: 80vw;
}
.image-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.image-block {
  cursor: pointer;
  margin: 0.2rem;
  height: 13rem;
  width: 10rem;
  text-align: center;
  overflow: hidden;
}
.image-block:hover {
  font-weight: bold;
}
.image-block img:hover {
  background-color: #dee2e6;
}
.image-block img {
  object-fit: contain;
  height: 10rem;
  width: 10rem;
  border: 1px solid #dee2e6;
}
</style>
