<template>
  <tm-card :class="{ 'mobile-view': $isMobile() }">
    <template #header>
      <tm-breadcrumb :current="$t('scenarios.scenarios')" />
      <tm-heading :text="$t('scenarios.scenarios') + dtaModelFlag" back-to-route="home" />
      <tm-divider />
      <tm-filter
        v-model:selectedSharing="selectedShareFilters"
        v-model:selectedDating="selectedDateFilters"
        v-model:selectedSorting="selectedSorting"
        v-model:searchPhrase="searchPhrase"
        :ready="contentLoaded"
        :sharing-options="isLoggedUser ? scenarioShareFilterGroup : undefined"
        :dating-options="scenarioDateFilterGroup"
        :sorting-options="sortingSelection"
      />
    </template>

    <template #content>
      <tm-panel-list
        :ready="contentLoaded"
        :items="filteredScenarios"
        :activated-item-id="activeScenarioId"
        :watch-mode="!isLoggedUser || !isTrafficModelValid"
        type="scenarios"
        @item:activated="activateAndFitMapItem({ scenarioId: $event.id })"
        @item:deactivated="activateAndFitMapItem({})"
        @item:action:clicked="performItemAction($event.action, $event.item)"
      />
    </template>

    <template #footer>
      <div v-if="isLoggedUser && isTrafficModelValid" class="p-d-flex p-jc-center">
        <tm-button
          :tooltip="$t('scenarios.new scenario')"
          type="create"
          icon="pi-plus"
          class="p-mr-3"
          @click="beginScenarioCreation()"
        />
      </div>
    </template>
  </tm-card>
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from 'vue';
import useEditMode from '@composables/useEditMode';
import useFullScenario from '@composables/useFullScenario';
import useFilters from '@composables/useFilters';
import TmPanelList from '@components/views/PanelList.vue';
import TmFilter from '@components/views/Filter.vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useConfirm } from 'primevue/useconfirm';
import useMapItems from '@composables/useMapItems';
import useModels from '@composables/useModels';
import type { Filter, Sorting } from '@composables/useFilters';
import type { ItemAction } from '@components/views/PanelListItem.vue';

const { fetchModels } = useModels();
const { enterEditSession, verifyEditSessions } = useEditMode();
const {
  scenariosOverview: scenarios,
  fetchScenariosOverview: fetchScenarios,
  deleteScenario,
  copyScenario,
  activeScenarioId,
} = useFullScenario();
const { t } = useI18n();
const store = useStore();
const router = useRouter();
const confirm = useConfirm();
const { activateAndFitMapItem } = useMapItems();

const isLoggedUser = computed<boolean>(() => store.getters['auth/isLogged']);
const isTrafficModelValid = computed<boolean>(() => !!store.state.scenarios.model?.isValid);
const loggedUserId: number = store.state.auth.user.id;
const contentLoaded = ref(false);
const searchPhrase = ref('');

const dtaModelFlag = computed(() => {
  return store.getters['scenarios/isDtaModelTypeActive'] ? ' (DTA)' : '';
});

const scenarioShareFilterGroup: Filter[] = [
  { name: 'own', field: 'authorUserId', value: loggedUserId, label: 'scenarios.private' },
  { name: 'shared', field: 'isShared', value: true, label: 'scenarios.shared' },
  { name: 'public', field: 'isPublic', value: true, label: 'scenarios.public' },
];
const selectedShareFilters = ref<Filter[]>(isLoggedUser.value ? [scenarioShareFilterGroup[0]] : []);

const scenarioDateFilterGroup: Filter[] = [
  { name: 'past', label: 'scenarios.past' },
  { name: 'current', label: 'scenarios.current' },
  { name: 'future', label: 'scenarios.future' },
];
const selectedDateFilters = ref<Filter[]>([]);

const sortingSelection: Sorting[] = [
  { name: 'name', label: 'scenarios.filter by name' },
  { name: 'date', label: 'scenarios.filter by most recent' },
];
const selectedSorting = ref<Sorting>(sortingSelection[0]);

const { filteredItems: filteredScenarios } = useFilters<TmScenario>({
  items: scenarios,
  cacheKey: 'scenarios',
  selectedFilters: {
    ...(isLoggedUser.value && { share: selectedShareFilters }),
    date: selectedDateFilters,
  },
  selectedSorting,
  searchPhrase,
});

const performItemAction = async (action: ItemAction, scenario: TmScenario) => {
  switch (action) {
    case 'enter':
      await enterEditSession(scenario);
      await store.dispatch('map/activateItem', { scenarioId: scenario.id }); // this is only needed to switch data mode to model if it is not already
      router.push({ name: 'scenarios.events', params: { id: scenario.id } });
      break;
    case 'share':
      router.push({ name: 'scenarios.share', params: { id: scenario.id } });
      break;
    case 'copy':
      copyScenario(scenario.id);
      break;
    case 'delete':
      confirm.require({
        message: t('scenarios.confirm delete', { name: scenario.name }),
        header: t('scenarios.delete scenario'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          deleteScenario(scenario.id);
        },
      });
  }
};

const beginScenarioCreation = async () => {
  await store.dispatch('map/activateItem', {}); // cancel any active scenario first
  router.push({ name: 'scenarios.create' });
};

onMounted(async () => {
  await fetchModels(); // models should be prefetched, so we have the active traffic model ID available to fetch corresponding scenarios
  await fetchScenarios();
  contentLoaded.value = true;
  verifyEditSessions();
});
</script>

<style scoped>
.mobile-view .p-divider,
.mobile-view :deep(.mobile-breadcrumb),
.mobile-view :deep(.tm-back),
.mobile-view :deep(.footer) {
  display: none;
}
</style>
