import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import Tooltip from 'primevue/tooltip';
import primeVueLocales from '@locales/plugins/prime-vue';
import { defaultLocale } from '@plugins/i18n'; // default locale logic decided at i18n
import type { App } from 'vue';

// import 'primevue/resources/themes/saga-blue/theme.css'; // original primevue theme
import './theme.css'; // customized primevue theme (saga blue with replaced colors, #2196f3 -> #0094d2, #0d89ec -> #007aad)
import 'primevue/resources/primevue.min.css'; // core primevue css
import 'primeicons/primeicons.css'; // primevue icons
import 'primeflex/primeflex.css'; // primeflex (positioning)
import 'remixicon/fonts/remixicon.css'; // additional remixicon icons (not part of prime-vue)

export default function registerPrimeVue(app: App) {
  app.use(PrimeVue, { locale: primeVueLocales[defaultLocale as keyof typeof primeVueLocales] });
  app.use(ToastService);
  app.use(ConfirmationService);
  app.directive('tooltip', Tooltip);
}
