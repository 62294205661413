import type { RouteParams } from 'vue-router';

type TmRouteParam = string | string[];
type TmResultParam<B> = B extends true ? number | undefined : string | undefined;

const stringToNumber = (p: string) => Number.parseInt(p, 10);

const parseRouteParam = <B extends boolean>(routeParam?: TmRouteParam, expectNumber = <B>false): TmResultParam<B> => {
  if (!routeParam) return;
  const singleStringParam = Array.isArray(routeParam) ? routeParam[0] : routeParam;
  const resultParam = expectNumber ? stringToNumber(singleStringParam) : singleStringParam;
  return resultParam as TmResultParam<B>;
};

export const parseRouteParamAsString = (routeParam?: TmRouteParam) => parseRouteParam(routeParam, false);
export const parseRouteParamAsNumber = (routeParam?: TmRouteParam) => parseRouteParam(routeParam, true);

export const getScenarioItemIdsFromRouteParams = (params: RouteParams): TmItemIds => {
  return {
    scenarioId: parseRouteParamAsNumber(params.id),
    eventId: parseRouteParamAsNumber(params.evId),
    modificationId: parseRouteParamAsNumber(params.modId),
  };
};
