import { computed } from 'vue';
import { useStore } from 'vuex';
import WidgetTimeline from '@components/layouts/widgets/WidgetTimeline.vue';
import useMobile from '@composables/useMobile';
import {
  INTERACTION_MAP_MODES,
  MP_MODEL_STA_AGGREGATION,
  MP_MODEL_STA_AGGREGATION_COMPARISON,
  MP_MODEL_STA_COMPARISON,
  MP_SHORTEST_SELECT,
  MP_SHORTEST_VIEW,
  MP_HISTORICAL_VIEWER,
  MP_HISTORICAL_AGGREGATION,
  MP_LINK_EXPORT_SELECT,
  MP_LINK_EXPORT_SELECT_AGGREGATION,
} from '@keys/index';
import type { Component } from 'vue';

type TmWidget = {
  name: string;
  dataModes?: TmDataMode[];
  modelTypes?: TmModelType[];
  excludedMapModes?: TmMapMode[];
  isForMobile?: boolean;
  icon: string;
  buttonComponent?: Component;
  excludedWidgets?: string[];
  mapModes?: TmMapMode[];
};

const widgetOptions: TmWidget[] = [
  {
    name: 'mapPopup',
    dataModes: ['live', 'historical', 'model', 'comparison'],
    modelTypes: ['STA', 'DTA'],
    excludedMapModes: [...INTERACTION_MAP_MODES, MP_SHORTEST_SELECT, MP_SHORTEST_VIEW],
    isForMobile: true,
    icon: 'ri-information-line',
  },
  {
    name: 'calendar',
    dataModes: ['historical', 'model'],
    modelTypes: ['STA', 'DTA'],
    excludedMapModes: [...INTERACTION_MAP_MODES, MP_SHORTEST_SELECT],
    isForMobile: true,
    icon: 'ri-calendar-line',
  },
  {
    name: 'historicalExport',
    dataModes: ['historical', 'comparison'],
    modelTypes: ['STA'],
    mapModes: [
      MP_HISTORICAL_VIEWER,
      MP_HISTORICAL_AGGREGATION,
      MP_LINK_EXPORT_SELECT,
      MP_LINK_EXPORT_SELECT_AGGREGATION,
    ],
    isForMobile: false,
    icon: 'ri-download-2-line',
  },
  {
    name: 'comparison',
    dataModes: ['comparison'],
    modelTypes: ['STA', 'DTA'],
    isForMobile: true,
    icon: 'ri-equalizer-line',
    excludedWidgets: ['aggregation', 'aggregationComparison'],
  },
  {
    name: 'eventList',
    dataModes: ['model'],
    modelTypes: ['STA', 'DTA'],
    excludedMapModes: [...INTERACTION_MAP_MODES, MP_SHORTEST_SELECT],
    isForMobile: true,
    icon: 'ri-timer-line',
  },
  {
    name: 'modelExport',
    dataModes: ['model'],
    modelTypes: ['STA'],
    excludedMapModes: [...INTERACTION_MAP_MODES],
    isForMobile: false,
    icon: 'ri-download-2-line',
  },
  {
    name: 'shortestPath',
    dataModes: ['model'],
    modelTypes: ['STA'],
    excludedMapModes: [...INTERACTION_MAP_MODES],
    isForMobile: false,
    icon: 'ri-guide-fill',
  },
  {
    name: 'timeline',
    dataModes: ['model'],
    modelTypes: ['STA', 'DTA'],
    excludedMapModes: [...INTERACTION_MAP_MODES, MP_SHORTEST_SELECT],
    isForMobile: true,
    icon: 'ri-bar-chart-horizontal-fill',
    buttonComponent: WidgetTimeline,
  },
  {
    name: 'mapSettings',
    dataModes: ['live', 'historical', 'model', 'comparison'],
    modelTypes: ['STA', 'DTA'],
    excludedMapModes: [...INTERACTION_MAP_MODES, MP_SHORTEST_SELECT],
    isForMobile: true,
    icon: 'ri-map-line',
  },
  {
    name: 'legend',
    dataModes: ['live', 'historical', 'model', 'comparison'],
    modelTypes: ['STA', 'DTA'],
    excludedMapModes: [...INTERACTION_MAP_MODES, MP_SHORTEST_SELECT],
    isForMobile: true,
    icon: 'ri-list-check',
  },
  {
    name: 'aggregation',
    dataModes: ['comparison'],
    modelTypes: ['STA'], // historical aggregation is the same for STA and DTA, but model is not and some additional logic would need to be added
    isForMobile: false,
    icon: 'ri-functions',
    excludedWidgets: ['comparison', 'aggregationComparison'],
  },
  {
    name: 'aggregationComparison',
    dataModes: ['comparison'],
    modelTypes: ['STA'],
    isForMobile: false,
    icon: 'ri-calculator-line',
    excludedWidgets: ['comparison', 'aggregation'],
  },
];

export default function useWidgets() {
  const store = useStore();
  const isMobile = useMobile();

  const modelType = computed<TmModelType>(() => store.state.scenarios.model.type);
  const dataMode = computed<TmDataMode>(() => store.getters['map/getDataMode']);
  const mapMode = computed<TmMapMode>(() => store.state.map.mapMode);

  const widgetEnabledList = import.meta.env.VITE_ENABLED_WIDGETS?.split(', ') || [];
  const widgetActiveList = computed<TmWidget['name'][]>(() => store.getters['map/getWidgets']());
  const widgetDisabledList = computed(() => {
    const disabledWidgets: string[] = [];
    // NOTE: no widgets are supposed to be disabled for now - excluded widgets will close each other instead (TraMod-1028)
    // for (const w of widgetOptions) {
    //   if (w.excludedWidgets && isWidgetActive(w.name)) disabledWidgets.push(...w.excludedWidgets);
    // }
    return disabledWidgets;
  });

  const isWidgetEnabled = (name: string) => widgetEnabledList.includes(name);
  const isWidgetActive = (name: string) => isWidgetEnabled(name) && widgetActiveList.value.includes(name);
  const isWidgetDisabled = (name: string) => widgetDisabledList.value.includes(name);

  const widgets = computed(() => _getWidgets(dataMode.value, modelType.value, mapMode.value, isMobile()));

  const openWidgetsForDataMode = (dataMode: TmDataMode) => {
    const widgetsToBeOpened = _getWidgets(dataMode, modelType.value, mapMode.value, isMobile()).reverse(); // get widgets in reverse order to keep the original display order
    const mapModeOpenedByDefault = isWidgetActive('mapPopup');
    widgetsToBeOpened.forEach(({ name }) => {
      store.dispatch('map/hideWidget', { name }); // hide widgets displayed by default first (to keep the original order)
      store.dispatch('map/showWidget', { name });
    });
    if (!mapModeOpenedByDefault) store.dispatch('map/hideWidget', { name: 'mapPopup' });
  };

  const closeExcludedWidgets = (name: string) => {
    const excludedWidgets = widgetOptions.find((w) => w.name == name)?.excludedWidgets || [];
    excludedWidgets.forEach((ewName) => {
      if (isWidgetActive(ewName)) store.dispatch('map/hideWidget', { name: ewName });
    });
  };

  const activateDefaultMapMode = (name: string) => {
    // TODO: somehow store this to the widgetOptions? would have to be nested object..
    const widgetName_dataMode_modelType = `${name}_${dataMode.value}_${modelType.value}`;
    // TODO: what about shortestPath widget? (currently it sets mapMode only based on user interaction and restores on in its onUnmounted hook)
    switch (widgetName_dataMode_modelType) {
      case 'comparison_comparison_STA':
        return store.dispatch('map/setMapMode', { mode: MP_MODEL_STA_COMPARISON });
      case 'aggregationComparison_comparison_STA':
        return store.dispatch('map/setMapMode', { mode: MP_MODEL_STA_AGGREGATION_COMPARISON });
      case 'aggregation_comparison_STA':
        return store.dispatch('map/setMapMode', { mode: MP_MODEL_STA_AGGREGATION });
      default:
        return; // in most cases mapMode will not change
    }
  };

  const toggleWidget = (name: string) => {
    const isBeingActivated = !isWidgetActive(name);
    store.dispatch('map/toggleWidget', { name });
    if (!isBeingActivated) return;
    closeExcludedWidgets(name);
    activateDefaultMapMode(name);
  };

  return {
    widgets,
    enabledWidgets: computed(() => widgets.value.filter((w) => isWidgetEnabled(w.name))),
    activeWidgets: computed(() =>
      widgets.value
        .filter((w) => isWidgetActive(w.name))
        .sort((w1, w2) => widgetActiveList.value.indexOf(w2.name) - widgetActiveList.value.indexOf(w1.name)),
    ),
    isWidgetEnabled,
    isWidgetActive,
    isWidgetDisabled,
    openWidgetsForDataMode,
    toggleWidget,
  };
}

function _getWidgets(
  dataMode = <TmDataMode | null>null,
  modelType = <TmModelType | null>null,
  mapMode = <TmMapMode | null>null,
  isMobile = false,
) {
  const matchesModelType = (modelTypes?: TmModelType[]) => !modelType || modelTypes?.includes(modelType);
  const matchesDataMode = (dataModes?: TmDataMode[]) => !dataMode || dataModes?.includes(dataMode);
  const matchesMapMode = (mapModes?: TmMapMode[], exclusions?: TmMapMode[]) =>
    !mapMode || ((!mapModes || mapModes.includes(mapMode)) && !exclusions?.includes(mapMode));
  const matchesDevice = (isForMobile?: boolean) => !isMobile || isForMobile;

  return widgetOptions.reduce((modes: TmWidget[], opt) => {
    if (
      matchesModelType(opt.modelTypes) &&
      matchesDataMode(opt.dataModes) &&
      matchesMapMode(opt.mapModes, opt.excludedMapModes) &&
      matchesDevice(opt.isForMobile)
    ) {
      modes.push({ name: opt.name, icon: opt.icon, buttonComponent: opt.buttonComponent });
    }
    return modes;
  }, []);
}
