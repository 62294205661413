<template>
  <div class="tm-tabs p-component">
    <ul ref="nav" class="tm-tabs-nav" role="tablist">
      <template v-for="(tab, i) of tabs" :key="getTabKey(tab, i)">
        <li
          v-if="!isTabJustComment(tab)"
          role="presentation"
          :class="[{ 'p-highlight': activeTabIndex === i, 'p-disabled': isTabDisabled(tab) }]"
        >
          <a
            role="tab"
            class="tm-tabs-nav-link"
            :tabindex="isTabDisabled(tab) ? undefined : '0'"
            :aria-selected="activeTabIndex === i"
            @click="onTabClick($event, i)"
          >
            <span v-if="tab.props?.header" class="tm-tabs-title">{{ tab.props.header }}</span>
            <i v-if="tab.props?.filled" class="filled-icon ri-check-line"></i>
          </a>
        </li>
      </template>
    </ul>
    <div class="tm-tabs-panels">
      <template v-for="(tab, i) of tabs" :key="getTabKey(tab, i)">
        <div v-show="activeTabIndex === i" class="tm-tabs-panel" role="tabpanel">
          <component :is="tab"></component>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, Comment } from 'vue';
import type { VNode } from 'vue';

const slots = defineSlots<{ default?: () => VNode[] }>();

const isTabDisabled = (tab: VNode) => !!tab.props?.disabled;
const getTabKey = (tab: VNode, i: number) => tab.props?.header ?? i;
const activeTabIndex = ref(-1);

const tabs = computed<VNode[]>(() => slots.default?.() || []);

const onTabClick = (event: MouseEvent, i: number) => {
  if (isTabDisabled(tabs.value[i])) return;
  if (i === activeTabIndex.value) activeTabIndex.value = -1;
  else activeTabIndex.value = i;
};

const isTabJustComment = (tabSlot: VNode) => tabSlot.type === Comment; // commented elements does not count as tabs
</script>

<style scoped>
.tm-tabs .tm-tabs-panels {
  padding: 0 1rem;
}
.tm-tabs .tm-tabs-nav {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
  flex-wrap: wrap;
}
.tm-tabs .tm-tabs-nav li {
  flex: 1;
  display: inline-flex;
  align-items: center;
  text-align: center;
  overflow: hidden;
  border: solid 1px #dee2e6;
}
.tm-tabs .tm-tabs-nav-link {
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: 0.5rem 1rem;
  position: relative;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tm-tabs .tm-tabs-nav li:not(:last-of-type) {
  border-right: 0px;
}
.tm-tabs .tm-tabs-nav li:first-of-type {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.tm-tabs .tm-tabs-nav li:last-of-type {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.tm-tabs .tm-tabs-nav li.p-highlight {
  background: #0094d2;
  color: #495057;
}
.tm-tabs .tm-tabs-nav li.p-highlight .tm-tabs-nav-link {
  color: white;
}
.tm-tabs .tm-tabs-nav li:hover {
  background: #e9ecef;
}
.tm-tabs .tm-tabs-nav li.p-highlight:hover {
  background: #007aad;
}
.tm-tabs .filled-icon {
  position: absolute;
  right: 0.3rem;
}
</style>
