import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { synchronizeUrlWithModuleState, queryAffectingMutations } from '@store/helpers';
import { debounce } from 'lodash-es';

export default function useQuerySync() {
  const router = useRouter();
  const store = useStore();

  const syncQuery = debounce((state: TmStoreState) => {
    synchronizeUrlWithModuleState({ state, router });
  }, 500);

  function startQuerySync() {
    store.subscribe((mutation, state) => {
      if (queryAffectingMutations.includes(mutation.type)) syncQuery(state);
    });
  }

  return {
    startQuerySync,
  };
}
